import * as React from "react"
import { createContext, useState } from "react"

interface PersonalLinkCardContextInterface {
  personalLinkCardState: boolean
  setPersonalLinkCardState: React.Dispatch<React.SetStateAction<boolean>>
}

const defaultState = true

export const PersonalLinkCardContext = createContext<PersonalLinkCardContextInterface>({
  personalLinkCardState: defaultState,
  setPersonalLinkCardState: () => {},
})

export const PersonalLinkCardContextProvider = (props: { children: React.ReactNode }) => {
  const [personalLinkCardState, setPersonalLinkCardState] = useState(defaultState)

  return (
    <PersonalLinkCardContext.Provider
      value={{
        personalLinkCardState,
        setPersonalLinkCardState,
      }}
    >
      {props.children}
    </PersonalLinkCardContext.Provider>
  )
}
