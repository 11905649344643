import * as React from "react"
import { createContext, useState, useEffect } from "react"

// Types
import { ThemeOptions } from "@mui/material"
import { dark, light } from "../constants/LightDarkMode"
import { THEME_KEY } from "constants/Global"

interface LightDarkModeContextInterface {
  currentModeState: ThemeOptions
  updateCurrentModeState: React.Dispatch<React.SetStateAction<ThemeOptions>>
}

const defaultState = () => {
  const savedTheme = localStorage.getItem(THEME_KEY)
  if (savedTheme === "dark") {
    return dark
  } else if (savedTheme === "light") {
    return light
  }
  return dark
}

export const LightDarkModeContext =
  createContext<LightDarkModeContextInterface>({
    currentModeState: defaultState(),
    updateCurrentModeState: () => {},
  })

export const LightDarkModeContextProvider = (props: {
  children: React.ReactNode
}) => {
  const [currentModeState, updateCurrentModeState] = useState(defaultState())

  useEffect(() => {
    if (currentModeState === dark) {
      localStorage.setItem(THEME_KEY, "dark")
    } else if (currentModeState === light) {
      localStorage.setItem(THEME_KEY, "light")
    }
  }, [currentModeState])

  return (
    <LightDarkModeContext.Provider
      value={{
        currentModeState,
        updateCurrentModeState,
      }}
    >
      {props.children}
    </LightDarkModeContext.Provider>
  )
}
