import React, { useState, useContext, useEffect, useRef } from "react"
import { useQuery, useLazyQuery, useMutation } from "@apollo/client"
import { ApolloError } from "@apollo/client/errors"

// MUI Components
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import FormHelperText from "@mui/material/FormHelperText"
import FormControl from "@mui/material/FormControl"
import Divider from "@mui/material/Divider"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Collapse from "@mui/material/Collapse"
import Alert from "@mui/material/Alert"
import Tooltip from "@mui/material/Tooltip"
import Link from "@mui/material/Link"

// Components
import { InputTextField } from "components/InputTextField"
import { DropDownOption } from "components/SelectFieldDropdown"
import { SelectCategoryForm } from "components/SelectCategoryForm"
import { LinkTagForm } from "components/LinkTagForm"
import { CloseModalButton } from "components/Modal/CloseModalButton"
import { SelectOrganizationForm } from "components/SelectOrganizationForm"

// Contexts
import { ModalContext } from "contexts/ModalContext"
import { SnackBarContext } from "contexts/SnackBarContext"

// Types
import { SNACK_BAR_TYPES } from "components/SnackBar/SnackBarTypes"
import { CategoryInterface } from "interfaces/Category"
import { OrganizationInterface } from "interfaces/Organization"
import { SelectChangeEvent } from "@mui/material/Select"
import { TagInterface } from "interfaces/Tags"
import { LINK_MAX_DESCRIPTION_LENGTH, LINK_MAX_URL_LENGTH, LINK_MAX_TITLE_LENGTH } from "constants/Global"
import { GraphQlErrorResponse } from "interfaces/Apis"
// Utils
import { debounceFunction, isValidUrl, parseGraphQlError } from "Utils/Utils"

import {
  writeLinkQuery,
  createLinkMutation,
  getCategoriesQuery,
  getOrganizationsQuery,
  getOpenGraphDataQuery,
  getActiveUserQuery,
  getAiCategoriesAndTagsQuery,
} from "queries/queries"

interface CreateLinkModalInterface {
  title: string
  url: string
  description: string
}

export const CreateLinkModal = () => {
  const timeout = useRef<any>()

  const [urlError, setUrlError] = useState<JSX.Element | string>("")
  const [autofillError, setAutofillError] = useState<string | null>(null)
  const [autofillNotSubscribed, setAutofillNotSubscribed] = useState(false)
  const [titleError, setTitleError] = useState<string>("")
  const [descriptionError, setDescriptionError] = useState<string>("")
  const [errorText, setErrorText] = useState<string>(" ")
  const [dropdownOption, setDropdownOption] = useState<DropDownOption | null>(null)
  const [orgDropdownOption, setOrgDropdownOption] = useState<DropDownOption>({
    name: "Personal",
    value: "0",
  })
  const [selectedTags, setSelectedTags] = useState<TagInterface[]>([])
  const [aiSuggestedData, setAiSuggestedData] = useState({ tags: [], categories: [] })

  const [values, setValues] = useState<CreateLinkModalInterface>({
    title: "",
    url: "",
    description: "",
  })
  const [hasBeenFocused, setHasBeenFocused] = useState({
    url: false,
  })
  const { setModalState } = useContext(ModalContext)
  const { setSnackBarState } = useContext(SnackBarContext)

  const { loading, error, data } = useQuery(getCategoriesQuery)
  const getOrgsResponse = useQuery(getOrganizationsQuery)
  const [getOpenGraphData] = useLazyQuery(getOpenGraphDataQuery)
  const [getAiCategoriesAndTags] = useLazyQuery(getAiCategoriesAndTagsQuery)
  const getActiveUserResponse = useQuery(getActiveUserQuery)

  const [createLink] = useMutation(createLinkMutation, {
    update(cache, { data: { createLink } }) {
      cache.modify({
        fields: {
          links(existingLinks = []) {
            const newLinkRef = cache.writeFragment({
              data: createLink,
              fragment: writeLinkQuery,
            })
            return [...existingLinks, newLinkRef]
          },
        },
      })
    },
  })

  const closeModal = () => {
    setModalState({ isOpen: false, modalType: "" })
  }

  const handleChange = (prop: keyof CreateLinkModalInterface) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (autofillError) {
      setAutofillError(null)
    }
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleFocus = (prop: keyof CreateLinkModalInterface) => {
    setHasBeenFocused({ ...hasBeenFocused, [prop]: true })
  }

  const handleSelect = (event: SelectChangeEvent | null, value: DropDownOption) => {
    setDropdownOption(value)
  }

  const handleOrgSelect = (event: SelectChangeEvent | null, value: DropDownOption) => {
    // setModalState({ ...modalState, data: { organizationId: orgDropdownOption?.value } })

    if (
      event?.target?.value &&
      event?.target?.value !== orgDropdownOption?.value
      // clear selected category if we are changing the selected org
    ) {
      setDropdownOption(null)
      setSelectedTags([])
    }

    if (event && event.target && event.target) {
      setOrgDropdownOption(event.target)
    }
  }

  const buildDropdownOptions = () => {
    if (loading || error) {
      return []
    }

    const filteredCategories = data.categories.filter((category: CategoryInterface) => {
      if (orgDropdownOption.value === "0") {
        return !category.organization?.id
      } else {
        return category.organization?.id === orgDropdownOption.value
      }
    })

    return filteredCategories.map((category: CategoryInterface) => {
      return { name: category.title, value: category.id }
    })
  }

  const buildOrgDropdownOptions = () => {
    if (getOrgsResponse.loading || getOrgsResponse.error) {
      return []
    }

    return getOrgsResponse.data.organizations
      .map((organization: OrganizationInterface) => {
        return { name: organization.name, value: organization.id }
      })
      .concat([{ name: "Personal", value: "0" }])
  }

  const handleCreateLink = () => {
    createLink({
      variables: {
        url: values.url,
        title: values.title,
        description: values.description,
        categoryId: dropdownOption?.value,
        tagIds: selectedTags.map((tag: TagInterface) => tag.id),
        organizationId: orgDropdownOption?.value !== "0" ? orgDropdownOption?.value : null,
      },
    }).then(
      (res: any) => {
        setSnackBarState({
          isOpen: true,
          snackBarType: SNACK_BAR_TYPES.SUCCESS,
          message: "Link Created!",
        })
        closeModal()
      },
      (res: any) => {
        setTitleError("")
        setUrlError("")
        setDescriptionError("")
        if (res && res.message) {
          setErrorText(res.message)
        } else {
          setErrorText("Error - Something went wrong, please try again.")
        }
      }
    )
  }

  const autoFillDetails = () => {
    getOpenGraphData({ variables: { url: values.url } }).then(
      (response) => {
        if (response.data && response.data.openGraphData) {
          setAutofillError(null)
          setValues({
            ...values,
            title: response.data.openGraphData.title || "",
            description: response.data.openGraphData.description?.substring(0, LINK_MAX_DESCRIPTION_LENGTH) || "",
          })
        } else {
          setAutofillError("Unable to fetch data for this url.")
        }
      },
      (response: ApolloError) => {
        setAutofillError(response.message)
      }
    )
  }

  const suggestCategoryAndTags = () => {
    if (getActiveUserResponse.data?.activeUser?.hasActiveSubscription) {
      getAiCategoriesAndTags({ variables: { url: values.url } }).then(
        (response) => {
          if (response.data && response.data.aiCategoryAndTags) {
            setAiSuggestedData({
              tags: response.data.aiCategoryAndTags.tags || [],
              categories: response.data.aiCategoryAndTags.categories || [],
            })
          } else {
            setAutofillError("Unable to fetch suggestions for this url")
          }
        },
        (response: ApolloError) => {
          setAutofillError(response.message)
        }
      )
    } else {
      setAutofillNotSubscribed(true)
    }
  }

  const debouncedHandleCreateLink = () => {
    debounceFunction(timeout, handleCreateLink, 250)
  }

  const debouncedAutoFillDetails = () => {
    debounceFunction(timeout, autoFillDetails, 250)
  }

  const debouncedSuggestCategoryAndTags = () => {
    debounceFunction(timeout, suggestCategoryAndTags, 250)
  }

  const httpUpdateLink = () => {
    setValues({ ...values, url: `http://${values.url}` })
  }

  const httpsUpdateLink = () => {
    setValues({ ...values, url: `https://${values.url}` })
  }

  const httpMissingError = () => {
    return (
      <>
        Invalid URL - Must begin with{" "}
        <Link sx={{ cursor: "pointer", color: "warning.main" }} onClick={httpUpdateLink}>
          http://
        </Link>{" "}
        or{" "}
        <Link sx={{ cursor: "pointer", color: "warning.main" }} onClick={httpsUpdateLink}>
          https://
        </Link>{" "}
      </>
    )
  }

  useEffect(() => {
    // validate URL
    if (!hasBeenFocused.url) {
    } else if (values.url.length < 3) {
      setUrlError(httpMissingError())
    } else if (!isValidUrl(values.url)) {
      setUrlError(httpMissingError())
    } else if (values.url.length > LINK_MAX_URL_LENGTH) {
      setUrlError(`URL too long, limit is ${LINK_MAX_URL_LENGTH} characters`)
    } else {
      setUrlError("")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasBeenFocused.url, values.url])

  useEffect(() => {
    // validate title
    if (values.title.length > LINK_MAX_TITLE_LENGTH) {
      setTitleError("Title too long.")
    } else {
      setTitleError("")
    }
  }, [values.title])

  useEffect(() => {
    // validate description
    if (values.description && values.description.length > LINK_MAX_DESCRIPTION_LENGTH) {
      setDescriptionError("Description too long!")
    } else {
      setDescriptionError("")
    }
  }, [values.description])

  useEffect(() => {
    const listener = (event: any) => {
      if (
        (event.code === "Enter" || event.code === "NumpadEnter") &&
        (event.srcElement.id === "outlined-adornment-link-url" ||
          event.srcElement.id === "outlined-adornment-link-title" ||
          event.srcElement.id === "outlined-adornment-link-description")
      ) {
        event.preventDefault()
        if (isSubmitDisabled()) {
          return
        }
        debouncedHandleCreateLink()
      }
    }
    document.addEventListener("keydown", listener)
    return () => {
      document.removeEventListener("keydown", listener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, dropdownOption, selectedTags])

  const isSubmitDisabled = () => {
    return !!urlError || !!titleError || !!descriptionError
  }

  return (
    <>
      <CloseModalButton closeModal={closeModal} />
      <Box>
        <Typography textAlign="center" variant="h4" sx={{ my: 2 }} aria-live="polite">
          Create Link
        </Typography>
      </Box>

      <Box id="add-link-input-field">
        <InputTextField
          autoFocus={true}
          autoComplete="off"
          name="link-url"
          helperText="URL*"
          onBlur={() => handleFocus("url")}
          handleChange={handleChange("url")}
          canToggleTextFieldVisibility={false}
          shouldShowTextField={true}
          handleClickShowTextField={() => {}}
          textField={values.url}
          error={!!urlError}
          errorText={urlError}
        />
      </Box>

      <SelectOrganizationForm
        dropdownOptions={buildOrgDropdownOptions()}
        title="Does this link belongs to you or one of your organizations?"
        selectedOption={orgDropdownOption}
        handleSelect={handleOrgSelect}
      />

      <Divider sx={{ my: 3 }}>
        <Typography>Tools:</Typography>
      </Divider>

      <Grid container sx={{ my: 2 }}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Tooltip followCursor title="Fetch title and description from URL's web page">
              <Button
                disabled={!!urlError}
                variant="outlined"
                color="primary"
                onClick={debouncedAutoFillDetails}
                sx={{ width: { xs: "100%", md: "auto" } }}
              >
                Autofill Details
              </Button>
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <Tooltip followCursor title="Ask AI to suggest categories and tags">
              <Button
                disabled={!!urlError}
                variant="outlined"
                color="primary"
                onClick={debouncedSuggestCategoryAndTags}
                sx={{ width: { xs: "100%", md: "auto" } }}
              >
                Suggest Category & Tags
              </Button>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>

      <Divider sx={{ my: 3 }}>
        <Typography>Details:</Typography>
      </Divider>

      <Collapse in={!!autofillError} sx={{ px: { xs: 2, md: 2 } }}>
        <Alert sx={{ mt: 1 }} variant="standard" severity="error">
          <Typography>{autofillError}</Typography>
        </Alert>
      </Collapse>

      <Collapse in={autofillNotSubscribed} sx={{ px: { xs: 2, md: 2 } }}>
        <Alert sx={{ mt: 1 }} variant="standard" severity="warning">
          <Typography>Please subscribe to use this feature.</Typography>
        </Alert>
      </Collapse>

      <InputTextField
        name="link-title"
        autoComplete="off"
        helperText="Title"
        handleChange={handleChange("title")}
        canToggleTextFieldVisibility={false}
        shouldShowTextField={true}
        handleClickShowTextField={() => {}}
        textField={values.title}
        error={!!titleError}
        errorText={titleError}
      />

      <InputTextField
        name="link-description"
        autoComplete="off"
        helperText="Description"
        handleChange={handleChange("description")}
        canToggleTextFieldVisibility={false}
        shouldShowTextField={true}
        handleClickShowTextField={() => {}}
        textField={values.description}
        error={!!descriptionError}
        errorText={descriptionError}
        multiline={true}
        maxRows={4}
      />

      <Divider sx={{ my: 3 }}>
        <Typography>Category:</Typography>
      </Divider>

      <SelectCategoryForm
        dropdownOptions={buildDropdownOptions()}
        title="Category"
        suggestions={aiSuggestedData.categories}
        selectedOption={dropdownOption}
        handleSelect={handleSelect}
        defaultOrganizationId={orgDropdownOption.value}
      />

      <Divider sx={{ my: 3 }}>
        <Typography>Tags:</Typography>
      </Divider>

      <LinkTagForm
        organizationId={orgDropdownOption.value}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        suggestions={aiSuggestedData.tags}
      />

      <Divider sx={{ my: 3 }}></Divider>

      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Button variant="outlined" color="warning" onClick={closeModal} sx={{ width: { xs: "100%", md: "auto" } }}>
              Cancel
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              mt: { xs: 2, md: 0 },
              display: "flex",
              flexDirection: { xs: "row", md: "row-reverse" },
            }}
          >
            <FormControl error={!!errorText} sx={{ width: { xs: "100%", md: "auto" } }}>
              <Button variant="contained" onClick={debouncedHandleCreateLink} disabled={isSubmitDisabled()}>
                Create Link
              </Button>
              <FormHelperText aria-live="polite">{errorText}</FormHelperText>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
