import Typography from "@mui/material/Typography"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"

interface OrganizationResourceUseInterface {
    linkCount: number
    categoryCount: number
    tagCount: number
    folderCount: number
    hasActiveSubscription: boolean
    maxLinkCount: number
    maxCategoryCount: number
    maxTagCount: number
    maxFolderCount: number
}

export const OrganizationResourceUse = (
    props: OrganizationResourceUseInterface
) => {
    const {
        linkCount,
        categoryCount,
        tagCount,
        folderCount,
        hasActiveSubscription,
        maxLinkCount,
        maxCategoryCount,
        maxTagCount,
        maxFolderCount,
    } = props


    const detailsBlurb = () => {
        if (hasActiveSubscription) {
            return (
                <Typography sx={{ ml: 2, mb: 1 }}>
                    Linkidex is in beta, we are testing the limits on links, categories, etc. Need more space? Just reach out to david@linkidex.com
                </Typography>
            )
        } else {
            return (
                <Typography sx={{ ml: 2, mb: 1 }}>
                    You have a freemium organization. Purchase 1 seat to unlock more space on Linkidex.
                </Typography>
            )
        }
    }

    return (
        <>

            <Typography variant="h4" sx={{ ml: 2, mt: 2 }}>
                Your Usage
            </Typography>

            {detailsBlurb()}

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 300 }} size="small" aria-label="Uploads">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                <Typography>Links</Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography>Categories</Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography>Tags</Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography>Folders</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                            <TableRow
                                key={'orgResourceUseTable1'}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            >
                                <TableCell align="center" component="th" scope="row">
                                    <Typography>
                                    { linkCount }/{ maxLinkCount }
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" component="th" scope="row" sx={{}}>
                                    <Typography>
                                    { categoryCount } / { maxCategoryCount }
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" component="th" scope="row">
                                    <Typography>
                                    { tagCount } / { maxTagCount }
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" component="th" scope="row">
                                    <Typography>
                                    { folderCount } / { maxFolderCount }
                                    </Typography>
                                </TableCell>
                            </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

