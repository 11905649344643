import { useQuery } from "@apollo/client"
import { getFileUploadsQuery } from "queries/queries"
import { useTheme } from "@mui/material/styles"

import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { SxProps } from "@mui/material"

import { formatDateTime } from "Utils/Utils"

interface ImportTableInterface {
  textStyle: SxProps
  setShowTable: (arg: boolean) => void
}

export const ImportTable = (props: ImportTableInterface) => {
  const { textStyle, setShowTable } = props
  const { data } = useQuery(getFileUploadsQuery)
  const theme = useTheme()

  const determineRowColor = (state: string) => {
    if (state === "Importing") {
      return theme.palette.text.primary
    } else if (state === "Imported") {
      return theme.palette.success.main
    } else {
      return theme.palette.warning.main
    }
  }

  const handleClick = () => {
    setShowTable(false)
  }

  const renderTable = () => (
    <>
      <Grid item xs={12} md={6} sx={{ my: 2 }}>
        <TableContainer component={Paper} sx={{ ...textStyle }}>
          <Typography sx={{ textAlign: "center" }} variant="h6">
            Your Imports:
          </Typography>
          <Table sx={{ minWidth: 300 }} size="small" aria-label="Uploads">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <Typography>Import Status</Typography>
                </TableCell>
                <TableCell align="center" sx={{}}>
                  <Typography>Message</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>Uploaded At</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.fileUploads.map((file_upload: any, idx: number) => (
                <TableRow
                  key={idx}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center" component="th" scope="row">
                    <Typography
                      color={determineRowColor(file_upload.userFriendlyState)}
                    >
                      {file_upload.userFriendlyState}
                    </Typography>
                  </TableCell>
                  <TableCell align="center" component="th" scope="row" sx={{}}>
                    <Typography
                      color={determineRowColor(file_upload.userFriendlyState)}
                    >
                      {file_upload.errorMessage}
                    </Typography>
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    <Typography
                      color={determineRowColor(file_upload.userFriendlyState)}
                    >
                      {formatDateTime(file_upload.createdAt)}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Button sx={{ ml: 2 }} onClick={handleClick}>
            Hide Past Imports
          </Button>
        </TableContainer>
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
        sx={{ display: { xs: "none", md: "block" } }}
      ></Grid>
    </>
  )

  return <>{data?.fileUploads?.length ? renderTable() : ""}</>
}
