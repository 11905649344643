import Typography from "@mui/material/Typography"
import Link from "@mui/material/Link"
import Box from "@mui/material/Box"
import { useNavigate } from "react-router-dom"

export const SideDrawerFooter = () => {
  const navigate = useNavigate()

  return (
    <Box sx={{ pt: 2, margin: "auto" }}>
      <Typography sx={{ pr: 1, my: 6, margin: "auto" }} variant="caption" textAlign="center">
        <Link sx={{ mx: 1, cursor: "pointer" }} onClick={() => navigate("/terms_of_service")}>
          Terms of Service
        </Link>
        <Link
          sx={{
            cursor: "pointer",
          }}
          onClick={() => navigate("/privacy_policy")}
        >
          Privacy Policy
        </Link>
      </Typography>
    </Box>
  )
}
