import * as React from "react"
import { VariableSizeList, ListChildComponentProps } from "react-window"

// MUI
import { useTheme, styled } from "@mui/material/styles"
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete"
import Typography from "@mui/material/Typography"
import TextField from "@mui/material/TextField"
import useMediaQuery from "@mui/material/useMediaQuery"
import ListSubheader from "@mui/material/ListSubheader"
import Popper from "@mui/material/Popper"
import Box from "@mui/material/Box"
import { SxProps } from "@mui/material"

const LISTBOX_PADDING = 8 // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props
  const dataSet = data[index]
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  }

  if (dataSet.hasOwnProperty("group")) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    )
  }

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      {dataSet[1]}
    </Typography>
  )
}

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null)
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props
  const itemData: React.ReactChild[] = []
  ;(children as React.ReactChild[]).forEach(
    (item: React.ReactChild & { children?: React.ReactChild[] }) => {
      itemData.push(item)
      itemData.push(...(item.children || []))
    }
  )

  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true,
  })
  const itemCount = itemData.length
  const itemSize = smUp ? 36 : 48

  const getChildSize = (child: React.ReactChild) => {
    if (child.hasOwnProperty("group")) {
      return 48
    }

    return itemSize
  }

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
  }

  const gridRef = useResetCache(itemCount)

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
})

export interface DropDownOption {
  value: string
  name: string
}

interface SelectFieldDropdownInterface {
  dropdownOptions: readonly DropDownOption[]
  title: string
  selectedOption: null | DropDownOption
  handleSelect: (event: any, value: any) => void
  styles: SxProps
}

export const SelectFieldDropdown = (props: SelectFieldDropdownInterface) => {
  const { dropdownOptions, selectedOption, handleSelect, title, styles } = props

  const isOptionEqualToValue = (
    option: DropDownOption,
    value: DropDownOption
  ) => {
    return option.value === value.value
  }

  return (
    <Box sx={styles}>
      <Autocomplete
        id="select-tag-auto-complete"
        sx={{ width: "100%", mt: 2 }}
        disableListWrap
        // filterOptions={} // TODO DAVID
        PopperComponent={StyledPopper}
        ListboxComponent={ListboxComponent}
        options={dropdownOptions}
        renderInput={(params) => (
          <TextField {...params} label={`Select ${title}`} />
        )}
        renderOption={(props, option: DropDownOption) => [props, option.name]}
        getOptionLabel={(option: DropDownOption) => option.name}
        renderGroup={(params) => params}
        onChange={handleSelect}
        value={selectedOption}
        isOptionEqualToValue={isOptionEqualToValue}
      />
    </Box>
  )
}
