import * as React from "react"
import { createContext, useState } from "react"

interface IsStrictSearchContextInterface {
  isStrictSearch: boolean
  setIsStrictSearch: React.Dispatch<React.SetStateAction<boolean>>
}

const defaultState = false

export const IsStrictSearchContext = createContext<IsStrictSearchContextInterface>({
  isStrictSearch: defaultState,
  setIsStrictSearch: () => {},
})

export const IsStrictSearchContextProvider = (props: { children: React.ReactNode }) => {
  const [isStrictSearch, setIsStrictSearch] = useState(defaultState)

  return (
    <IsStrictSearchContext.Provider
      value={{
        isStrictSearch,
        setIsStrictSearch,
      }}
    >
      {props.children}
    </IsStrictSearchContext.Provider>
  )
}
