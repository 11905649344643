import React, { useState, useEffect, useContext, useRef } from "react"
import { useQuery } from "@apollo/client"
import { useParams, useNavigate } from "react-router-dom"
import { Navigate } from "react-router-dom"
import { useApolloClient } from "@apollo/client"

// mui
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings"
import Button from "@mui/material/Button"
import Alert from "@mui/material/Alert"
import CloseIcon from "@mui/icons-material/Close"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import TablePagination from "@mui/material/TablePagination"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"

// Components
import { InputTextField } from "components/InputTextField"

// Constants
import { DEBOUNCE_TIME_LONG } from "constants/Global"
import { SNACK_BAR_TYPES } from "components/SnackBar/SnackBarTypes"

// Contexts
import { ModalContext } from "contexts/ModalContext"
import { SnackBarContext } from "contexts/SnackBarContext"

// Interfaces
import { UserOrganizationInterface } from "interfaces/Organization"

// Queries
import {
  getPaginatedUserOrganizationsQuery,
  getTotalUserOrganizationsQuery,
  getOrganizationQuery,
  getStripeSubscriptionForOrganizationQuery,
  getOrganizationSeatCounts,
} from "queries/queries"

// Types
import { MODAL_TYPES } from "components/Modal/ModalTypes"

// Utils
import { debounceFunction } from "Utils/Utils"
import { ApiUtils } from "Utils/ApiUtils"

export function OrganizationMemberList() {
  const { setModalState } = useContext(ModalContext)
  const timeout = useRef<any>()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [inputEmail, setInputEmail] = useState("")
  const [debouncedInputEmail, setDebouncedInputEmail] = useState("")
  const [emailInputError, setEmailInputError] = useState("")
  const { organization_id } = useParams()
  const navigate = useNavigate()
  const { setSnackBarState } = useContext(SnackBarContext)
  const client = useApolloClient()

  const getOrganizationQueryResponse = useQuery(getOrganizationQuery, {
    variables: {
      id: organization_id,
    },
  })

  const getOrganizationSeatCountsResponse = useQuery(
    getOrganizationSeatCounts,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        organizationId: organization_id,
      },
    }
  )

  const getStripeSubscriptionResponse = useQuery(
    getStripeSubscriptionForOrganizationQuery,
    {
      fetchPolicy: "network-only",
      variables: {
        organizationId: organization_id,
      },
    }
  )

  const openManageUserOrganizationPermissionsModal = (
    userOrganization: UserOrganizationInterface
  ) => {
    setModalState({
      isOpen: true,
      modalType: MODAL_TYPES.MANAGE_USER_ORGANIZATION_PERMISSIONS,
      data: {
        organizationUserRoles: userOrganization.organizationUserRoles,
        userOrganizationId: userOrganization.id,
        organizationId: userOrganization.organizationId,
      },
    })
  }

  const openConfirmRemoveFromOrganizationModal = (
    userOrganization: UserOrganizationInterface
  ) => {
    setModalState({
      isOpen: true,
      modalType: MODAL_TYPES.REMOVE_USER_FROM_ORGANIZATION,
      data: {
        userOrganizationId: userOrganization.id,
        userEmail: userOrganization.email,
        searchEmail: debouncedInputEmail,
        organizationName:
          getOrganizationQueryResponse?.data?.organization?.name,
        userId: userOrganization.userId,
        organizationId: userOrganization.organizationId,
      },
    })
  }

  const openCreateInviteModal = () => {
    setModalState({
      isOpen: true,
      modalType: MODAL_TYPES.CREATE_ORGANIZATION_INVITE,
      data: {
        organizationId: organization_id,
      },
    })
  }

  const openOrganizationRolesDescriptionModal = () => {
    setModalState({
      isOpen: true,
      modalType: MODAL_TYPES.ORGANIZATION_ROLES_DESCRIPTION,
    })
  }

  const openOrganizationStateDescriptionModal = () => {
    setModalState({
      isOpen: true,
      modalType: MODAL_TYPES.ORGANIZATION_STATE_DESCRIPTION,
    })
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputEmail(event.target.value)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const { data, loading, error } = useQuery(
    getPaginatedUserOrganizationsQuery,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        id: organization_id,
        offset: page,
        limit: rowsPerPage,
        email: debouncedInputEmail.length < 4 ? "" : debouncedInputEmail,
      },
    }
  )

  const getTotalUserOrganizationsResponse = useQuery(
    getTotalUserOrganizationsQuery,
    {
      variables: {
        id: organization_id,
        email: debouncedInputEmail.length < 4 ? "" : debouncedInputEmail,
      },
    }
  )

  useEffect(() => {
    if (inputEmail.length === 0) {
      setEmailInputError("")
    } else if (inputEmail.length < 4) {
      setEmailInputError("Search must be greater than 4 characters")
    } else {
      setEmailInputError("")
    }
    debouncedSetInputEmail()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputEmail])

  const debouncedSetInputEmail = () => {
    debounceFunction(
      timeout,
      () => setDebouncedInputEmail(inputEmail),
      DEBOUNCE_TIME_LONG
    )
  }

  const debouncedRefreshData = () => {
    debounceFunction(timeout, () => refreshData(), DEBOUNCE_TIME_LONG)
  }

  const refreshData = () => {
    client.refetchQueries({
      include: [getOrganizationSeatCounts, getPaginatedUserOrganizationsQuery],
    })
  }

  const createStripePortalSession = () => {
    if (!organization_id) {
      return
    }
    ApiUtils.createCustomerPortalSession("Organization", organization_id).then(
      (res: any) => {
        window.open(res.url)
      },
      (res: any) => {
        setSnackBarState({
          isOpen: true,
          snackBarType: SNACK_BAR_TYPES.ERROR,
          message:
            res.error ||
            "Something went wrong! Please try again, or reach out to help@linkidex.com",
        })
      }
    )
  }

  const upgradeOrgManageOrg = () => {
    if (!getStripeSubscriptionResponse?.data) {
      return <div></div>
    } else if (
      getStripeSubscriptionResponse?.data?.stripeSubscriptionForOrganization?.id
    ) {
      return (
        <Button
          sx={{ my: 2, mx: 2 }}
          variant="contained"
          onClick={createStripePortalSession}
          aria-label="Edit Subscription"
        >
          Edit Subscription
        </Button>
      )
    } else {
      return (
        <Button
          sx={{ my: 2, mx: 2 }}
          variant="contained"
          onClick={() => navigate(`/organizations/${organization_id}/upgrade`)}
          aria-label="Upgrade Organization"
        >
          Upgrade Org
        </Button>
      )
    }
  }

  const deriveState = (userOrganization: UserOrganizationInterface) => {
    if (userOrganization.disabledNoSeatAt) {
      return <Typography color="warning.main">No Seat</Typography>
    }
    return <Typography color="success.main">Active</Typography>
  }

  const displayUsersInOrgRows = () =>
    data?.userOrganizations.map(
      (userOrganization: UserOrganizationInterface, idx: number) => (
        <TableRow
          key={userOrganization.email}
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        >
          <TableCell component="th" scope="row">
            {userOrganization.email}
          </TableCell>
          <TableCell component="th" scope="row">
            {userOrganization.organizationUserRoles
              .map((orgUserRole) => orgUserRole.userFacingName)
              .join(", ")}
          </TableCell>
          <TableCell component="th" scope="row">
            <Tooltip followCursor title="Manage user's permissions">
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  openManageUserOrganizationPermissionsModal(userOrganization)
                }}
              >
                <AdminPanelSettingsIcon sx={{ fontSize: "20px" }} />
              </IconButton>
            </Tooltip>
            <Tooltip followCursor title="Remove from org">
              <IconButton
                aria-label="remove-from-organization"
                color="inherit"
                size="small"
                onClick={() => {
                  openConfirmRemoveFromOrganizationModal(userOrganization)
                }}
              >
                <CloseIcon sx={{ fontSize: "20px" }} />
              </IconButton>
            </Tooltip>
          </TableCell>
          <TableCell component="th" scope="row">
            {deriveState(userOrganization)}
          </TableCell>
        </TableRow>
      )
    )

  const refreshDataButton = () => {
    return (
      <Button
        size="small"
        onClick={debouncedRefreshData}
        aria-label="Resend Email Verification"
      >
        Refresh Data
      </Button>
    )
  }
  const seatCountBlurb = () => {
    const numberOfUsedSeats =
      getOrganizationSeatCountsResponse?.data?.organizationSeatCounts
        ?.numberOfUsedSeats
    const numberOfSeats =
      getOrganizationSeatCountsResponse?.data?.organizationSeatCounts
        ?.numberOfSeats

    const hasStripeSubscription =
      !!getStripeSubscriptionResponse?.data?.stripeSubscriptionForOrganization
        ?.id
    const text = `${numberOfUsedSeats} out of ${numberOfSeats} seats used.`
    if (hasStripeSubscription && numberOfUsedSeats > numberOfSeats) {
      // if subscribed org doesn't have enough seats
      return (
        <Alert sx={{ mt: 1 }} variant="outlined" severity="warning">
          <Typography>
            {text} Edit your subscription to add or remove seats.{" "}
            {refreshDataButton()}
          </Typography>
        </Alert>
      )
    } else if (hasStripeSubscription) {
      // if subscribed org has enough seats
      return (
        <Typography variant="subtitle1" sx={{ ml: 2, mb: 1 }}>
          {text} Edit your subscription to add or remove seats.{" "}
          {refreshDataButton()}
        </Typography>
      )
    } else if (numberOfUsedSeats > numberOfSeats) {
      // if un-subscribed org doesn't have enough seats
      return (
        <Alert sx={{ mt: 1 }} variant="outlined" severity="warning">
          <Typography>
            {text} Upgrade your organization to add more. {refreshDataButton()}
          </Typography>
        </Alert>
      )
    } else {
      return (
        <Typography variant="subtitle1" sx={{ ml: 2, mb: 1 }}>
          {text} Upgrade your organization to add more. {refreshDataButton()}
        </Typography>
      )
    }
  }

  const memberListTable = () => (
    <>
      <Typography variant="h4" sx={{ ml: 2, mt: 2 }}>
        Manage Members
      </Typography>

      {seatCountBlurb()}

      <Grid container>
        <Grid item xs={12} md={6}>
          <InputTextField
            name="email"
            autoComplete="off"
            helperText="Search by email"
            handleChange={handleChangeEmail}
            canToggleTextFieldVisibility={false}
            shouldShowTextField={true}
            handleClickShowTextField={() => {}}
            textField={inputEmail}
            error={!!emailInputError}
            errorText={emailInputError}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Button
            sx={{ my: 2, mx: 2 }}
            variant="contained"
            onClick={openCreateInviteModal}
            aria-label="Resend Email Verification"
          >
            Create Invite
          </Button>
        </Grid>
        <Grid item xs={6} md={3}>
          {upgradeOrgManageOrg()}
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Email</strong>
              </TableCell>
              <TableCell>
                <strong>Permissions</strong>
                <IconButton onClick={openOrganizationRolesDescriptionModal}>
                  <InfoOutlinedIcon sx={{ fontSize: "18px" }} />
                </IconButton>
              </TableCell>
              <TableCell>
                <strong>Manage</strong>
              </TableCell>
              <TableCell>
                <strong>State</strong>
                <IconButton onClick={openOrganizationStateDescriptionModal}>
                  <InfoOutlinedIcon sx={{ fontSize: "18px" }} />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{displayUsersInOrgRows()}</TableBody>
        </Table>
      </TableContainer>

      {!loading && (
        <TablePagination
          component="div"
          count={
            getTotalUserOrganizationsResponse?.data?.totalUserOrganizations || 0
          }
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  )

  return (
    <>{error ? <Navigate to="/organizations" replace /> : memberListTable()}</>
  )
}
