import { useState, useContext, useRef } from "react"

// MUI Components
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import FormHelperText from "@mui/material/FormHelperText"
import FormControl from "@mui/material/FormControl"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import { useMutation } from "@apollo/client"

// Components
import { CloseModalButton } from "components/Modal/CloseModalButton"

// Contexts
import { ModalContext } from "contexts/ModalContext"
import { SnackBarContext } from "contexts/SnackBarContext"

// Utils
import { SNACK_BAR_TYPES } from "components/SnackBar/SnackBarTypes"
import { debounceFunction } from "Utils/Utils"

// Queries
import {
  deleteUserOrganizationMutation,
  getTotalUserOrganizationsQuery,
  getOrganizationSeatCounts,
} from "queries/queries"

// TYPES
import { DEBOUNCE_TIME } from "constants/Global"

export const RemoveUserFromOrganizationModal = () => {
  const timeout = useRef<any>()
  const [errorText, setErrorText] = useState<string>(" ")
  const { modalState, setModalState } = useContext(ModalContext)
  const { setSnackBarState } = useContext(SnackBarContext)

  const [deleteUserOrganization] = useMutation(deleteUserOrganizationMutation, {
    refetchQueries: [
      {
        query: getTotalUserOrganizationsQuery,
        variables: {
          id: modalState.data.organizationId,
          email: modalState.data.searchEmail,
        },
      },
      {
        query: getOrganizationSeatCounts,
        variables: {
          organizationId: modalState.data.organizationId,
        },
      },
    ],
    update(cache, { data: { deleteUserOrganization } }) {
      cache.evict({
        id: `UserOrganization:${deleteUserOrganization.id}`,
      })
    },
  })

  const closeModal = () => {
    setModalState({ isOpen: false, modalType: "" })
  }

  const handleRemoveFromOrganization = () => {
    deleteUserOrganization({
      variables: {
        organizationId: modalState.data.organizationId,
        userId: modalState.data.userId,
      },
    }).then(
      (res: any) => {
        setSnackBarState({
          isOpen: true,
          snackBarType: SNACK_BAR_TYPES.SUCCESS,
          message: "User has been removed from organization.",
        })
        closeModal()
      },
      (res: any) => {
        if (res && res.message) {
          setErrorText(res.message)
        } else {
          setErrorText("Something went wrong, please try again.")
        }
      }
    )
  }

  const debouncedHandleRemoveFromOrganization = () => {
    debounceFunction(timeout, handleRemoveFromOrganization, DEBOUNCE_TIME)
  }

  return (
    <>
      <CloseModalButton closeModal={closeModal} />
      <Typography textAlign="center" variant="h4" sx={{ my: 2 }}>
        Remove User from organization?
      </Typography>
      <Typography>
        removing <strong>{modalState.data.userEmail}</strong> from{" "}
        <strong>{modalState.data.organizationName}</strong>.
      </Typography>

      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Button
              variant="outlined"
              color="warning"
              onClick={closeModal}
              sx={{ width: { xs: "100%", md: "auto" } }}
            >
              Cancel
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              mt: { xs: 2, md: 0 },
              display: "flex",
              flexDirection: { xs: "row", md: "row-reverse" },
            }}
          >
            <FormControl
              error={!!errorText}
              sx={{ width: { xs: "100%", md: "auto" } }}
            >
              <Button
                color="error"
                variant="contained"
                onClick={debouncedHandleRemoveFromOrganization}
              >
                Remove
              </Button>
              <FormHelperText aria-live="polite">{errorText}</FormHelperText>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
