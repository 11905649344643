import * as React from "react"
import { createContext, useState } from "react"

const defaultState = { isOpen: false, hasSeenWelcome: false }

interface UserOnboardingStateInterface {
  isOpen: boolean
  hasSeenWelcome: boolean
}

interface UserOnboardingTourContextInterface {
  userOnboardingTourState: UserOnboardingStateInterface
  updateUserOnboardingTourState: React.Dispatch<
    React.SetStateAction<UserOnboardingStateInterface>
  >
}

export const UserOnboardingTourContext =
  createContext<UserOnboardingTourContextInterface>({
    userOnboardingTourState: defaultState,
    updateUserOnboardingTourState: () => {},
  })

export const UserOnboardingTourContextProvider = (props: {
  children: React.ReactNode
}) => {
  const [userOnboardingTourState, updateUserOnboardingTourState] =
    useState<UserOnboardingStateInterface>(defaultState)

  return (
    <UserOnboardingTourContext.Provider
      value={{
        userOnboardingTourState,
        updateUserOnboardingTourState,
      }}
    >
      {props.children}
    </UserOnboardingTourContext.Provider>
  )
}
