import * as React from "react"
import { createContext, useState } from "react"

interface SelectedOrganizationsInterface {
  [key: string]: boolean
}

interface SelectedOrganizationsContextInterface {
  selectedOrganizationsState: SelectedOrganizationsInterface
  setSelectedOrganizationsState: React.Dispatch<
    React.SetStateAction<SelectedOrganizationsInterface>
  >
}

const defaultState = {}

export const SelectedOrganizationsContext =
  createContext<SelectedOrganizationsContextInterface>({
    selectedOrganizationsState: defaultState,
    setSelectedOrganizationsState: () => {},
  })

export const SelectedOrganizationsContextProvider = (props: {
  children: React.ReactNode
}) => {
  const [selectedOrganizationsState, setSelectedOrganizationsState] =
    useState(defaultState)

  return (
    <SelectedOrganizationsContext.Provider
      value={{
        selectedOrganizationsState,
        setSelectedOrganizationsState,
      }}
    >
      {props.children}
    </SelectedOrganizationsContext.Provider>
  )
}
