import Box from "@mui/material/Box"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import { SxProps } from "@mui/material"

// components
import { DropDownOption } from "components/SelectFieldDropdown"

interface SimpleDropdownInterface {
  dropdownOptions: readonly DropDownOption[]
  title: string
  selectedOption: DropDownOption
  handleSelect: (event: any, value: any) => void
  styles: SxProps
}

export const SimpleDropdown = (props: SimpleDropdownInterface) => {
  const { dropdownOptions, selectedOption, handleSelect, title, styles } = props

  const buildDropdownOptions = () =>
    dropdownOptions.map((dropdownOption: DropDownOption) => (
      <MenuItem key={dropdownOption.value} value={dropdownOption.value}>
        {dropdownOption.name}
      </MenuItem>
    ))
  return (
    <Box sx={styles}>
      <FormControl fullWidth>
        <InputLabel id="select">{title}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedOption.value}
          label={title}
          onChange={handleSelect}
        >
          {buildDropdownOptions()}
        </Select>
      </FormControl>
    </Box>
  )
}
