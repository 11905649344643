import React, { useState, useContext, useEffect, useRef } from "react"
import { useMutation } from "@apollo/client"
import { getActiveUserQuery, updateUserEmailMutation } from "queries/queries"

// MUI Components
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import FormHelperText from "@mui/material/FormHelperText"
import FormControl from "@mui/material/FormControl"
import Divider from "@mui/material/Divider"

// Contexts
import { ModalContext } from "../../contexts/ModalContext"
import { SnackBarContext } from "../../contexts/SnackBarContext"

// Utils
import { isValidEmail, debounceFunction } from "Utils/Utils"
import { SNACK_BAR_TYPES } from "components/SnackBar/SnackBarTypes"

// Components
import { InputTextField } from "components/InputTextField"
import { CloseModalButton } from "components/Modal/CloseModalButton"

// TYPES
import { DEBOUNCE_TIME } from "constants/Global"

interface UpdateEmailModalInterface {
  password: string
  newEmail: string
}

export const UpdateEmailModal = () => {
  const timeout = useRef<any>()
  const [updateEmailError, setUpdateEmailError] = useState<boolean>(false)
  const [updateEmailErrorText, setUpdateEmailErrorText] = useState<string>(" ")
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
  const [values, setValues] = useState<UpdateEmailModalInterface>({
    password: "",
    newEmail: "",
  })
  const { setModalState } = useContext(ModalContext)
  const { setSnackBarState } = useContext(SnackBarContext)
  const [updateUserEmail] = useMutation(updateUserEmailMutation)

  const closeModal = () => {
    setModalState({ isOpen: false, modalType: "" })
  }

  const debouncedHandleUpdateEmail = () => {
    debounceFunction(timeout, handleUpdateEmail, DEBOUNCE_TIME)
  }

  useEffect(() => {
    // user hasn't finished filling out the form
    if (values.password.length === 0 && values.newEmail.length === 0) {
      setIsSubmitDisabled(true)
      setUpdateEmailError(false)
      setUpdateEmailErrorText(" ")
    }
    // password missing
    else if (values.password.length === 0 && values.newEmail.length > 0) {
      setIsSubmitDisabled(true)
      setUpdateEmailError(true)
      setUpdateEmailErrorText("Error - Password missing")
    }
    // Email does not pass regex
    else if (!isValidEmail(values.newEmail)) {
      setIsSubmitDisabled(true)
      setUpdateEmailError(true)
      setUpdateEmailErrorText("Error - Invalid email address")
      // form is valid
    } else {
      setUpdateEmailErrorText(" ")
      setUpdateEmailError(false)
      setIsSubmitDisabled(false)
    }
  }, [values])

  useEffect(() => {
    const listener = (event: any) => {
      if (
        (event.code === "Enter" || event.code === "NumpadEnter") &&
        !isSubmitDisabled
      ) {
        event.preventDefault()
        debouncedHandleUpdateEmail()
      }
    }
    document.addEventListener("keydown", listener)
    return () => {
      document.removeEventListener("keydown", listener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitDisabled, values])

  const handleChange =
    (prop: keyof UpdateEmailModalInterface) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value })
    }

  const handleUpdateEmail = () => {
    setUpdateEmailError(false)
    setUpdateEmailErrorText(" ")
    updateUserEmail({
      variables: {
        password: values.password,
        newEmail: values.newEmail,
      },
      refetchQueries: [{ query: getActiveUserQuery }],
    }).then(
      (res: object) => {
        setSnackBarState({
          isOpen: true,
          snackBarType: SNACK_BAR_TYPES.SUCCESS,
          message: "Email Updated Successfully",
        })
        closeModal()
      },
      (res: any) => {
        setUpdateEmailError(true)
        if (res && res.error) {
          setUpdateEmailErrorText(res.error)
        } else {
          setUpdateEmailErrorText("Something went wrong, please try again.")
        }
      }
    )
  }

  return (
    <>
      <CloseModalButton closeModal={closeModal} />
      <Typography
        textAlign="center"
        variant="h4"
        sx={{ my: 2 }}
        aria-live="polite"
        aria-label="Update Email Form. Please Enter your password, and your new email address."
      >
        Update Email
      </Typography>

      {/* current password */}
      <InputTextField
        name="password"
        helperText="password*"
        handleChange={handleChange("password")}
        canToggleTextFieldVisibility={false}
        shouldShowTextField={false}
        handleClickShowTextField={() => {}}
        textField={values.password}
        error={updateEmailError}
        errorText=""
      />

      <Divider sx={{ my: 3 }} />

      {/* new email */}
      <InputTextField
        name="newEmail"
        helperText="new email*"
        handleChange={handleChange("newEmail")}
        canToggleTextFieldVisibility={false}
        shouldShowTextField={true}
        handleClickShowTextField={() => {}}
        textField={values.newEmail}
        error={updateEmailError}
        errorText=""
      />

      <FormControl error={updateEmailError}>
        <Button
          sx={{ mt: 2 }}
          variant="contained"
          onClick={() => debouncedHandleUpdateEmail()}
          disabled={isSubmitDisabled}
          aria-label="submit email update"
        >
          Update Email
        </Button>
        <FormHelperText aria-live="polite" aria-label={updateEmailErrorText}>
          {updateEmailErrorText}
        </FormHelperText>
      </FormControl>
    </>
  )
}
