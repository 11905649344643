import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"

export const OrganizationStateDescriptionModal = () => {
  return (
    <>
      <Typography
        textAlign="center"
        variant="h4"
        sx={{ my: 2 }}
        aria-label={`Organization Roles`}
      >
        User States
      </Typography>

      <Typography sx={{ my: 2 }}>
        <strong>Active </strong>- User has access to the organization based on
        their permissions
      </Typography>
      <Typography sx={{ my: 2 }}>
        <strong>No Seat </strong>- User is unable to view Organization's
        resources. Please increase your Organization's seats or remove users
        from your organization
      </Typography>

      <Divider />

      <Typography sx={{ my: 2 }}>
        If there are more users than seats in an organization, the most recently
        added users will not be allocated seats. To make more seats available,
        either remove users from your organization, or add seats to your
        subscription.
      </Typography>
    </>
  )
}
