import React from "react"

// MUI Components
import FormControl from "@mui/material/FormControl"
import FormHelperText from "@mui/material/FormHelperText"
import InputLabel from "@mui/material/InputLabel"
import InputAdornment from "@mui/material/InputAdornment"
import OutlinedInput from "@mui/material/OutlinedInput"
import IconButton from "@mui/material/IconButton"
import { SxProps } from "@mui/material"

// MUI Icons
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"

interface InputTextFieldInterface {
  autoFocus?: boolean // should the input field be auto-focused.
  autoComplete?: string // should the input field allow browser auto-complete.
  name: string // used for component IDs and Labels
  helperText: string // used for human readable text
  onBlur?: () => void // what to to after a field has been focused and then unfocused (such as begin running FE validations)
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void // what to do when someone types inside input field
  canToggleTextFieldVisibility: boolean // should the component let user toggle if text field is plain text or hidden
  shouldShowTextField: boolean // is the text field plain text or hidden
  handleClickShowTextField: () => void // function to handle showing if the text field is hidden or not
  textField: string // what the text field's current value is.
  error: boolean // is there an error
  errorText: JSX.Element | string // what the error message is
  multiline?: boolean // if the given input field should be multiple lines
  maxRows?: number // max number of lines to display if multiline is true
  reference?: React.RefObject<HTMLElement>
  endAdornment?: any
  startAdornment?: any
}

const handleMouseDownTextField = (event: React.MouseEvent<HTMLButtonElement>) => {
  event.preventDefault()
}

const textInputStyle = {
  my: 1,
  width: "100%",
} as SxProps

export function InputTextField({
  autoFocus,
  autoComplete,
  name,
  helperText,
  onBlur,
  handleChange,
  canToggleTextFieldVisibility,
  shouldShowTextField,
  handleClickShowTextField,
  textField,
  error,
  errorText,
  multiline,
  maxRows,
  reference,
  endAdornment,
  startAdornment,
}: InputTextFieldInterface) {
  const determineEndAdornment = () => {
    // if password field, this is a toggle password button, else it is whatever was passed in as a prop (if anything)
    // <InputAdornment position="end">kg</InputAdornment>
    if (canToggleTextFieldVisibility) {
      return (
        <InputAdornment position="end">
          <IconButton
            aria-label={`toggle ${name} visibility`}
            onClick={handleClickShowTextField}
            onMouseDown={handleMouseDownTextField}
            edge="end"
          >
            {shouldShowTextField ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      )
    } else if (endAdornment) {
      return endAdornment
    }
    return <></>
  }

  return (
    <>
      <FormControl key={`form-control-input-${name}`} sx={textInputStyle} variant="outlined" error={error}>
        <InputLabel htmlFor={`outlined-adornment-${name}`}>{helperText}</InputLabel>
        <OutlinedInput
          inputRef={reference}
          onBlur={onBlur}
          autoFocus={autoFocus}
          autoComplete={autoComplete}
          multiline={!!multiline}
          maxRows={maxRows}
          key={`outlined-adornment-${name}`}
          id={`outlined-adornment-${name}`}
          type={shouldShowTextField ? "text" : "password"}
          value={textField}
          onChange={handleChange}
          endAdornment={determineEndAdornment()}
          startAdornment={startAdornment}
          label={name}
          aria-describedby={`form-control-input-${name}`}
          inputProps={{ maxLength: "2049" }}
        />
        <FormHelperText id={`form-control-input-${name}`}>{errorText}</FormHelperText>
      </FormControl>
    </>
  )
}
