import * as React from "react"
import { createContext, useState } from "react"
import { DEFAULT_DRAWER_WIDTH } from "constants/Global"
import { useIsMobile } from "Utils/Utils"

interface SideDrawerInterface {
  isOpen: boolean
  width: number
}

interface SideDrawerContextInterface {
  sideDrawerState: SideDrawerInterface
  setSideDrawerState: React.Dispatch<React.SetStateAction<SideDrawerInterface>>
}

const defaultState = { isOpen: false, width: DEFAULT_DRAWER_WIDTH }

export const SideDrawerContext = createContext<SideDrawerContextInterface>({
  sideDrawerState: defaultState,
  setSideDrawerState: () => {},
})

export const SideDrawerContextProvider = (props: {
  children: React.ReactNode
}) => {
  const isMobile = useIsMobile()
  const [sideDrawerState, setSideDrawerState] = useState(
    {
      isOpen: isMobile ? false : true,
      width: DEFAULT_DRAWER_WIDTH
    }
  )

  return (
    <SideDrawerContext.Provider
      value={{
        sideDrawerState,
        setSideDrawerState,
      }}
    >
      {props.children}
    </SideDrawerContext.Provider>
  )
}
