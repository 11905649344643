import React from "react"
import { useContext } from "react"
import { useQuery } from "@apollo/client"

// MUI
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"
import { SxProps } from "@mui/material"

// Components
import { ModalContext } from "contexts/ModalContext"

// Types
import { MODAL_TYPES } from "components/Modal/ModalTypes"

interface PasswordDetailsRowInterface {
  textStyle: SxProps
}

export function BackupCodeRow({ textStyle }: PasswordDetailsRowInterface) {
  const { setModalState } = useContext(ModalContext)

  const openManageTwoFactorModal = () => {
    setModalState({ isOpen: true, modalType: MODAL_TYPES.MANAGE_BACKUP_CODES })
  }

  return (
    <>
      {/* Left part of row */}
      <Grid item sx={{ display: { marginTop: 20 } }} xs={12} sm={8}>
        <Typography sx={textStyle} variant="h6">
          Backup Codes
        </Typography>
        <Typography sx={textStyle} variant="subtitle2">
          Use backup codes to regain access to your account if you lose access to your Two-Factor device.
        </Typography>
      </Grid>

      {/* Right part of row */}
      <Grid item sx={{ display: { marginTop: 20 } }} xs={12} sm={4}>
        <Button
          aria-label="manage-backup"
          onClick={openManageTwoFactorModal}
          variant="outlined"
          sx={{ mt: { xs: 0, sm: 1.5 }, ml: { xs: 2, sm: 0 } }}
        >
          Manage Backups
        </Button>
      </Grid>
    </>
  )
}
