import { useState, useContext } from "react"
import { useQuery, useMutation } from "@apollo/client"
import {
  getFoldersQuery,
  getTagsQuery,
  createOrUpdateTagFolderItemMutation,
  deleteTagFolderItemMutation,
  updateFolderMutation,
  writeFolderQuery,
} from "queries/queries"
import { NodeModel } from "@minoru/react-dnd-treeview"
import { PinInterface } from "interfaces/Pin"

import { SnackBarContext } from "contexts/SnackBarContext"
import { SNACK_BAR_TYPES } from "components/SnackBar/SnackBarTypes"
// MUI
import Box from "@mui/material/Box"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import Collapse from "@mui/material/Collapse"

// Components
import { TagIcon } from "./TagIcon"
import { SideDrawerTreeView } from "../SideDrawerTreeView"
import { UserTagDropdown } from "./UserTagDropdown"

// Utils
import { buildFolderData, buildTagData } from "Utils/Utils"

// Types
import { FOLDER, CATEGORY, TAG, COLLAPSE_DEFAULT_TIMEOUT } from "constants/Global"

interface UserTagsProps {
  isNotMobile: boolean
  isUserOnLinksPage: boolean
  toggleItem: (id: number) => void
  isItemChecked: (id: number) => boolean | undefined
  organizationId?: string
  isDisabled?: boolean
}

export const UserTags = (props: UserTagsProps) => {
  const { isNotMobile, isUserOnLinksPage, toggleItem, isItemChecked, organizationId, isDisabled } = props

  const { setSnackBarState } = useContext(SnackBarContext)

  const folderQueryData = useQuery(getFoldersQuery)
  const tagQueryData = useQuery(getTagsQuery)
  const [createOrUpdateFolderItem] = useMutation(createOrUpdateTagFolderItemMutation)
  const [deleteFolderItem] = useMutation(deleteTagFolderItemMutation)
  const [editFolder] = useMutation(updateFolderMutation, {
    update(cache, { data: { updateFolder } }) {
      cache.modify({
        id: cache.identify(updateFolder),
        fields: {
          links(existingFolders = []) {
            const newFolderRef = cache.writeFragment({
              data: updateFolder,
              fragment: writeFolderQuery,
            })
            return [...existingFolders, newFolderRef]
          },
        },
      })
    },
  })

  const handleCreateOrUpdateFolderItem = (itemId: number, itemType: string, folderId: number) => {
    createOrUpdateFolderItem({
      variables: { itemId, itemType, folderId },
    }).then(
      () => {
        setSnackBarState({
          isOpen: true,
          snackBarType: SNACK_BAR_TYPES.SUCCESS,
          message: `Tag Moved`,
        })
      },
      (res: any) => {
        if (res && res.message) {
          setSnackBarState({
            isOpen: true,
            snackBarType: SNACK_BAR_TYPES.ERROR,
            message: res.toString(),
          })
        } else {
          setSnackBarState({
            isOpen: true,
            snackBarType: SNACK_BAR_TYPES.ERROR,
            message: "Error - something went wrong, please try again.",
          })
        }
      }
    )
  }

  const handleDeleteFolderItem = (itemId: number, itemType: string) => {
    deleteFolderItem({
      variables: { itemId, itemType },
    }).then(
      () => {
        setSnackBarState({
          isOpen: true,
          snackBarType: SNACK_BAR_TYPES.SUCCESS,
          message: `Tag Moved`,
        })
      },
      (res: any) => {
        if (res && res.message) {
          setSnackBarState({
            isOpen: true,
            snackBarType: SNACK_BAR_TYPES.ERROR,
            message: res.toString(),
          })
        } else {
          setSnackBarState({
            isOpen: true,
            snackBarType: SNACK_BAR_TYPES.ERROR,
            message: "Error - something went wrong, please try again.",
          })
        }
      }
    )
  }

  const handleUpdateFolder = (id: number, parentFolderId?: number, title?: string) => {
    editFolder({
      variables: { id, parentFolderId, title },
      refetchQueries: [getFoldersQuery],
    }).then(
      () => {
        setSnackBarState({
          isOpen: true,
          snackBarType: SNACK_BAR_TYPES.SUCCESS,
          message: `Folder Moved`,
        })
      },
      (res: any) => {
        if (res && res.message) {
          setSnackBarState({
            isOpen: true,
            snackBarType: SNACK_BAR_TYPES.ERROR,
            message: res.toString(),
          })
        } else {
          setSnackBarState({
            isOpen: true,
            snackBarType: SNACK_BAR_TYPES.ERROR,
            message: "Error - something went wrong, please try again.",
          })
        }
      }
    )
  }

  // TODO: put this in a use effect so it doesn't needlessly run
  const buildTreeData = (): NodeModel<{
    pin: PinInterface
    contentType: typeof FOLDER | typeof CATEGORY | typeof TAG
  }>[] => {
    const formattedFolderData = folderQueryData.data?.folders
      ? buildFolderData(folderQueryData.data.folders, TAG, isNotMobile, organizationId)
      : []

    const formattedTagData = tagQueryData.data?.tags
      ? buildTagData(tagQueryData.data.tags, isNotMobile, organizationId, isItemChecked, isUserOnLinksPage, toggleItem)
      : []

    // @ts-ignore
    return formattedFolderData.concat(formattedTagData)
  }

  const [isTreeOpen, setIsTreeOpen] = useState<boolean>(false)

  const toggleTagDropdown = () => {
    setIsTreeOpen(!isTreeOpen)
  }

  const determineItemTypeFromDragSourceId = (dragSourceId: string) => {
    return dragSourceId.split("-")[0]
  }

  const determineItemIdFromDragSourceId = (dragSourceId: string) => {
    return parseInt(dragSourceId.split("-")[1]) || 0
  }

  const handleDrop = (newTree: any, { dragSourceId, dropTargetId, dragSource, dropTarget }: any) => {
    // TODO: throw front end error and don't do anything if itemId or itemType or folderId don't exist
    // instead of returning empty string or 0. Note: must handle valid use of setting folder_id to 0
    const itemType = determineItemTypeFromDragSourceId(dragSourceId)
    const itemId = determineItemIdFromDragSourceId(dragSourceId)

    if (itemType === TAG) {
      if (dropTargetId) {
        const folderId = determineItemIdFromDragSourceId(dropTargetId)
        handleCreateOrUpdateFolderItem(itemId, itemType, folderId)
      } else if (dropTargetId === 0) {
        handleDeleteFolderItem(itemId, itemType)
      }
    } else if (itemType === FOLDER) {
      const folderId = determineItemIdFromDragSourceId(dragSourceId)
      const parentFolderId = dropTargetId ? determineItemIdFromDragSourceId(dropTargetId) : 0
      handleUpdateFolder(folderId, parentFolderId)
    }
  }

  const LABEL_BY = "link-side-drawer-tags"

  const ariaLabel = () => {
    return `toggle-tag-list. List currently ${isTreeOpen ? "open" : "closed"}`
  }

  return (
    <>
      <ListItem
        key={`${LABEL_BY}`}
        id={`${LABEL_BY}`}
        disablePadding
        aria-label={ariaLabel()}
        aria-pressed={isTreeOpen}
        dense={isNotMobile}
      >
        <TagIcon aria-labelledby={`${LABEL_BY}`} toggleTagDropdown={toggleTagDropdown} />

        <ListItemButton onClick={toggleTagDropdown} sx={{ pr: 1 }}>
          <ListItemText
            primary="Tags"
            aria-labelledby={`${LABEL_BY}`}
            primaryTypographyProps={{
              "aria-labelledby": `${LABEL_BY}`,
              variant: "h6",
            }}
          />
          <UserTagDropdown organizationId={organizationId} />
          {/* {isTreeOpen ? <ExpandMore /> : <ExpandLess />} */}
        </ListItemButton>
      </ListItem>

      <Collapse in={isTreeOpen} timeout={COLLAPSE_DEFAULT_TIMEOUT} unmountOnExit>
        <SideDrawerTreeView treeData={buildTreeData()} handleDrop={handleDrop} isDisabled={isDisabled} />
      </Collapse>
    </>
  )
}
