import * as React from "react"
import { createContext, useState } from "react"

interface FavoritesSelectedContextInterface {
  favoritesSelectedState: boolean
  setFavoritesSelectedState: React.Dispatch<React.SetStateAction<boolean>>
}

const defaultState = false

export const FavoritesSelectedContext = createContext<FavoritesSelectedContextInterface>({
  favoritesSelectedState: defaultState,
  setFavoritesSelectedState: () => {},
})

export const FavoritesSelectedContextProvider = (props: { children: React.ReactNode }) => {
  const [favoritesSelectedState, setFavoritesSelectedState] = useState(defaultState)

  return (
    <FavoritesSelectedContext.Provider
      value={{
        favoritesSelectedState,
        setFavoritesSelectedState,
      }}
    >
      {props.children}
    </FavoritesSelectedContext.Provider>
  )
}
