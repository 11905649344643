import React, { useContext, useState, useRef, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useApolloClient } from "@apollo/client"

// mui
import Typography from "@mui/material/Typography"
import FormControl from "@mui/material/FormControl"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Link from "@mui/material/Link"

// Components
import { InputTextField } from "components/InputTextField"

// Contexts
import { ModalContext } from "contexts/ModalContext"
import { CurrentUserContext } from "contexts/CurrentUserContext"

// Utils
import { ApiUtils } from "../../Utils/ApiUtils"
import { debounceFunction } from "Utils/Utils"
import { getActiveUserQuery } from "queries/queries"

// Types
import { DEBOUNCE_TIME } from "constants/Global"
import { MODAL_TYPES } from "components/Modal/ModalTypes"

export const SecondFactorModal = () => {
  const timeout = useRef<any>()
  const { modalState, setModalState } = useContext(ModalContext)
  const { updateCurrentUserState } = useContext(CurrentUserContext)
  const navigate = useNavigate()
  const client = useApolloClient()

  const [inputCode, setInputCode] = useState("")
  const [inputErrorText, setInputErrorText] = useState("")

  const closeModal = () => {
    setModalState({ isOpen: false, modalType: "" })
  }

  const openLostAuthenticatorModal = () => {
    setModalState({
      isOpen: true,
      modalType: MODAL_TYPES.LOST_AUTHENTICATOR,
      data: { login_stage_two_code: modalState.data.login_stage_two_code },
    })
  }

  const openWebauthnModal = () => {
    setModalState({
      isOpen: true,
      modalType: MODAL_TYPES.WEBAUTHN_FACTOR,
      data: { 
        login_stage_two_code: modalState.data.login_stage_two_code,
        remember_me: modalState.data.remember_me
     },
    })
  }

  useEffect(() => {
    // enter submits form
    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault()
        debouncedHandleSubmitCode()
      }
    }
    document.addEventListener("keydown", listener)
    return () => {
      document.removeEventListener("keydown", listener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputCode])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputCode(event.target.value)
  }

  const debouncedHandleSubmitCode = () => {
    debounceFunction(timeout, handleSubmitCode, DEBOUNCE_TIME)
  }

  const handleSubmitCode = () => {
    setInputErrorText("")
    ApiUtils.secondFactor({
      login_stage_two_code: modalState.data.login_stage_two_code,
      remember_me: modalState.data.remember_me,
      otp: inputCode,
    }).then(
      (res: any) => {
        closeModal()
        // eslint-disable-next-line no-restricted-globals
        let intended = history?.state?.usr?.from
        updateCurrentUserState(res)
        client.refetchQueries({
          include: [getActiveUserQuery],
        })
        if (intended) {
          navigate(intended)
        } else {
          navigate("/links")
        }
      },
      (res: object) => {
        setInputErrorText("Login failed! Please try again.")
      }
    )
  }

  return (
    <>
      <Typography textAlign="center" variant="h5" sx={{ my: 2 }}>
        Authenticator Code
      </Typography>
      <Typography textAlign="center" sx={{ my: 2 }}>
        Please enter the code from your two-factor device.{" "}
        <Link onClick={openWebauthnModal} sx={{ cursor: "pointer" }}>
          Use WebAuthn instead.
        </Link>
      </Typography>
      <InputTextField
        autoComplete={"off"}
        name="2-factor-code"
        helperText="2 Factor Code"
        handleChange={handleChange}
        canToggleTextFieldVisibility={false}
        shouldShowTextField={true}
        handleClickShowTextField={() => {}}
        textField={inputCode}
        error={!!inputErrorText}
        errorText={inputErrorText}
      />

      <Box sx={{ textAlign: "center" }}>
        <Button
          variant="text"
          color="secondary"
          onClick={openLostAuthenticatorModal}
          size="small"
        >
          I cant login
        </Button>
      </Box>
      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Button
              variant="outlined"
              color="warning"
              onClick={closeModal}
              sx={{ width: { xs: "100%", md: "auto" } }}
              aria-label={`Cancel edit tag`}
            >
              Cancel
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              mt: { xs: 2, md: 0 },
              display: "flex",
              flexDirection: { xs: "row", md: "row-reverse" },
            }}
          >
            <FormControl
              error={!!inputErrorText}
              sx={{ width: { xs: "100%", md: "auto" } }}
            >
              <Button
                variant="contained"
                onClick={debouncedHandleSubmitCode}
                disabled={false}
                aria-label={`Save changes to tag`}
              >
                Submit
              </Button>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
