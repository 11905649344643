import { useContext } from "react"

// mui
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"
import Box from "@mui/material/Box"
import Link from "@mui/material/Link"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import IosShareIcon from "@mui/icons-material/IosShare"

import { ModalContext } from "contexts/ModalContext"
import { MODAL_TYPES } from "components/Modal/ModalTypes"

export function DownloadPage() {
  const { setModalState } = useContext(ModalContext)

  const openIphoneModal = () => {
    setModalState({
      isOpen: true,
      modalType: MODAL_TYPES.IPHONE_DOWNLOAD_SCREENSHOT,
    })
  }

  return (
    <Box sx={{ mx: 2 }}>
      <Typography variant="h4" sx={{ mt: 2, mb: 1 }}>
        Installing Linkidex
      </Typography>
      <Typography variant="h5" sx={{ mt: 2, mb: 1 }}>
        Android (Chrome)
      </Typography>
      <Typography>
        Tap <MoreVertIcon sx={{ verticalAlign: "middle" }} />
        on the top right and tap 'Add to Home screen'
      </Typography>
      <Typography variant="h5" sx={{ mt: 2, mb: 1 }}>
        iPhone (Safari)
      </Typography>
      <Typography>
        Tap <IosShareIcon sx={{ verticalAlign: "middle" }} /> on the bottom of
        the screen and tap
        <Link onClick={openIphoneModal} sx={{ px: 1, cursor: "pointer" }}>
          'Add to Home Screen.'
        </Link>
      </Typography>

      <Divider sx={{ my: 1 }} />

      <Typography variant="h5" sx={{ mt: 2, mb: 1 }}>
        Play Store / App Store
      </Typography>
      <Typography>
        Currently Linkidex must be installed through your web browser.
      </Typography>
    </Box>
  )
}
