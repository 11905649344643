import { useContext } from "react"
import { ApiUtils } from "Utils/ApiUtils"
import { SnackBarContext } from "contexts/SnackBarContext"
import { SNACK_BAR_TYPES } from "components/SnackBar/SnackBarTypes"

const useFileUpload = () => {
  const { setSnackBarState } = useContext(SnackBarContext)

  const handleFileUpload = (file) => {
    ApiUtils.getPresignedS3Url({ fileName: file.name }).then((uploadParams) => {
      if (uploadParams) {
        uploadToS3(uploadParams, file)
      }
    })
  }

  const uploadToS3 = (uploadParams, file) => {
    const { url, s3_upload_params: fields } = uploadParams
    const formData = new FormData()
    formData.append("Content-Type", file.type)
    Object.entries(fields).forEach(([k, v]) => {
      formData.append(k, v)
    })
    formData.append("file", file)
    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((awsResponse) => {
        if (awsResponse.ok) {
          ApiUtils.uploadBookmarksSuccess(uploadParams.file_upload_id)
          setSnackBarState({
            isOpen: true,
            snackBarType: SNACK_BAR_TYPES.SUCCESS,
            message:
              "Upload Successful! Your bookmarks will begin processing shortly",
          })
        } else {
          setSnackBarState({
            isOpen: true,
            snackBarType: SNACK_BAR_TYPES.ERROR,
            message:
              "Something went wrong! Please make sure you are uploading an 'html' file generated by your browser.",
          })
        }
      })
      .catch((error) => {
        setSnackBarState({
          isOpen: true,
          snackBarType: SNACK_BAR_TYPES.ERROR,
          message:
            "Something went wrong! Please make sure you are uploading an 'html' file generated by your browser.",
        })
      })
  }

  return {
    handleFileUpload,
  }
}
export default useFileUpload
