import * as React from "react"
import { createContext, useState } from "react"

interface SelectedTagsInterface {
  [key: number]: boolean
}

interface SelectedTagsContextInterface {
  selectedTagsState: SelectedTagsInterface
  setSelectedTagsState: React.Dispatch<React.SetStateAction<SelectedTagsInterface>>
}

const defaultState = {}

export const SelectedTagsContext = createContext<SelectedTagsContextInterface>({
  selectedTagsState: defaultState,
  setSelectedTagsState: () => {},
})

export const SelectedTagsContextProvider = (props: { children: React.ReactNode }) => {
  const [selectedTagsState, setSelectedTagsState] = useState(defaultState)

  return (
    <SelectedTagsContext.Provider
      value={{
        selectedTagsState,
        setSelectedTagsState,
      }}
    >
      {props.children}
    </SelectedTagsContext.Provider>
  )
}
