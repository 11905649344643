import React from "react"

// Components
import { ResetPasswordForm } from "components/ResetPasswordForm"

export const ResetPasswordPage = () => {
  return (
    <>
      <ResetPasswordForm />
    </>
  )
}
