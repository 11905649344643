import React from "react"
import { SignUpForm } from "components/SignUpForm"

export const SignUpModal = () => {
  return (
    <>
      <SignUpForm />
    </>
  )
}
