import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"

export const OrganizationRolesDescriptionModal = () => {
  return (
    <>
      <Typography
        textAlign="center"
        variant="h4"
        sx={{ my: 2 }}
        aria-label={`Organization Roles`}
      >
        Organization Roles
      </Typography>

      <Typography sx={{ my: 2 }}>
        <strong>Admin </strong>- User can create invites, remove others from an
        organization, manage others's permissions, and create, edit, and delete
        links, categories, tags, and folders.
      </Typography>
      <Typography sx={{ my: 2 }}>
        <strong>Billing </strong>- User can manage the billing information of
        the Organization
      </Typography>
      <Typography sx={{ my: 2 }}>
        <strong>Contributor </strong>- User can create, edit, and delete links,
        categories, tags, and folders.
      </Typography>
      <Divider />

      <Typography sx={{ my: 2 }}>
        All members of an Organization can view the Organization's links,
        categories, tags and folders.
      </Typography>
    </>
  )
}
