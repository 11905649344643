import React, { useContext } from "react"

// mui
import Button from "@mui/material/Button"

import { SnackBarContext } from "contexts/SnackBarContext"
import { SNACK_BAR_TYPES } from "components/SnackBar/SnackBarTypes"
import { resendVerifyEmailMutation } from "queries/queries"
import { useMutation } from "@apollo/client"

export const ResendEmailVerification = () => {
  const { setSnackBarState } = useContext(SnackBarContext)
  const [resendVerifyEmail] = useMutation(resendVerifyEmailMutation)

  const handleResendEmailVerification = () => {
    resendVerifyEmail({
      variables: {},
    }).then(
      () => {
        setSnackBarState({
          isOpen: true,
          snackBarType: SNACK_BAR_TYPES.SUCCESS,
          message: `Email verification sent!`,
        })
      },
      (res: any) => {
        if (res && res.message) {
          setSnackBarState({
            isOpen: true,
            snackBarType: SNACK_BAR_TYPES.ERROR,
            message: res.toString(),
          })
        } else {
          setSnackBarState({
            isOpen: true,
            snackBarType: SNACK_BAR_TYPES.ERROR,
            message: "Error - something went wrong, please try again.",
          })
        }
      }
    )
  }

  return (
    <Button
      size="small"
      onClick={handleResendEmailVerification}
      aria-label="Resend Email Verification"
    >
      Resend Verification Email
    </Button>
  )
}
