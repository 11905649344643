/* eslint-disable no-restricted-globals */
import React from "react"
import { useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"

// MUI Components
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"
import Alert from "@mui/material/Alert"
import Collapse from "@mui/material/Collapse"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import FormControl from "@mui/material/FormControl"
import FormHelperText from "@mui/material/FormHelperText"

// Components
import { InputTextField } from "../InputTextField"

// Utils
import { ApiUtils } from "../../Utils/ApiUtils"
import { isValidEmail, debounceFunction } from "../../Utils/Utils"

// TYPES
import { DEBOUNCE_TIME } from "constants/Global"

// Styles
import { forgotPasswordFormStyle } from "./styles"

export interface ForgotPasswordFormInterface {
  email: string
}

export const ForgotPasswordForm = () => {
  const timeout = useRef<any>()
  const navigate = useNavigate()
  const [emailInputError, setEmailInputError] = React.useState<string>("")
  const [isSubmitDisabled, setIsSubmitDisabled] = React.useState<boolean>(false)
  const [emailInputErrorText, setEmailInputErrorText] =
    React.useState<string>(" ")
  const [isOpen, setIsOpen] = React.useState<boolean | undefined>(false)
  const [values, setValues] = React.useState<ForgotPasswordFormInterface>({
    email: "",
  })

  const handleChange =
    (prop: keyof ForgotPasswordFormInterface) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value })
    }

  const handlePasswordRecovery = () => {
    ApiUtils.sendAccountRecoveryEmail({
      email: values.email,
    }).then(
      (res: object) => {
        setIsOpen(true)
        setValues({ email: "" })
      },
      (res: object) => {
        setEmailInputError("Error - something went wrong, please try again.")
      }
    )
  }

  const debouncedHandlePasswordRecovery = () => {
    debounceFunction(timeout, handlePasswordRecovery, DEBOUNCE_TIME)
  }

  useEffect(() => {
    if (values.email.length === 0) {
      setIsSubmitDisabled(true)
      setEmailInputError("")
    } else if (!isValidEmail(values.email)) {
      setIsSubmitDisabled(true)
      setEmailInputError("Invalid Email!")
    } else {
      setIsSubmitDisabled(false)
      setEmailInputError("")
    }
  }, [values])

  const successAlert = () => {
    return (
      <>
        <Collapse in={isOpen} sx={{ px: { xs: 2, md: 6 } }}>
          <Alert
            sx={{ mt: 1 }}
            variant="outlined"
            severity="success"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setIsOpen(false)
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <Typography>
              Recovery instructions have been sent to the given email, if it is
              associated to a verified account. Please check your inbox.
            </Typography>
          </Alert>
        </Collapse>
      </>
    )
  }

  return (
    <>
      {successAlert()}
      <Box sx={forgotPasswordFormStyle}>
        <Typography variant="h4" textAlign="center" sx={{ my: 2 }}>
          Forgot Password
        </Typography>

        {/* input email */}
        <InputTextField
          name="email"
          helperText="email"
          handleChange={handleChange("email")}
          canToggleTextFieldVisibility={false}
          shouldShowTextField={true}
          handleClickShowTextField={() => {}}
          textField={values.email}
          error={!!emailInputError}
          errorText={emailInputError}
        />

        <Button
          variant="contained"
          disabled={isSubmitDisabled}
          onClick={debouncedHandlePasswordRecovery}
        >
          Send Password Recovery
        </Button>

        <br />

        <Divider sx={{ my: 2 }} />

        <Button
          variant="outlined"
          color="warning"
          onClick={() => navigate("/")}
        >
          Cancel
        </Button>
      </Box>
    </>
  )
}
