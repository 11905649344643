import { useContext } from "react"

import { useTheme } from "@mui/material/styles"
import { SideDrawerContext } from "contexts/SideDrawerContext"
import { FavoritesSelectedContext } from "contexts/FavoritesSelectedContext"
import { FAVORITE_COLOR } from "constants/Global"
// mui
import Card from "@mui/material/Card"
import Tooltip from "@mui/material/Tooltip"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import ListItemIcon from "@mui/material/ListItemIcon"
import FavoriteIcon from "@mui/icons-material/Favorite"
import DeleteIcon from "@mui/icons-material/Delete"
interface FiltersCardPropsInterface {
  isNotMobile: boolean
  clearAllFilters: () => void
}

export const FiltersCard = (props: FiltersCardPropsInterface) => {
  const { isNotMobile, clearAllFilters } = props
  const theme = useTheme()
  const { sideDrawerState } = useContext(SideDrawerContext)
  const { favoritesSelectedState, setFavoritesSelectedState } = useContext(FavoritesSelectedContext)

  return (
    <Card
      sx={{
        mx: 1,
        my: 2,
        p: 0,
        borderRadius: 2,
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: theme.palette.divider,
        boxShadow: 0,
      }}
    >
      <ListItem component="span" disablePadding dense={!isNotMobile} sx={{ px: 0 }}>
        <Tooltip enterDelay={500} title="clear all filters" followCursor>
          <ListItemButton id="side-drawer-clear-filters" onClick={clearAllFilters} sx={{ pr: 1 }}>
            <ListItemIcon sx={{ mr: 0, pr: 2, minWidth: "0px" }}>
              <DeleteIcon
                sx={{
                  color: theme.palette.error.main,
                }}
              />
            </ListItemIcon>

            <ListItemText
              primaryTypographyProps={{
                maxWidth: sideDrawerState.width,
                flexGrow: 1,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                flexBasis: "100%",
              }}
              primary="Clear Filters"
            />
          </ListItemButton>
        </Tooltip>
      </ListItem>
      <ListItem component="span" disablePadding dense={!isNotMobile} sx={{ px: 0 }}>
        <ListItemButton
          selected={favoritesSelectedState}
          onClick={() => {
            setFavoritesSelectedState(!favoritesSelectedState)
          }}
          sx={{ pr: 1 }}
        >
          <ListItemIcon sx={{ mr: 0, pr: 2, minWidth: "0px" }}>
            <FavoriteIcon sx={{ color: FAVORITE_COLOR }} />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              maxWidth: sideDrawerState.width,
              flexGrow: 1,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              flexBasis: "100%",
            }}
            primary="Favorites"
          />
        </ListItemButton>
      </ListItem>
    </Card>
  )
}
