import { useContext } from "react"

import Typography from "@mui/material/Typography"

// Components
import { CloseModalButton } from "components/Modal/CloseModalButton"

// Contexts
import { ModalContext } from "contexts/ModalContext"

export const UseCategoriesAndTagsForAiSuggestHowToModal = () => {
  const { setModalState } = useContext(ModalContext)

  const closeModal = () => {
    setModalState({ isOpen: false, modalType: "" })
  }

  return (
    <>
      <CloseModalButton closeModal={closeModal} />
      <Typography
        textAlign="center"
        variant="h4"
        sx={{ my: 1 }}
        aria-label="Using Your Categories and Tags for AI Suggestions"
      >
        Using Your Categories and Tags for AI Suggestions
      </Typography>

      <Typography>
        Keeping this on will cause our AI provider to try and use your existing categories and tags when making
        suggestions.
      </Typography>
      <br />
      <Typography>
        Switching this off will make our AI provider not use your existing categories and tags when coming up with
        suggestions, and reduce the number of AI credits / tokens that are used each time you use AI suggestions.
      </Typography>
      <br />
      <Typography>We recommend keeping this setting on by default.</Typography>
    </>
  )
}
