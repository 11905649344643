import React, { useState, useContext, useEffect, useRef } from "react"
import { useMutation } from "@apollo/client"
import { getActiveUserQuery, updateUserPasswordMutation } from "queries/queries"

// MUI Components
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import FormHelperText from "@mui/material/FormHelperText"
import FormControl from "@mui/material/FormControl"
import Divider from "@mui/material/Divider"

// Components
import { InputTextField } from "components/InputTextField"
import { CloseModalButton } from "components/Modal/CloseModalButton"

// Contexts
import { ModalContext } from "../../contexts/ModalContext"
import { SnackBarContext } from "../../contexts/SnackBarContext"

// Utils
import { SNACK_BAR_TYPES } from "components/SnackBar/SnackBarTypes"
import { debounceFunction } from "Utils/Utils"

// TYPES
import { DEBOUNCE_TIME } from "constants/Global"

interface UpdatePasswordModalInterface {
  password: string
  newPassword: string
  confirmNewPassword: string
}

export const UpdatePasswordModal = () => {
  const timeout = useRef<any>()
  const [updatePasswordError, setUpdatePasswordError] = useState<boolean>(false)
  const [updatePasswordErrorText, setUpdatePasswordErrorText] =
    useState<string>(" ")
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
  const [values, setValues] = useState<UpdatePasswordModalInterface>({
    password: "",
    newPassword: "",
    confirmNewPassword: "",
  })
  const { setModalState } = useContext(ModalContext)
  const { setSnackBarState } = useContext(SnackBarContext)
  const [updateUserPassword] = useMutation(updateUserPasswordMutation)

  const closeModal = () => {
    setModalState({ isOpen: false, modalType: "" })
  }

  const debouncedHandleUpdatePassword = () => {
    debounceFunction(timeout, handleUpdatePassword, DEBOUNCE_TIME)
  }

  useEffect(() => {
    // user hasn't finished filling out the form
    if (
      values.newPassword.length === 0 ||
      values.confirmNewPassword.length === 0 ||
      values.password.length === 0
    ) {
      setIsSubmitDisabled(true)
      setUpdatePasswordError(false)
      setUpdatePasswordErrorText(" ")
    }
    // password is not long enough
    else if (values.newPassword.length !== 0 && values.newPassword.length < 8) {
      setUpdatePasswordErrorText("new password too short!")
      setUpdatePasswordError(true)
      setIsSubmitDisabled(true)
      // passwords don't match
    } else if (values.newPassword !== values.confirmNewPassword) {
      setUpdatePasswordErrorText("new passwords do not match!")
      setUpdatePasswordError(true)
      setIsSubmitDisabled(true)
      // password form is valid
    } else {
      setUpdatePasswordErrorText(" ")
      setUpdatePasswordError(false)
      setIsSubmitDisabled(false)
    }
  }, [values])

  useEffect(() => {
    const listener = (event: any) => {
      if (
        (event.code === "Enter" || event.code === "NumpadEnter") &&
        !isSubmitDisabled
      ) {
        event.preventDefault()
        debouncedHandleUpdatePassword()
      }
    }
    document.addEventListener("keydown", listener)
    return () => {
      document.removeEventListener("keydown", listener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitDisabled, values])

  const handleChange =
    (prop: keyof UpdatePasswordModalInterface) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value })
    }

  const handleUpdatePassword = () => {
    setUpdatePasswordError(false)
    setUpdatePasswordErrorText(" ")
    updateUserPassword({
      variables: {
        password: values.password,
        newPassword: values.newPassword,
      },
      refetchQueries: [{ query: getActiveUserQuery }],
    }).then(
      (res: object) => {
        setSnackBarState({
          isOpen: true,
          snackBarType: SNACK_BAR_TYPES.SUCCESS,
          message: "Password Updated Successfully!",
        })
        closeModal()
      },
      (res: any) => {
        setUpdatePasswordError(true)
        if (res && res.error) {
          setUpdatePasswordErrorText(res.error)
        } else {
          setUpdatePasswordErrorText(
            "Error - Something went wrong, please try again."
          )
        }
      }
    )
  }

  return (
    <>
      <CloseModalButton closeModal={closeModal} />
      <Typography
        textAlign="center"
        variant="h4"
        sx={{ my: 2 }}
        aria-live="polite"
        aria-label="Update Password Form. Please Enter your current password, your new password, and your new password again."
      >
        Update Password
      </Typography>
      <InputTextField
        name="current password"
        helperText="current password*"
        handleChange={handleChange("password")}
        canToggleTextFieldVisibility={false}
        shouldShowTextField={false}
        handleClickShowTextField={() => {}}
        textField={values.password}
        error={updatePasswordError}
        errorText={""}
      />

      <Divider sx={{ my: 3 }} />

      <InputTextField
        name="newPassword"
        helperText="New Password*"
        handleChange={handleChange("newPassword")}
        canToggleTextFieldVisibility={false}
        shouldShowTextField={false}
        handleClickShowTextField={() => {}}
        textField={values.newPassword}
        error={updatePasswordError}
        errorText={""}
      />

      <InputTextField
        name="confirmNewPassword"
        helperText="Confirm New Password*"
        handleChange={handleChange("confirmNewPassword")}
        canToggleTextFieldVisibility={false}
        shouldShowTextField={false}
        handleClickShowTextField={() => {}}
        textField={values.confirmNewPassword}
        error={updatePasswordError}
        errorText={""}
      />

      <FormControl
        error={updatePasswordError}
        aria-live="polite"
        aria-label={updatePasswordErrorText}
      >
        <FormHelperText aria-live="polite">
          {updatePasswordErrorText}
        </FormHelperText>
        <Button
          sx={{ mt: 1 }}
          variant="contained"
          onClick={() => debouncedHandleUpdatePassword()}
          disabled={isSubmitDisabled}
          aria-label="submit password update"
        >
          Update Password
        </Button>
      </FormControl>
    </>
  )
}
