import { createTheme } from "@mui/material/styles"

export const treeViewTheme = (mode: "light" | "dark") => {
  return createTheme({
    components: {
      MuiScopedCssBaseline: {
        styleOverrides: {
          root: {
            "*": {
              margin: 0,
              padding: 0,
            },
            "html, body, #root": {
              height: "100%",
            },
            ul: {
              listStyle: "none",
            },
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: { verticalAlign: "middle" },
        },
      },
    },
    palette: {
      mode: mode,
    },
  })
}
