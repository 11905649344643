import { useContext } from "react"

import Typography from "@mui/material/Typography"

// Components
import { CloseModalButton } from "components/Modal/CloseModalButton"

// Contexts
import { ModalContext } from "contexts/ModalContext"

export const ImportBookmarksHowTwoModal = () => {
  const { setModalState } = useContext(ModalContext)

  const closeModal = () => {
    setModalState({ isOpen: false, modalType: "" })
  }

  return (
    <>
      <CloseModalButton closeModal={closeModal} />
      <Typography
        textAlign="center"
        variant="h4"
        sx={{ my: 1 }}
        aria-label={`Welcome To Linkidex!`}
      >
        Uploading Bookmarks to Linkidex
      </Typography>
    </>
  )
}
