import React from "react"

// Components
import { App } from "./App"

// Utils
import { LightDarkModeContextProvider } from "./contexts/LightDarkModeContext"

function AppThemeWrapper() {
  return (
    <>
      <LightDarkModeContextProvider>
        <App />
      </LightDarkModeContextProvider>
    </>
  )
}

export default AppThemeWrapper
