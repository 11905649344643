// MUI
import Box from "@mui/material/Box"
import Link from "@mui/material/Link"
import Typography from "@mui/material/Typography"

export const BottomLegalLinks = () => {
  return (
    <Typography sx={{ display: "flex", justifyContent: "center", px: 1, pb: 5 }}>
      <Link href="/privacy_policy" sx={{ px: 1 }}>
        Privacy Policy
      </Link>
      |
      <Link href="/terms_of_service" sx={{ px: 1 }}>
        Terms of Service
      </Link>
    </Typography>
  )
}
