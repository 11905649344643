import React, { useContext } from "react"
import { ThemeProvider, createTheme, responsiveFontSizes } from "@mui/material/styles"
import Paper from "@mui/material/Paper"

// Components
import { Navbar } from "./components/Navbar"
import { ActivePage } from "./components/ActivePage"
import { Modal } from "./components/Modal"
import { SnackBar } from "./components/SnackBar"
import { SideDrawerWrapper } from "components/SideDrawerWrapper"

// Utils
import { CurrentUserContextProvider } from "./contexts/CurrentUserContext"
import { ModalContextProvider } from "./contexts/ModalContext"
import { SnackBarContextProvider } from "./contexts/SnackBarContext"
import { LightDarkModeContext } from "./contexts/LightDarkModeContext"
import { SideDrawerContextProvider } from "./contexts/SideDrawerContext"
import { SelectedCategoriesContextProvider } from "contexts/SelectedCategoriesContext"
import { SelectedTagsContextProvider } from "contexts/SelectedTagsContext"
import { SelectedOrganizationsContextProvider } from "contexts/SelectedOrganizationsContext"
import { PersonalLinksSelectedContextProvider } from "contexts/PersonalLinksSelectedContext"
import { FavoritesSelectedContextProvider } from "contexts/FavoritesSelectedContext"
import { UserOnboardingTourContextProvider } from "contexts/UserOnboardingTourContext"
import { PersonalLinkCardContextProvider } from "contexts/PersonalLinkCardContext"
import { IsStrictSearchContextProvider } from "contexts/IsStrictSearchContext"

export function App() {
  const { currentModeState } = useContext(LightDarkModeContext)

  let theme = createTheme({
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            scrollbarWidth: "thin",
          },
        },
      },
    },
    ...currentModeState,
  })

  theme = responsiveFontSizes(theme)
  theme.typography.h1 = {
    fontWeight: "normal",
    fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
    fontSize: "50px",
    "@media (min-width:600px)": {
      fontSize: "65px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "70px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "75px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "80px",
    },
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Paper
          elevation={3}
          square={true}
          sx={{
            mx: "auto",
            minHeight: "100vh",
            overflowX: "hidden",
          }}
        >
          <CurrentUserContextProvider>
            <ModalContextProvider>
              <SnackBarContextProvider>
                <SideDrawerContextProvider>
                  <SelectedCategoriesContextProvider>
                    <SelectedTagsContextProvider>
                      <SelectedOrganizationsContextProvider>
                        <PersonalLinksSelectedContextProvider>
                          <FavoritesSelectedContextProvider>
                            <PersonalLinkCardContextProvider>
                              <IsStrictSearchContextProvider>
                                <UserOnboardingTourContextProvider>
                                  <div className="App">
                                    <Navbar />
                                    <Modal />
                                    <SnackBar />
                                    <SideDrawerWrapper />
                                    <ActivePage />
                                  </div>
                                </UserOnboardingTourContextProvider>
                              </IsStrictSearchContextProvider>
                            </PersonalLinkCardContextProvider>
                          </FavoritesSelectedContextProvider>
                        </PersonalLinksSelectedContextProvider>
                      </SelectedOrganizationsContextProvider>
                    </SelectedTagsContextProvider>
                  </SelectedCategoriesContextProvider>
                </SideDrawerContextProvider>
              </SnackBarContextProvider>
            </ModalContextProvider>
          </CurrentUserContextProvider>
        </Paper>
      </ThemeProvider>
    </>
  )
}
