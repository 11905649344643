import QRCode from "react-qr-code"

// mui
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import FormControl from "@mui/material/FormControl"
import FormHelperText from "@mui/material/FormHelperText"
import Alert from "@mui/material/Alert"
import Divider from "@mui/material/Divider"

// types
import { UserSecondFactorInterface } from "interfaces/UserSecondFactor"

// components
import { InputTextField } from "components/InputTextField"

interface RegisterSecondFactorPropsInterface {
  closeModal: () => void
  handleTwoFactorChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  debouncedHandleSubmitTwoFactorCode: () => void
  userSecondFactor: UserSecondFactorInterface
  twoFactorCode: string
  inputTwoFactorErrorText: string
}

export const RegisterSecondFactor = (
  props: RegisterSecondFactorPropsInterface
) => {
  const {
    closeModal,
    handleTwoFactorChange,
    debouncedHandleSubmitTwoFactorCode,
    userSecondFactor,
    twoFactorCode,
    inputTwoFactorErrorText,
  } = props

  return (
    <>
      <Alert variant="outlined" severity="error">
        Two-factor authentication is not enabled on your account.
      </Alert>

      <Typography sx={{ my: 1 }}>
        To activate two factor, scan the QR code below with your authenticator
        app, then input the code generated by your authenticator
      </Typography>
      <Divider sx={{ my: 1 }} />
      <Box sx={{ textAlign: "center", backgroundColor: "white", py: 5 }}>
        <QRCode value={userSecondFactor.provisioning_uri} />
      </Box>
      <Box sx={{ textAlign: "center", mt: 1 }}>
        {userSecondFactor.otp_secret}
      </Box>

      <InputTextField
        autoComplete={"off"}
        name="Authenticator Code"
        helperText="Authenticator Code"
        handleChange={handleTwoFactorChange}
        canToggleTextFieldVisibility={false}
        shouldShowTextField={true}
        handleClickShowTextField={() => {}}
        textField={twoFactorCode}
        error={!!inputTwoFactorErrorText}
        errorText={inputTwoFactorErrorText}
      />
      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Button
              variant="outlined"
              color="warning"
              onClick={closeModal}
              sx={{ width: { xs: "100%", md: "auto" } }}
              aria-label={`cancel`}
            >
              Cancel
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              mt: { xs: 2, md: 0 },
              display: "flex",
              flexDirection: { xs: "row", md: "row-reverse" },
            }}
          >
            <FormControl
              error={!!inputTwoFactorErrorText}
              sx={{ width: { xs: "100%", md: "auto" } }}
            >
              <Button
                variant="contained"
                onClick={debouncedHandleSubmitTwoFactorCode}
                disabled={false}
                aria-label={`submit two factor code`}
              >
                Submit
              </Button>
              <FormHelperText aria-live="polite">
                {inputTwoFactorErrorText}
              </FormHelperText>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
