import CloseIcon from "@mui/icons-material/Close"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import Box from "@mui/material/Box"

import { styled, useTheme } from "@mui/material/styles"

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}))

interface SideDrawerHeaderProps {
  closeSideDrawer: () => void
}

export const SideDrawerHeader = (props: SideDrawerHeaderProps) => {
  const { closeSideDrawer } = props
  const theme = useTheme()

  return (
    <DrawerHeader theme={theme} sx={{ px: 0 }}>
      <Box sx={{ display: "flex" }}></Box>
      <Box sx={{ display: "flex", flexGrow: 1 }}></Box>
      <Tooltip followCursor enterDelay={500} title="Close">
        <IconButton aria-label="close" onClick={closeSideDrawer} color="warning" sx={{ display: "flex" }}>
          <CloseIcon />
        </IconButton>
      </Tooltip>
    </DrawerHeader>
  )
}
