import { useContext, useEffect } from "react"
import { Routes, Route, Navigate } from "react-router-dom"
import { styled } from "@mui/material/styles"
import Box from "@mui/material/Box"

// Components
import { UserOnboardingTour } from "./UserOnboardingTour"
import { AccountPage } from "pages/AccountPage"
import { DownloadPage } from "pages/DownloadPage"
import { OrganizationsPage } from "pages/OrganizationsPage"
import { ManageOrganizationPage } from "pages/ManageOrganizationPage"
import { LoginPage } from "pages/LoginPage"
import { LinkPage } from "pages/LinkPage"
import { UpdateAppAlertBar } from "components/UpdateAppAlertBar"
import { EmailAlertBar } from "components/EmailAlertBar"
// import { CategoriesPage } from "pages/CategoriesPage"
// import { TagsPage } from "pages/TagsPage"
import { VerifyEmailPage } from "pages/VerifyEmailPage"
import { LandingPage } from "pages/LandingPage"
import { ForgotPasswordPage } from "pages/ForgotPasswordPage"
import { ResetPasswordPage } from "pages/ResetPasswordPage"
import { PrivacyPolicyPage } from "pages/PrivacyPolicyPage"
import { TermsOfServicePage } from "pages/TermsOfServicePage"
import { CookiesPolicyPage } from "pages/CookiesPolicyPage"
import { DmcaPage } from "pages/DmcaPage"
import { UserPolicyPage } from "pages/UserPolicyPage"
import { AiGuidelinesPage } from "pages/AiGuidelinesPage"
import { PricingPage } from "pages/PricingPage"
import { JoinOrganizationPage } from "pages/JoinOrganizationPage"
import { UpgradeOrganizationPage } from "pages/UpgradeOrganizationPage"
import { UpgradeUserPage } from "pages/UpgradeUserPage"
import { ProtectedRoutes } from "components/ProtectedRoutes"
import { RecoveryRoutes } from "components/RecoveryRoutes"

// Contexts
import { CurrentUserContext } from "contexts/CurrentUserContext"
import { SideDrawerContext } from "contexts/SideDrawerContext"
import { UserOnboardingTourContext } from "contexts/UserOnboardingTourContext"
import { ModalContext } from "contexts/ModalContext"

// Types
import { MODAL_TYPES } from "components/Modal/ModalTypes"

const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "width",
})<{
  open?: boolean
  width: number
}>(({ theme, open, width }) => ({
  flexGrow: 1,
  padding: theme.spacing(0),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: `${width}px`,
  }),
}))

export function ActivePage() {
  const { currentUserState } = useContext(CurrentUserContext)
  const { sideDrawerState } = useContext(SideDrawerContext)
  const { setModalState } = useContext(ModalContext)

  const { userOnboardingTourState, updateUserOnboardingTourState } = useContext(
    UserOnboardingTourContext
  )

  const openWelcomeModal = () => {
    setModalState({ isOpen: true, modalType: MODAL_TYPES.WELCOME_TO_LINKIDEX })
  }

  useEffect(() => {
    // trigger welcome modal when user account has not seen welcome modal, and user account is less than 1 hour old
    if (!userOnboardingTourState.hasSeenWelcome && currentUserState.createdAt) {
      const d = new Date()
      const oneHourAgo = d.setHours(d.getHours() - 1)

      if (Date.parse(currentUserState.createdAt) > oneHourAgo) {
        openWelcomeModal()
        updateUserOnboardingTourState({
          isOpen: false,
          hasSeenWelcome: true,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    updateUserOnboardingTourState,
    userOnboardingTourState.isOpen,
    userOnboardingTourState.hasSeenWelcome,
    currentUserState.createdAt,
  ])

  // Prevents app rapidly flickering between login page and a protected route when a current
  // user exists and the app is refreshed, as there is a delay between the react app being
  // loaded and the react app receiving the currentUser via API
  if (currentUserState.isLoading) {
    return <div></div>
  }

  return (
    <Main
      open={sideDrawerState.isOpen && !!currentUserState.email}
      width={sideDrawerState.width}
    >
      <UpdateAppAlertBar />
      <EmailAlertBar />
      <UserOnboardingTour />
      <Box sx={{ px: { xs: 0, md: 1 } }}>
        <Routes>
          {/* public routes */}
          <Route path="/" element={<LandingPage />} />

          {/* Account Recovery (re-route logged in users) */}

          <Route element={<RecoveryRoutes />}>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/forgot_password" element={<ForgotPasswordPage />} />
            <Route path="/reset_password/*" element={<ResetPasswordPage />}>
              <Route path=":code" element={<ResetPasswordPage />} />
            </Route>
          </Route>

          {/* Legal */}
          <Route path="/privacy_policy" element={<PrivacyPolicyPage />} />
          <Route path="/terms_of_service" element={<TermsOfServicePage />} />
          <Route path="/cookies_policy" element={<CookiesPolicyPage />} />
          <Route path="/dmca" element={<DmcaPage />} />
          <Route path="/user_policy" element={<UserPolicyPage />} />
          <Route path="/ai_guidelines" element={<AiGuidelinesPage />} />
          <Route path="/pricing" element={<PricingPage />} />

          {/* Routes requiring login */}
          <Route element={<ProtectedRoutes />}>
            <Route path="/account" element={<AccountPage />} />
            <Route path="/download" element={<DownloadPage />} />
            <Route path="/upgrade" element={<UpgradeUserPage />} />
            <Route path="/organizations" element={<OrganizationsPage />} />
            <Route
              path="/organizations/:organization_id"
              element={<ManageOrganizationPage />}
            />
            <Route
              path="/organizations/:organization_id/upgrade"
              element={<UpgradeOrganizationPage />}
            />
            <Route path="/links" element={<LinkPage />} />
            {/* <Route path="/categories" element={<CategoriesPage />} /> */}
            {/* <Route path="/tags" element={<TagsPage />} /> */}

            <Route path="/verify_email/*" element={<VerifyEmailPage />}>
              <Route path=":code" element={<VerifyEmailPage />} />
            </Route>
            <Route path="/join_org/*" element={<JoinOrganizationPage />}>
              <Route
                path=":organization_id/:invite_code"
                element={<JoinOrganizationPage />}
              />
            </Route>
          </Route>

          {/* any unhandled route navigates back to home page */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Box>
    </Main>
  )
}
