import { useContext } from "react"

// mui
import { SideDrawer } from "components/SideDrawer"

// Contexts
import { CurrentUserContext } from "contexts/CurrentUserContext"

export const SideDrawerWrapper = () => {
  // do not render the sidebar if currentUser does not exist.
  // This makes getCategoriesQuery and getTagsQuery within the sidebar fire
  // after the user logs in.
  const { currentUserState } = useContext(CurrentUserContext)

  if (!currentUserState.email) {
    return <></>
  }

  return (
    <>
      <SideDrawer />
    </>
  )
}
