import React from "react"
import { useContext } from "react"
import { useQuery } from "@apollo/client"

// MUI
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"
import { SxProps } from "@mui/material"

// Components
import { ModalContext } from "contexts/ModalContext"
import { ResendEmailVerification } from "components/ResendEmailVerification"
// Types
import { MODAL_TYPES } from "components/Modal/ModalTypes"

// Queries
import { getActiveUserQuery } from "queries/queries"

interface PasswordDetailsRowInterface {
  textStyle: SxProps
}

export function EmailDetailsRow({ textStyle }: PasswordDetailsRowInterface) {
  const { setModalState } = useContext(ModalContext)
  const { data } = useQuery(getActiveUserQuery)

  const openUpdateEmailModal = () => {
    setModalState({ isOpen: true, modalType: MODAL_TYPES.UPDATE_EMAIL })
  }

  const emailVerifiedText = () => {
    if (data?.activeUser?.emailVerified) {
      return "Email is verified!"
    } else {
      return (
        <>
          Please verify your email!
          <br />
          <ResendEmailVerification />
        </>
      )
    }
  }

  return (
    <>
      {/* Left part of row */}
      <Grid item sx={{ display: { marginTop: 20 } }} xs={12} sm={8}>
        <Typography sx={textStyle} variant="h6">
          {data?.activeUser?.email}
        </Typography>
        <Typography sx={textStyle} variant="subtitle2">
          {emailVerifiedText()}
        </Typography>
      </Grid>

      {/* Right part of row */}
      <Grid item sx={{ display: { marginTop: 20 } }} xs={12} sm={4}>
        <Button
          aria-label="update-email"
          onClick={openUpdateEmailModal}
          variant="outlined"
          sx={{ mt: { xs: 0, sm: 1.5 }, ml: { xs: 2, sm: 0 } }}
        >
          Update Email
        </Button>
      </Grid>
    </>
  )
}
