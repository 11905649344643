import React, { useContext, useState, useRef, useEffect } from "react"
import { useApolloClient } from "@apollo/client"
import { getActiveUserQuery } from "queries/queries"

// mui
import Typography from "@mui/material/Typography"

// Components
import { CloseModalButton } from "components/Modal/CloseModalButton"
import { ConfirmRemoveTwoFactor } from "./ConfirmRemoveTwoFactor"
import { SecondFactorDetails } from "./SecondFactorDetails"
import { RegisterSecondFactor } from "./RegisterSecondFactor"
import { PasswordChallenge } from "../PasswordChallenge"

// Contexts
import { ModalContext } from "contexts/ModalContext"
import { SnackBarContext } from "contexts/SnackBarContext"

// Utils
import { ApiUtils } from "Utils/ApiUtils"
import { debounceFunction } from "Utils/Utils"

// Types
import { DEBOUNCE_TIME } from "constants/Global"
import { SNACK_BAR_TYPES } from "components/SnackBar/SnackBarTypes"

export const ManageTwoFactorModal = () => {
  const client = useApolloClient()

  const timeout = useRef<any>()
  const { setModalState } = useContext(ModalContext)
  const { setSnackBarState } = useContext(SnackBarContext)

  const [inputPassword, setInputPassword] = useState("")
  const [twoFactorCode, setTwoFactorCode] = useState("")
  const [inputErrorText, setInputErrorText] = useState("")
  const [isConfirmRemoveTwoFactorOpen, setIsConfirmRemoveTwoFactorOpen] =
    useState(false)
  const [inputTwoFactorErrorText, setInputTwoFactorErrorText] = useState("")
  const [userSecondFactor, setUserSecondFactor] = useState<any>(null)

  const closeModal = () => {
    setModalState({ isOpen: false, modalType: "" })
  }

  const openConfirmRemoveTwoFactor = () => {
    setIsConfirmRemoveTwoFactorOpen(true)
  }

  useEffect(() => {
    // enter submits form
    const listener = (event: any) => {
      if (
        (event.code === "Enter" || event.code === "NumpadEnter") &&
        userSecondFactor === null
      ) {
        event.preventDefault()
        debouncedHandleSubmitPassword()
      }
    }
    document.addEventListener("keydown", listener)
    return () => {
      document.removeEventListener("keydown", listener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputPassword])

  useEffect(() => {
    // enter submits form
    const listener = (event: any) => {
      if (
        (event.code === "Enter" || event.code === "NumpadEnter") &&
        userSecondFactor &&
        !userSecondFactor.enabled
      ) {
        event.preventDefault()
        debouncedHandleSubmitTwoFactorCode()
      }
    }
    document.addEventListener("keydown", listener)
    return () => {
      document.removeEventListener("keydown", listener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [twoFactorCode])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputPassword(event.target.value)
  }

  const debouncedHandleSubmitPassword = () => {
    debounceFunction(timeout, handleSubmitPassword, DEBOUNCE_TIME)
  }

  const debouncedHandleSubmitTwoFactorCode = () => {
    debounceFunction(timeout, handleSubmitTwoFactorCode, DEBOUNCE_TIME)
  }

  const debouncedHandleRemoveTwoFactor = () => {
    debounceFunction(timeout, handleRemoveTwoFactor, DEBOUNCE_TIME)
  }

  const handleSubmitPassword = () => {
    setInputErrorText("")
    ApiUtils.getTwoFactorDetails({
      password: inputPassword,
    }).then(
      (res: any) => {
        setUserSecondFactor(res)
      },
      (res: object) => {
        setInputErrorText("Validation failed! Please try again.")
      }
    )
  }

  const handleSubmitTwoFactorCode = () => {
    setInputTwoFactorErrorText("")
    ApiUtils.registerTwoFactorAuthenticator({
      code: twoFactorCode,
      login_stage_two_code: userSecondFactor.login_stage_two_code,
    }).then(
      (res: any) => {
        setUserSecondFactor(res)
        setSnackBarState({
          isOpen: true,
          snackBarType: SNACK_BAR_TYPES.SUCCESS,
          message: "Two-Factor enabled.",
        })
        client.refetchQueries({
          include: [getActiveUserQuery],
        })
      },
      (res: object) => {
        setInputTwoFactorErrorText("Validation failed! Please try again.")
      }
    )
  }

  const handleRemoveTwoFactor = () => {
    setInputTwoFactorErrorText("")
    ApiUtils.removeTwoFactorAuthenticator({
      login_stage_two_code: userSecondFactor.login_stage_two_code,
    }).then(
      (res: any) => {
        closeModal()
        client.refetchQueries({
          include: [getActiveUserQuery],
        })
        setSnackBarState({
          isOpen: true,
          snackBarType: SNACK_BAR_TYPES.SUCCESS,
          message: "Two-Factor disabled.",
        })
      },
      (res: object) => {
        setInputTwoFactorErrorText("Validation failed! Please try again.")
      }
    )
  }

  const handleTwoFactorChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTwoFactorCode(event.target.value)
  }

  const determineModalBody = () => {
    if (isConfirmRemoveTwoFactorOpen) {
      return (
        <ConfirmRemoveTwoFactor
          closeModal={closeModal}
          inputErrorText={inputErrorText}
          debouncedHandleRemoveTwoFactor={debouncedHandleRemoveTwoFactor}
        />
      )
    } else if (userSecondFactor && userSecondFactor.enabled) {
      return (
        <SecondFactorDetails
          closeModal={closeModal}
          userSecondFactor={userSecondFactor}
          openConfirmRemoveTwoFactor={openConfirmRemoveTwoFactor}
        />
      )
    } else if (userSecondFactor && !userSecondFactor.enabled) {
      return (
        <RegisterSecondFactor
          closeModal={closeModal}
          handleTwoFactorChange={handleTwoFactorChange}
          debouncedHandleSubmitTwoFactorCode={
            debouncedHandleSubmitTwoFactorCode
          }
          userSecondFactor={userSecondFactor}
          twoFactorCode={twoFactorCode}
          inputTwoFactorErrorText={inputTwoFactorErrorText}
        />
      )
    } else {
      return (
        <PasswordChallenge
          closeModal={closeModal}
          debouncedHandleSubmitPassword={debouncedHandleSubmitPassword}
          handleChange={handleChange}
          inputPassword={inputPassword}
          inputErrorText={inputErrorText}
        />
      )
    }
  }

  return (
    <>
      <CloseModalButton closeModal={closeModal} />
      <Typography textAlign="center" variant="h4" sx={{ my: 2 }}>
        Manage Two-Factor
      </Typography>
      {determineModalBody()}
    </>
  )
}
