import React, { useContext } from "react"
import { useMutation } from "@apollo/client"
import { gql } from "@apollo/client"
import { createPinMutation, deletePinMutation } from "queries/queries"
import { SnackBarContext } from "contexts/SnackBarContext"
import { SNACK_BAR_TYPES } from "components/SnackBar/SnackBarTypes"

// mui
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { IconButton } from "@mui/material"

// Types
import { MODAL_TYPES } from "components/Modal/ModalTypes"
import { OrganizationInterface } from "interfaces/Organization"
import { PinInterface } from "interfaces/Pin"

// Contexts
import { ModalContext } from "contexts/ModalContext"

interface CategoryDropdownPropsInterface {
  categoryId: number
  categoryTitle: string
  categoryDescription: string
  folderId: number
  folderTitle: string
  organization?: OrganizationInterface
  pin?: PinInterface
}

export const CategoryDropdown = (props: CategoryDropdownPropsInterface) => {
  const { categoryId, categoryTitle, categoryDescription, folderId, folderTitle, organization, pin } = props
  const [open, setIsOpen] = React.useState<boolean>(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { setModalState } = useContext(ModalContext)
  const { setSnackBarState } = useContext(SnackBarContext)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleEditCategory = () => {
    handleClose()
    setModalState({
      isOpen: true,
      modalType: MODAL_TYPES.EDIT_COLLECTION,
      data: {
        id: categoryId,
        title: categoryTitle,
        description: categoryDescription,
        folderId: folderId,
        folderTitle: folderTitle,
        organization: organization,
      },
    })
  }

  const handleAddPin = () => {
    addPin({
      variables: {
        itemableId: categoryId,
        itemableType: "Category",
      },
    }).then(
      () => {},
      (res: any) => {
        if (res?.message) {
          setSnackBarState({
            isOpen: true,
            snackBarType: SNACK_BAR_TYPES.ERROR,
            message: res.message,
          })
        } else {
          setSnackBarState({
            isOpen: true,
            snackBarType: SNACK_BAR_TYPES.ERROR,
            message: "Failed to add favorite - something went wrong",
          })
        }
      }
    )
  }

  const handleRemovePin = () => {
    if (!pin?.id) {
      return
    }
    deletePin({
      variables: {
        id: pin.id,
      },
    }).then(
      () => {},
      (res: any) => {
        if (res?.message) {
          setSnackBarState({
            isOpen: true,
            snackBarType: SNACK_BAR_TYPES.ERROR,
            message: res.message,
          })
        } else {
          setSnackBarState({
            isOpen: true,
            snackBarType: SNACK_BAR_TYPES.ERROR,
            message: "Failed to add favorite - something went wrong",
          })
        }
      }
    )
  }

  const [addPin, addPinRef] = useMutation(createPinMutation, {
    update(cache, { data: { createPin } }) {
      cache.modify({
        id: `Category:${categoryId}`,
        fields: {
          pin() {
            const newPinRef = cache.writeFragment({
              data: createPin,
              fragment: gql`
                fragment NewPin on Category {
                  id
                }
              `,
            })
            return newPinRef
          },
        },
      })
    },
  })

  const [deletePin, deletePinRef] = useMutation(deletePinMutation, {
    update(cache, { data: { deletePin } }) {
      cache.evict({ id: cache.identify(deletePin) })
    },
  })

  const handleDeleteCategory = () => {
    handleClose()

    setModalState({
      isOpen: true,
      modalType: MODAL_TYPES.DELETE_COLLECTION,
      data: {
        id: categoryId,
        title: categoryTitle,
        description: categoryDescription,
        organization: organization,
      },
    })
  }

  const togglePinText = () => {
    if (pin) {
      return "Remove Pin"
    }
    return "Add Pin"
  }

  const togglePin = () => {
    handleClose()
    if (pin) {
      return handleRemovePin()
    }
    return handleAddPin()
  }

  return (
    <>
      <IconButton
        edge="end"
        id="basic-button"
        aria-haspopup="true"
        onClick={handleClick}
        aria-label={`edit category ${categoryTitle}`}
      >
        <MoreVertIcon sx={{ fontSize: 28 }} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleEditCategory}>Edit Category</MenuItem>
        <MenuItem onClick={togglePin}>{togglePinText()}</MenuItem>
        <MenuItem onClick={handleDeleteCategory}>Delete Category</MenuItem>
      </Menu>
    </>
  )
}
