import React from "react"

// Components
import { LoginBox } from "../components/LoginBox"
import { BottomLegalLinks } from "components/BottomLegalLinks"

export const LoginPage = () => {
  return (
    <>
      <LoginBox />
      <BottomLegalLinks />
    </>
  )
}
