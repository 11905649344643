import React from "react"

// Components
import { LinkSearch } from "components/LinkSearch"

export function LinkPage() {
  return (
    <>
      <LinkSearch />
    </>
  )
}
