import React, { memo } from "react"
import { FixedSizeList, ListChildComponentProps } from "react-window"
import Fuse from "fuse.js"

// Components
import { SearchRow } from "./SearchRow"
import Box from "@mui/material/Box"

// Utils
import { useWindowDimensions, useIsMobile } from "Utils/Utils"
import { SEARCH_ROW_MOBILE, SEARCH_ROW_DESKTOP } from "constants/Global"
import { LinkInterface } from "interfaces/Link"

interface LinkSearchListProps {
  filteredResults: Fuse.FuseResult<LinkInterface>[]
}

export const LinkSearchList = memo((props: LinkSearchListProps) => {
  const { filteredResults } = props
  const { height } = useWindowDimensions()
  const isMobile = useIsMobile()

  const itemSize = () => {
    if (isMobile) {
      return SEARCH_ROW_MOBILE
    } else {
      return SEARCH_ROW_DESKTOP
    }
  }

  return (
    <Box id="LinkSearchList">
      <FixedSizeList
        height={height - 165}
        width="100%"
        itemSize={itemSize()}
        itemCount={filteredResults.length}
        itemData={{ filteredResults }}
        overscanCount={0}
      >
        {(
          args: ListChildComponentProps<{
            filteredResults: Fuse.FuseResult<LinkInterface>[]
          }>
        ) => SearchRow(args)}
      </FixedSizeList>
    </Box>
  )
})
