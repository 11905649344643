// MUI
import IconButton from "@mui/material/IconButton"

// Icons
import StyleIcon from "@mui/icons-material/Style"

interface TagIconProps {
  toggleTagDropdown: () => void
}
export const TagIcon = (props: TagIconProps) => {
  const { toggleTagDropdown } = props

  return (
    <IconButton
      onClick={toggleTagDropdown}
      aria-label="show-tag-list"
      tabIndex={-1}
    >
      <StyleIcon />
    </IconButton>
  )
}
