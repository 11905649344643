import React from "react"
import { useState, useContext } from "react"
import { useDragOver } from "@minoru/react-dnd-treeview"
import { TypeIcon } from "./TypeIcon"
import styles from "./CustomNode.module.css"

// MUI
import ArrowRightIcon from "@mui/icons-material/ArrowRight"
import PushPinIcon from "@mui/icons-material/PushPin"
import Tooltip from "@mui/material/Tooltip"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import Box from "@mui/material/Box"

import { CategoryDropdown } from "./CategoryDropdown"
import { TagDropdown } from "./TagDropdown"
import { FolderDropdown } from "./FolderDropdown"
import { FOLDER, CATEGORY, TAG } from "constants/Global"

// Contexts
import { LightDarkModeContext } from "contexts/LightDarkModeContext"
import { SideDrawerContext } from "contexts/SideDrawerContext"

import { useIsMobile } from "Utils/Utils"

export const CustomNode = (props: any) => {
  const { id, droppable, data, isDisabled } = props.node
  const indent = props.depth * 24
  const { sideDrawerState } = useContext(SideDrawerContext)
  const { currentModeState } = useContext(LightDarkModeContext)
  const isDarkModeEnabled = currentModeState.palette?.mode === "dark"
  const [isVisible, setIsVisible] = useState(false)
  const isMobile = useIsMobile()

  const isFolderType = (dataId: string) => {
    let itemType = dataId.split("-")[0]
    if (itemType === FOLDER) {
      return true
    }
    return false
  }

  const isCategoryType = (dataId: string) => {
    let itemType = dataId.split("-")[0]
    if (itemType === CATEGORY) {
      return true
    }
    return false
  }

  const isTagType = (dataId: string) => {
    let itemType = dataId.split("-")[0]
    if (itemType === TAG) {
      return true
    }
    return false
  }

  const handleToggle = (e: any, data: any) => {
    e.stopPropagation()
    if (isFolderType(props.node.id)) {
      // Rotates arrow icon when user 'clicks' a folder
      props.onToggle(props.node.id)
    } else {
      // handles a user toggling a given category or tag
      data.toggleItem(data.contentId)
    }
  }

  const dragOverProps = useDragOver(id, props.isOpen, props.onToggle)

  const ariaLabel = () => {
    if (isTagType(props.node.id) || isCategoryType(props.node.id)) {
      return `Currently${data.isItemChecked(data.contentId) ? "-" : "-not-"}filtering-by-${data.contentTitle}. Click-to
        ${data.isItemChecked(data.contentId) ? "remove" : "add"}-${data.contentTitle}
        ${data.isItemChecked(data.contentId) ? "from" : "to"}-search-filters
        `
    } else if (isFolderType(props.node.id)) {
      return `${data.contentTitle} Folder is currently ${props.isOpen ? "open." : "closed."} Click to ${
        props.isOpen ? "close." : "open."
      }
      }`
    }
  }

  const ariaLabelId = () => {
    if (isCategoryType(props.node.id)) {
      return `category-dropdown-${data.contentId}`
    } else if (isTagType(props.node.id)) {
      return `tag-dropdown-${data.contentId}`
    }
  }

  const toggleVisibleOverride = () => {
    setIsVisible(!isVisible)
  }

  const visibleOverrideClass = () => {
    if (isVisible) {
      return styles.visibleOverride
    }
    return ""
  }

  const dropdownMenu = () => {
    if (isCategoryType(props.node.id)) {
      return (
        <Box
          className={`${styles.dropdownIconChild} ${visibleOverrideClass()}`}
          onClick={() => {
            toggleVisibleOverride()
          }}
        >
          <CategoryDropdown
            categoryId={data.contentId}
            categoryTitle={data.contentTitle}
            categoryDescription={data.contentDescription}
            folderId={data.folderId}
            folderTitle={data.folderTitle}
            organization={data.organization}
            pin={data.pin}
          />
        </Box>
      )
    } else if (isFolderType(props.node.id)) {
      return (
        <Box
          className={`${styles.dropdownIconChild} ${visibleOverrideClass()}`}
          onClick={() => {
            toggleVisibleOverride()
          }}
        >
          <FolderDropdown
            folderId={data.contentId}
            folderTitle={data.contentTitle}
            folderDescription={data.contentDescription || ""}
            contentType={data.contentType}
            parentFolderId={data.parentFolderId}
            parentFolderTitle={data.parentFolderTitle}
            organization={data.organization}
          />
        </Box>
      )
    } else if (isTagType(props.node.id)) {
      return (
        <Box
          className={`${styles.dropdownIconChild} ${visibleOverrideClass()}`}
          onClick={() => {
            toggleVisibleOverride()
          }}
        >
          <TagDropdown
            tagId={data.contentId}
            tagTitle={data.contentTitle}
            tagDescription={data.contentDescription}
            folderId={data.folderId}
            folderTitle={data.folderTitle}
            organization={data.organization}
            pin={data.pin}
          />
        </Box>
      )
    }
  }

  return (
    <ListItem
      secondaryAction={dropdownMenu()}
      component="span"
      disablePadding
      dense={!data.isNotMobile}
      className={`${styles.dropdownIconParent}`}
    >
      <Tooltip
        enterDelay={500}
        enterNextDelay={500}
        leaveDelay={0}
        followCursor
        key={props.node.id}
        title={data.contentDescription || ""}
      >
        <ListItemButton
          selected={!props.node.droppable && data.isItemChecked(data.contentId)}
          id={ariaLabelId()}
          aria-label={ariaLabel()}
          onClick={(e: any) => {
            handleToggle(e, data)
          }}
          disabled={props.isDisabled && !props.node.droppable} // do not disable folders
          style={{ paddingInlineStart: indent }}
          {...dragOverProps}
        >
          {/* ------------------------------ left Icon ------------------------------ */}
          <div className={`${styles.expandIconWrapper} ${props.isOpen && props.node.droppable ? styles.isOpen : ""}`}>
            {props.node.droppable && (
              <div>
                <ArrowRightIcon />
              </div>
            )}
          </div>

          {/* ------------------------------ Folder Icon ------------------------------ */}

          <TypeIcon droppable={droppable} contentType={data?.contentType} />

          {/* ------------------------------ Row Text ------------------------------ */}

          {data.pin && <PushPinIcon color="success" sx={{ fontSize: "16px" }} />}

          <ListItemText
            // aria-labelledby={ariaLabelId(category)}
            primaryTypographyProps={{
              maxWidth: sideDrawerState.width,
              flexGrow: 1,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              flexBasis: "100%",
              paddingLeft: "6px",
              // "aria-labelledby": ariaLabelId(category),
            }}
            primary={props.node.text}
          />
        </ListItemButton>
      </Tooltip>
    </ListItem>
  )
}
