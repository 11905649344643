import { useContext } from "react"
import { useQuery } from "@apollo/client"

// mui
import Typography from "@mui/material/Typography"
import Link from "@mui/material/Link"
import Card from "@mui/material/Card"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"

import { getOrganizationsQuery } from "queries/queries"

import { ModalContext } from "contexts/ModalContext"
import { MODAL_TYPES } from "components/Modal/ModalTypes"

export function CreateOrganization() {
  const { setModalState } = useContext(ModalContext)
  const { loading, data } = useQuery(getOrganizationsQuery)

  const openCreateOrganizationModal = () => {
    setModalState({
      isOpen: true,
      modalType: MODAL_TYPES.CREATE_ORGANIZATION,
    })
  }

  const renderCreateOrgCard = () => {
    if (loading || data?.organizations?.length) {
      return ""
    }
    return (
      <Card
        component="div"
        sx={{
          mx: 2,
          my: 2,
          p: 2,
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "700px",
        }}
      >
        <Typography variant="h4" sx={{ mt: 2, mb: 1, textAlign: "center" }}>
          Create An Organization!
        </Typography>
        <Typography component="div">
          <List sx={{ listStyleType: "disc", pl: 4 }}>
            <ListItem sx={{ display: "list-item" }}>
              Share links amongst your team or friends.
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Manage who has read / write access to your shared links.
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Use all of the existing category and tag functionality of Linkidex
            </ListItem>
          </List>
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "center", pb: 1 }}>
          <Button
            sx={{ margin: "auto", textAlign: "center" }}
            onClick={openCreateOrganizationModal}
            aria-label="Resend Email Verification"
            variant="contained"
            color="success"
          >
            Create Org
          </Button>
        </Box>
      </Card>
    )
  }

  return <>{renderCreateOrgCard()}</>
}
