import React, { useContext, useCallback, useEffect } from "react"
import { useQuery } from "@apollo/client"
import { getActiveUserQuery } from "queries/queries"
import { useLocation } from "react-router-dom"

// MUI
import SwipeableDrawer from "@mui/material/SwipeableDrawer"
import Divider from "@mui/material/Divider"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Link from "@mui/material/Link"

// Utils
import { useIsMobile } from "Utils/Utils"
import { DEFAULT_DRAWER_WIDTH } from "constants/Global"

// Contexts
import { SideDrawerContext } from "contexts/SideDrawerContext"
import { SelectedCategoriesContext } from "contexts/SelectedCategoriesContext"
import { SelectedTagsContext } from "contexts/SelectedTagsContext"
import { SelectedOrganizationsContext } from "contexts/SelectedOrganizationsContext"
import { PersonalLinksSelectedContext } from "contexts/PersonalLinksSelectedContext"
import { FavoritesSelectedContext } from "contexts/FavoritesSelectedContext"

// Constants
import { DRAWER_MIN_WIDTH } from "constants/Global"
import { DRAWER_MAX_WIDTH } from "constants/Global"

// Components
import { SideDrawerHeader } from "./SideDrawerHeader"
import { SideDrawerFooter } from "./SideDrawerFooter"
import { UserOrganizations } from "./UserOrganizations"
import { PersonalLinksCard } from "./PersonalLinksCard"
import { FiltersCard } from "./FiltersCard"

export function SideDrawer() {
  const isNotMobile = !useIsMobile()
  const location = useLocation()

  const { sideDrawerState, setSideDrawerState } = useContext(SideDrawerContext)
  const { selectedCategoriesState, setSelectedCategoriesState } = useContext(SelectedCategoriesContext)
  const { selectedOrganizationsState, setSelectedOrganizationsState } = useContext(SelectedOrganizationsContext)
  const { personalLinksSelectedState, setPersonalLinksSelectedState } = useContext(PersonalLinksSelectedContext)
  const { setFavoritesSelectedState } = useContext(FavoritesSelectedContext)
  const { selectedTagsState, setSelectedTagsState } = useContext(SelectedTagsContext)
  const { data } = useQuery(getActiveUserQuery)

  // -------------------- Resize Drawer Helpers --------------------

  const handleMouseDown = (e: any) => {
    e.preventDefault() // prevents mouse drag from selecting text
    document.addEventListener("mouseup", handleMouseUp, true)
    document.addEventListener("mousemove", handleMouseMove, true)
  }

  const handleMouseUp = () => {
    document.removeEventListener("mouseup", handleMouseUp, true)
    document.removeEventListener("mousemove", handleMouseMove, true)
  }

  const handleMouseMove = useCallback(
    (e) => {
      const newWidth = e.clientX - document.body.offsetLeft
      if (newWidth > DRAWER_MIN_WIDTH && newWidth < DRAWER_MAX_WIDTH) {
        setSideDrawerState({ ...sideDrawerState, width: newWidth })
      }
    },
    [setSideDrawerState, sideDrawerState]
  )

  // -------------------- Manipulate Selected Categories Helpers  --------------------
  const addToSelectedCategories = (categoryId: number) => {
    let toAdd: any = {}
    toAdd[categoryId] = true
    setSelectedCategoriesState({ ...selectedCategoriesState, ...toAdd })
  }
  const removeFromSelectedCategories = (categoryId: number) => {
    let toRemove: any = { ...selectedCategoriesState }
    toRemove[categoryId] = false
    setSelectedCategoriesState(toRemove)
  }

  const clearSelectedCategories = () => {
    setSelectedCategoriesState({})
  }

  const isCategoryChecked = (categoryId: number): boolean | undefined => {
    return !!selectedCategoriesState[categoryId]
  }

  const toggleCategory = (categoryId: number) => {
    if (isCategoryChecked(categoryId)) {
      removeFromSelectedCategories(categoryId)
    } else {
      addToSelectedCategories(categoryId)
    }
  }

  const countOfSelectedCategories = () => {
    let countOfCategories = 0
    Object.values(selectedCategoriesState).map((value) => {
      if (value === true) {
        countOfCategories += 1
      }
      return null
    })
    return countOfCategories
  }

  // -------------------- End Manipulate Selected Categories Helpers --------------------
  // ------------------------- Manipulate Selected Tags Helpers  -------------------------

  const addToSelectedTags = (tagId: number) => {
    let toAdd: any = {}
    toAdd[tagId] = true
    setSelectedTagsState({ ...selectedTagsState, ...toAdd })
  }

  const removeFromSelectedTags = (tagId: number) => {
    let toRemove: any = { ...selectedTagsState }
    toRemove[tagId] = false
    setSelectedTagsState(toRemove)
  }

  const clearSelectedTags = () => {
    setSelectedTagsState({})
  }

  const isTagChecked = (tagId: number): boolean | undefined => {
    return !!selectedTagsState[tagId]
  }

  const toggleTag = (tagId: number) => {
    if (isTagChecked(tagId)) {
      removeFromSelectedTags(tagId)
    } else {
      addToSelectedTags(tagId)
    }
  }

  const countOfSelectedTags = () => {
    let countOfTags = 0
    Object.values(selectedTagsState).map((value) => {
      if (value === true) {
        countOfTags += 1
      }
      return null
    })
    return countOfTags
  }

  // -------------------- End Manipulate Selected Tags Helpers --------------------
  // -------------------- Manipulate Selected organizations Helpers  --------------------
  const addToSelectedOrganizations = (organizationId: string) => {
    let toAdd: any = {}
    toAdd[organizationId] = true
    setSelectedOrganizationsState({ ...selectedOrganizationsState, ...toAdd })
  }

  const removeFromSelectedOrganizations = (organizationId: string) => {
    let toRemove: any = { ...selectedOrganizationsState }
    toRemove[organizationId] = false
    setSelectedOrganizationsState(toRemove)
  }

  const clearSelectedOrganizations = () => {
    setSelectedOrganizationsState({})
  }

  const clearPersonalLinksSelectedState = () => {
    setPersonalLinksSelectedState(false)
  }

  const clearFavoriteLinksSelectedState = () => {
    setFavoritesSelectedState(false)
  }

  const isOrganizationChecked = (organizationId: string): boolean | undefined => {
    return !!selectedOrganizationsState[organizationId]
  }

  const toggleOrganization = (organizationId: string) => {
    if (isOrganizationChecked(organizationId)) {
      removeFromSelectedOrganizations(organizationId)
    } else {
      addToSelectedOrganizations(organizationId)
    }
  }

  const clearAllFilters = () => {
    clearSelectedCategories()
    clearSelectedTags()
    clearSelectedOrganizations()
    clearPersonalLinksSelectedState()
    clearFavoriteLinksSelectedState()
  }

  const countOfSelectedOrganizations = () => {
    let countOfOrganizations = 0
    Object.values(selectedOrganizationsState).map((value) => {
      if (value === true) {
        countOfOrganizations += 1
      }
      return null
    })
    return countOfOrganizations
  }

  // -------------------- End Manipulate Selected organizations Helpers --------------------

  const closeSideDrawer = () => {
    setSideDrawerState({ ...sideDrawerState, isOpen: false })
  }

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (isNotMobile && event) {
      // swiping the drawer does not trigger an event.
      // clicking the close button is handled by context
      // on desktop we want to allow users to manipulate
      // the app outside of the drawer while the drawer is open
      return null
    }
    setSideDrawerState({ ...sideDrawerState, isOpen: open })
  }

  const isUserOnLinksPage = () => {
    if (location.pathname === "/links") {
      return true
    }
    return false
  }

  const determineVariant = () => {
    if (isNotMobile) {
      // persistent drawers allow things outside of the drawer be manipulated
      return "persistent"
    }
    // temporary drawers can be swiped away
    return "temporary"
  }

  useEffect(() => {
    // resize Drawer to mobile friendly size if screen gets resized to mobile
    if (!isNotMobile) {
      setSideDrawerState({ ...sideDrawerState, width: DEFAULT_DRAWER_WIDTH })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNotMobile])

  const ResizeDrawerDragger = () => {
    if (isNotMobile) {
      return (
        <Box
          onMouseDown={(e) => handleMouseDown(e)}
          className="SideDrawerDragger"
          sx={{
            position: "fixed",
            display: "inline",
            float: "left",
            width: "7px",
            height: "99.9vh",
            cursor: "ew-resize",
            padding: 0,
            borderTop: "1px solid #ddd",
            top: 0,
            left: sideDrawerState.width,
            bottom: 0,
            zIndex: 100,
            backgroundColor: "divider",
          }}
        />
      )
    }
  }

  const togglePersonalLinks = () => {
    setPersonalLinksSelectedState(!personalLinksSelectedState)
  }

  const isPersonalLinksChecked = () => {
    return personalLinksSelectedState
  }

  return (
    <>
      <SwipeableDrawer
        sx={{
          width: sideDrawerState.width,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: sideDrawerState.width,
            boxSizing: "border-box",
          },
        }}
        anchor="left"
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        open={sideDrawerState.isOpen}
        hideBackdrop={isNotMobile}
        variant={determineVariant()}
        PaperProps={{
          variant: "elevation",
          square: true,
          sx: { borderRight: "1px solid", borderColor: "divider" },
        }}
        elevation={0}
      >
        <Box
          className="SideDrawerDraggerContainer"
          sx={{
            position: "relative ",
            height: "auto",
          }}
        >
          {ResizeDrawerDragger()}

          <SideDrawerHeader closeSideDrawer={closeSideDrawer} />
          <Divider />

          {/* ------------------------ DnD Trees Personal ------------------------ */}
          <Box sx={{ display: isUserOnLinksPage() ? "block" : "block" }}>
            <span id="dnd-trees-personal">
              <FiltersCard isNotMobile clearAllFilters={clearAllFilters} />
              <Box sx={{ my: 5 }}></Box>

              <PersonalLinksCard
                isNotMobile={isNotMobile}
                togglePersonalLinks={togglePersonalLinks}
                isPersonalLinksChecked={isPersonalLinksChecked()}
                isUserOnLinksPage={isUserOnLinksPage}
                clearSelectedTags={clearSelectedTags}
                clearSelectedCategories={clearSelectedCategories}
                countOfSelectedCategories={countOfSelectedCategories}
                countOfSelectedTags={countOfSelectedTags}
                toggleTag={toggleTag}
                toggleCategory={toggleCategory}
                isCategoryChecked={isCategoryChecked}
                isTagChecked={isTagChecked}
              />
            </span>
          </Box>
          {/* ------------------------ DnD Trees Orgs ------------------------ */}
          <Box sx={{ my: 5 }}></Box>

          <Box sx={{ display: isUserOnLinksPage() ? "block" : "block" }}>
            <UserOrganizations
              isNotMobile={isNotMobile}
              toggleOrganization={toggleOrganization}
              isOrganizationChecked={isOrganizationChecked}
              isUserOnLinksPage={isUserOnLinksPage}
              clearSelectedTags={clearSelectedTags}
              clearSelectedCategories={clearSelectedCategories}
              countOfSelectedCategories={countOfSelectedCategories}
              countOfSelectedTags={countOfSelectedTags}
              toggleTag={toggleTag}
              toggleCategory={toggleCategory}
              isCategoryChecked={isCategoryChecked}
              isTagChecked={isTagChecked}
            />
          </Box>
          {/* ------------------------ Side Drawer Beta Blurb ------------------------ */}
          <Divider sx={{ mx: 4 }} />

          {/* ------------------------ Side Drawer Footer ------------------------ */}

          <SideDrawerFooter />
        </Box>
      </SwipeableDrawer>
    </>
  )
}
