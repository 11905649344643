import React from "react"

// Components
import { ForgotPasswordForm } from "../components/ForgotPasswordForm"

export const ForgotPasswordPage = () => {
  return (
    <>
      <ForgotPasswordForm />
    </>
  )
}
