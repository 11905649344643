import React, { useContext, useState, useRef } from "react"
import { useNavigate } from "react-router-dom"
import * as WebAuthnJSON from "@github/webauthn-json"
import { useApolloClient } from "@apollo/client"

// mui
import Typography from "@mui/material/Typography"
import FormControl from "@mui/material/FormControl"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Link from "@mui/material/Link"
import Alert from "@mui/material/Alert"

// Contexts
import { ModalContext } from "contexts/ModalContext"
import { CurrentUserContext } from "contexts/CurrentUserContext"

// Utils
import { ApiUtils } from "../../Utils/ApiUtils"
import { debounceFunction, supportsWebauthn } from "Utils/Utils"
import { getActiveUserQuery } from "queries/queries"

// Types
import { DEBOUNCE_TIME } from "constants/Global"
import { MODAL_TYPES } from "components/Modal/ModalTypes"

export const WebauthnFactorModal = () => {
  const timeout = useRef<any>()
  const { modalState, setModalState } = useContext(ModalContext)
  const { updateCurrentUserState } = useContext(CurrentUserContext)
  const navigate = useNavigate()
  const client = useApolloClient()

  const [inputErrorText, setInputErrorText] = useState("")

  const closeModal = () => {
    setModalState({ isOpen: false, modalType: "" })
  }

  const openLostAuthenticatorModal = () => {
    setModalState({
      isOpen: true,
      modalType: MODAL_TYPES.LOST_AUTHENTICATOR,
      data: { login_stage_two_code: modalState.data.login_stage_two_code },
    })
  }

  const openTwoFactorModal = () => {
    setModalState({
      isOpen: true,
      modalType: MODAL_TYPES.SECOND_FACTOR,
      data: {
        login_stage_two_code: modalState.data.login_stage_two_code,
        remember_me: modalState.data.remember_me
      },
    })
  }

  const debouncedAuthenticateWithWebauthn = () => {
    debounceFunction(timeout, authenticateWithWebauthn, DEBOUNCE_TIME)
  }

  const authenticateWithWebauthn = () => {
    ApiUtils.authenticateChallengeWebauthnCredential({
      login_stage_two_code: modalState.data.login_stage_two_code,
    }).then(
      (res: any) => {
        WebAuthnJSON.get({ publicKey: res }).then(
          (credential: any) => {
            ApiUtils.authenticateWebauthnCredential({
              credential: credential,
              login_stage_two_code: modalState.data.login_stage_two_code,
              remember_me: modalState.data.remember_me,
            }).then(
              (res: any) => {
                closeModal()
                // eslint-disable-next-line no-restricted-globals
                let intended = history?.state?.usr?.from
                updateCurrentUserState(res)
                client.refetchQueries({
                  include: [getActiveUserQuery],
                })
                if (intended) {
                  navigate(intended)
                } else {
                  navigate("/links")
                }
              },
              (res: object) => {
                setInputErrorText("Validation failed! Please try again.")
              }
            )
          },

          (errorResponse: object) => {
            setInputErrorText("Validation failed! Please try again.")
          }
        )
      },
      (errorResponse: object) => {
        setInputErrorText("Validation failed! Please try again.")
      }
    )
  }

  return (
    <>
      <Typography textAlign="center" variant="h5" sx={{ my: 2 }}>
        Webauthn
      </Typography>
      {!supportsWebauthn() && (
        <Alert variant="outlined" severity="error" sx={{ my: 1 }}>
          You browser does not support Webauthn. Updating it may solve this
          issue.
        </Alert>
      )}
      <Typography textAlign="center" sx={{ my: 2 }}>
        Press 'Authenticate' and your device will prompt you if it supports
        Webauthn. You can also{" "}
        <Link onClick={openTwoFactorModal} sx={{ cursor: "pointer" }}>
          use Two-Factor
        </Link>
      </Typography>

      <Box sx={{ textAlign: "center" }}>
        <Button
          variant="text"
          color="secondary"
          onClick={openLostAuthenticatorModal}
          size="small"
        >
          I cant login
        </Button>
      </Box>
      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Button
              variant="outlined"
              color="warning"
              onClick={closeModal}
              sx={{ width: { xs: "100%", md: "auto" } }}
              aria-label={`Cancel edit tag`}
            >
              Cancel
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              mt: { xs: 2, md: 0 },
              display: "flex",
              flexDirection: { xs: "row", md: "row-reverse" },
            }}
          >
            <FormControl
              error={!!inputErrorText}
              sx={{ width: { xs: "100%", md: "auto" } }}
            >
              <Button
                variant="contained"
                onClick={debouncedAuthenticateWithWebauthn}
                disabled={false}
                aria-label={`Save changes to tag`}
              >
                Authenticate
              </Button>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
