// mui
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import FormControl from "@mui/material/FormControl"
import Divider from "@mui/material/Divider"

interface ShowBackupCodesPropsInterface {
  closeModal: () => void
  debouncedHandleResetCodes: () => void
  userBackupCodes: string[]
}

export const ShowBackupCodes = (props: ShowBackupCodesPropsInterface) => {
  const { closeModal, debouncedHandleResetCodes, userBackupCodes } = props

  const renderBackupCodes = () => {
    return userBackupCodes.map((code, idx) => (
      <Box key={idx}>
        <Divider sx={{ my: 1 }} />

        <Typography sx={{ textAlign: "center", wordBreak: "break-all" }}>
          {code}
        </Typography>
      </Box>
    ))
  }

  return (
    <>
      <Typography sx={{ my: 1 }}>
        These one time use codes can be used to access your account if you lose
        access to your two-factor device. Store them somewhere safe (we
        recommend a password manager) and never share them with anyone.
      </Typography>

      {renderBackupCodes()}

      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Button
              variant="outlined"
              color="warning"
              onClick={closeModal}
              sx={{ width: { xs: "100%", md: "auto" } }}
              aria-label={`Cancel`}
            >
              Close
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              mt: { xs: 2, md: 0 },
              display: "flex",
              flexDirection: { xs: "row", md: "row-reverse" },
            }}
          >
            <FormControl
              error={false}
              sx={{ width: { xs: "100%", md: "auto" } }}
            >
              <Button
                variant="contained"
                onClick={debouncedHandleResetCodes}
                disabled={false}
                color="error"
                aria-label={`remove two factor`}
              >
                Reset Codes
              </Button>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
