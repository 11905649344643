import React from "react"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Link from "@mui/material/Link"

export const DmcaPage = () => {
  return (
    <Box sx={{ mt: 1, mb: 0, pb: 4 }}>
      <Typography variant="h4" id="dmca">
        DMCA
      </Typography>
      <Typography>
        <em>Last Updated: Jan 22, 2023</em>
      </Typography>
      <Typography sx={{ mt: 2 }}>
        If you believe that material available on linkidex.com infringes on your
        copyright(s), please notify us by submitting a DMCA notice. After we
        receive a valid and complete notice, we will remove the material and
        make a good faith attempt to contact the user who uploaded the material
        by email.
      </Typography>
      <Typography sx={{ mt: 2 }}>
        Before submitting a DMCA notice, it’s important to consider if the
        manner in which the material is used falls under fair use. If you are
        not sure whether material located on Linkidex.com infringes your
        copyright, or if it is subject to fair use protections, you should first
        consider seeking legal advice.
      </Typography>
      <Typography sx={{ mt: 2 }}>
        You may be liable for damages (including costs and attorneys’ fees) if
        you materially misrepresent the material that infringes your copyright.
        We will highlight such abuses and seek to collect those damages. In
        addition, you are required to consider the possible fair use
        implications, as a result of Lenz v. Universal. We reserve the right to
        challenge abuses of the DMCA process.
      </Typography>
      <Typography>
        Your DMCA notice will be forwarded to the party that made the material
        available, and also may be sent to third parties such as
        LumenDatabase.org.
      </Typography>
      <Typography variant="h5" sx={{ mt: 2, mb: 2 }} id="1-who-s-who">
        Filing a Notice
      </Typography>
      <Typography sx={{ mt: 2 }}>
        Please follow these steps to file a notice:
      </Typography>
      <Typography component="span">
        <ol>
          <li>
            <Typography>
              Verify that the content in question is hosted by Linkidex. We have
              no control over 3rd party websites (websites other than
              linkidex.com)
            </Typography>
          </li>
          <li>
            If possible, contact the author of the content to see if the matter
            can be resolved directly between you and the individual sharing the
            content in question.
          </li>
          <li>
            Send your complaint to our designated agent via the contact info
            below if the issue cannot be resolved directly with the individual
            sharing the content in question.
          </li>
        </ol>
      </Typography>
      <Typography>
        As required by the DMCA, we have a policy to terminate users and/or
        organizations that we consider to be repeat infringers. Although we
        won’t share the specifics of our repeat infringer policy (we don't want
        anyone to game the system, after all), we believe that it strikes the
        right balance: it protects the rights of copyright owners and protects
        legitimate users from wrongful termination. Please note that notices
        that are successfully countered, rejected on fair use grounds, or deemed
        to be fraudulent are not counted against a user or site.
      </Typography>
      <Typography variant="h5" sx={{ mt: 2, mb: 2 }} id="send-dmca">
        Send DMCA complaints
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        {" "}
        Send your complaint to our designated agent
        (https://dmca.copyright.gov/osp/publish/history.html?id=d0d861f3582b391925666b729c3a168f)
        via email, or snail mail. (We prefer email)
      </Typography>
      <Typography>dmca@linkidex.com</Typography>
      <Typography>Copyright Compliance Department Linkidex Inc.</Typography>
      <Typography>1968 S. Coast Hwy #3211</Typography>
      <Typography>Laguna Beach, CA 92651</Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        You must include the following:
      </Typography>
      <ul>
        <li>
          <Typography>
            A physical or electronic signature of the copyright owner or a
            person authorized to act on their behalf.
          </Typography>
        </li>
        <li>
          <Typography>
            An identification of the copyrighted work claimed to have been
            infringed.
          </Typography>
        </li>
        <li>
          <Typography>
            A description of the nature and exact location of the material that
            you claim to infringe your copyright, in sufficient detail to permit
            Linkidex to find and positively identify that material. For example
            we require a link or URL of the content that contains the material
            and a description of which specific content - an image, a link, the
            text, etc. your complaint refers to.
          </Typography>
        </li>
        <li>
          <Typography>
            Your name, address, telephone number and email address
          </Typography>
        </li>
        <li>
          <Typography>
            A statement that you have a good faith belief that use of the
            material in the manner complained of is not authorized by the
            copyright owner, its agent, or the law
          </Typography>
        </li>
        <li>
          <Typography>
            A statement that the information in the notification is accurate,
            and under penalty of perjury, that you are authorized to act on
            behalf of the owner of an exclusive right that is allegedly
            infringed.
          </Typography>
        </li>
      </ul>
      <br />
      <Typography variant="caption">
        Thank you to the team at{" "}
        <Link href="https://auttomatic.com/about/">Auttomatic</Link> for making
        their Terms of Service available under a{" "}
        <Link href="https://creativecommons.org/licenses/by-sa/4.0/">
          Creative Commons Sharealike
        </Link>{" "}
        License. You can grab a copy of their Terms of Service and other legal
        documents on{" "}
        <Link
          href="https://github.com/Automattic/legalmattic/"
          target="_blank"
          rel="noreferrer"
        >
          GitHub
        </Link>
        .
      </Typography>
    </Box>
  )
}
