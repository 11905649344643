import { useState } from "react"
import styles from "./CustomNode.module.css"

// MUI
import ListItemButton from "@mui/material/ListItemButton"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import IconButton from "@mui/material/IconButton"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip"
import { styled, useTheme } from "@mui/material/styles"
import Divider from "@mui/material/Divider"

// Components
import { LinkDropdown } from "./LinkDropdown"
import { ToggleFavorite } from "./ToggleFavorite"

// Types
import { SEARCH_ROW_DESKTOP } from "constants/Global"
import { LinkInterface } from "interfaces/Link"

interface SearchRowDesktopPropsInterface {
  style: React.CSSProperties
  index: number
  // link: LinkInterface
  linkId: LinkInterface["id"]
  linkTitle: LinkInterface["title"]
  linkCategory: LinkInterface["category"]
  linkTags: LinkInterface["tags"]
  linkDescription: LinkInterface["description"]
  linkUserLinkFavorites: LinkInterface["userLinkFavorites"]
  copyLink: () => void
  openLink: (event: any) => void
  editLink: () => void
  deleteLink: () => void
}

export const SearchRowDesktop = (props: SearchRowDesktopPropsInterface) => {
  const {
    style,
    index,
    linkId,
    linkTitle,
    linkCategory,
    linkTags,
    linkDescription,
    linkUserLinkFavorites,
    copyLink,
    openLink,
    editLink,
    deleteLink,
  } = props

  const theme = useTheme()
  const [isVisible, setIsVisible] = useState(false)

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      maxWidth: 240,
      minWidth: 240,
      border: "1px solid #dadde9",
    },
  }))

  const buildTagText = () => {
    // add commas between tag titles if necessary
    if (!linkTags) {
      return null
    }
    const tagCount = linkTags.length
    if (linkTags && tagCount === 1) {
      return linkTags[0].title
    } else if (linkTags.length) {
      return linkTags.map((tag, idx) => {
        if (idx < tagCount - 1) {
          return `${tag.title}, `
        }
        return tag.title
      })
    }
    return null
  }

  const visibleOverrideClass = () => {
    if (isVisible) {
      return styles.visibleOverride
    }
    return ""
  }

  return (
    <>
      <ListItem
        disablePadding
        className={styles.searchResultParent}
        style={style}
        key={index}
        sx={{
          display: "flex",
          borderBottom: 1,
          borderColor: theme.palette.divider,
        }}
        secondaryAction={
          <Box className={`${styles.searchResultChild} ${visibleOverrideClass()}`}>
            <LinkDropdown
              linkTitle={linkTitle}
              editLink={editLink}
              deleteLink={deleteLink}
              copyLink={copyLink}
              setIsVisible={setIsVisible}
            />
          </Box>
        }
      >
        <ToggleFavorite linkId={linkId} linkTitle={linkTitle} userLinkFavorites={linkUserLinkFavorites} />

        {/* ------------------------ List Item Button ------------------------ */}

        <ListItemButton
          disableRipple
          onClick={openLink}
          sx={{
            py: 0,
            px: 0,
            mx: 0,
            display: "flex",
            minWidth: "0px",
            minHeight: `${SEARCH_ROW_DESKTOP}px`, // same as FixSizedList's `itemSize`
          }}
        >
          {/* ------------------------ Title ------------------------ */}
          <HtmlTooltip
            theme={theme}
            enterDelay={500}
            enterNextDelay={500}
            leaveDelay={0}
            followCursor
            title={
              <>
                <Typography color="inherit">{linkTitle}</Typography>
                <em>
                  <b>{linkCategory?.title}</b>
                </em>
                <Divider sx={{ mt: 1 }}>Description</Divider>
                {linkDescription || "No Description"}
                <Divider sx={{ mt: 1 }}>Tags</Divider>
                {buildTagText() || "No Tags"}
              </>
            }
          >
            <Typography
              aria-label={`Link Title: ${linkTitle} `}
              sx={{
                my: 0,
                py: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                flexBasis: "100%",
              }}
              component="div"
            >
              {linkTitle}
            </Typography>
          </HtmlTooltip>

          {/* ------------------------ Category ------------------------ */}
          <Typography
            aria-label={`Link Category: ${linkCategory?.title || "None"}`}
            variant="subtitle2"
            component="div"
            sx={{
              mr: 1,
              flexGrow: 1,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              flexBasis: "100%",
            }}
          >
            {linkCategory?.title}
          </Typography>

          {/* ------------------------ Description ------------------------ */}
          <Typography
            aria-label={`Link Description: ${linkDescription || "None"}`}
            variant="subtitle2"
            component="div"
            sx={{
              mr: 1,
              flexGrow: 1,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              flexBasis: "100%",
            }}
          >
            {linkDescription}
          </Typography>

          {/* ------------------------ Tags ------------------------ */}
          <Typography
            aria-label={`Link Tags: ${buildTagText() || "None"}`}
            variant="subtitle2"
            component="div"
            color="text.secondary"
            sx={{
              mr: 1,
              flexGrow: 1,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              flexBasis: "100%",
            }}
          >
            {buildTagText()}
          </Typography>
        </ListItemButton>
      </ListItem>
    </>
  )
}
