import React from "react"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"

import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import MenuIcon from "@mui/icons-material/Menu"
import Container from "@mui/material/Container"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"

// Components
import { NavbarDropdown } from "./NavbarDropdown"

// Contexts
import { CurrentUserContext } from "contexts/CurrentUserContext"
import { ModalContext } from "contexts/ModalContext"
import { SideDrawerContext } from "contexts/SideDrawerContext"

// Types
import { MODAL_TYPES } from "components/Modal/ModalTypes"

export const Navbar = () => {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  )
  const navigate = useNavigate()
  const { setModalState } = useContext(ModalContext)
  const { sideDrawerState, setSideDrawerState } = useContext(SideDrawerContext)

  const { currentUserState } = useContext(CurrentUserContext)

  const handleOpenSideDrawer = (event: React.MouseEvent<HTMLElement>) => {
    setSideDrawerState({ ...sideDrawerState, isOpen: true })
  }
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleAddLink = () => {
    setModalState({ isOpen: true, modalType: MODAL_TYPES.CREATE_LINK })
  }

  const shouldDisplayLinkidexOnLeftWhenMd = () => {
    // if logged in, do not display linkidex on left on medium or larger screens (as we are displaying links to blog and about in the middle instead)
    if (currentUserState.email) {
      return "none"
    }
    return "flex"
  }

  return (
    <AppBar position="static">
      <Container maxWidth={false} sx={{ pl: 0.5, pr: 0.5 }}>
        <Toolbar disableGutters sx={{ display: "flex" }}>
          {/* ------------------ hamburger menu ------------------ */}
          {currentUserState.email && (
            <Box sx={{ display: "flex" }}>
              <IconButton
                id="search-filters-and-return-to-search"
                size="large"
                aria-label="search-filters-and-return-to-search"
                aria-controls="search-filters-drawer"
                aria-haspopup="true"
                onClick={handleOpenSideDrawer}
                color="inherit"
                sx={{ ml: 0 }}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          )}
          {/* ------------ 'Linkidex' On Navbar Mobile------------*/}
          <Typography
            variant="h5"
            noWrap
            component="div"
            sx={{
              mr: 0.5,
              display: {
                xs: "flex",
                sm: "flex",
                md: `${shouldDisplayLinkidexOnLeftWhenMd()}`,
              },
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/")
            }}
          >
            Linkidex
          </Typography>
          <Box sx={{ flexGrow: 1 }}></Box>

          {/* ------------------------ Linkidex on Navbar Desktop ------------------------ */}
          {/* <Box
            sx={{
              width: "40px",
              display: { xs: "none", md: "block" },
            }}
          ></Box> */}
          {!currentUserState.email && (
            <>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{
                  display: {
                    xs: "none",
                    sm: "flex",
                  },
                  cursor: "pointer",
                  mx: 2,
                }}
                onClick={() => {
                  window.open("/blog/", "_blank")
                }}
              >
                Blog
              </Typography>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{
                  display: {
                    xs: "none",
                    sm: "flex",
                  },
                  cursor: "pointer",
                  mx: 2,
                }}
                onClick={() => {
                  navigate("/pricing")
                }}
              >
                Pricing
              </Typography>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{
                  display: {
                    xs: "none",
                    sm: "flex",
                  },
                  cursor: "pointer",
                  mx: 2,
                }}
                onClick={() => {
                  window.open("/blog/categories/release-notes/", "_blank")
                }}
              >
                Updates
              </Typography>
            </>
          )}

          {currentUserState.email && (
            <Typography
              variant="h5"
              noWrap
              component="div"
              sx={{
                display: {
                  xs: "none",
                  md: "flex",
                },
                cursor: "pointer",
                ml: 12,
              }}
              onClick={() => {
                navigate("/")
              }}
            >
              Linkidex
            </Typography>
          )}

          <Box sx={{ flexGrow: 1, backgroundColor: "red" }}></Box>

          {/* ------------ Add Link Button Desktop ------------ */}
          {currentUserState.email && (
            <Button
              id="nav-add-link-button"
              key={`add-link-button-desktop`}
              onClick={handleAddLink}
              variant="contained"
              color="success"
              sx={{
                display: { xs: "none", sm: "block" },
                my: 2,
                mr: 1,
              }}
            >
              New Link
            </Button>
          )}

          {/* ------------ Add Link Button Mobile ------------ */}
          {currentUserState.email && (
            <Button
              id="nav-add-link-button"
              size="small"
              key={`add-link-button-mobile`}
              onClick={handleAddLink}
              variant="contained"
              color="success"
              sx={{
                display: { xs: "block", sm: "none" },
                my: 2,
                mr: 1,
              }}
            >
              New Link
            </Button>
          )}
          {/* ------------ Login Button ------------ */}
          {!currentUserState.email && (
            <Button
              size="small"
              key={`navbar-login`}
              onClick={() => {
                navigate("/login")
              }}
              variant="contained"
              color="success"
              sx={{
                my: 2,
                mr: 1,
              }}
            >
              Login
            </Button>
          )}

          {/* ------------ Dropdown ------------ */}
          <NavbarDropdown
            handleOpenUserMenu={handleOpenUserMenu}
            handleCloseUserMenu={handleCloseUserMenu}
            anchorElUser={anchorElUser}
          />
        </Toolbar>
      </Container>
    </AppBar>
  )
}
