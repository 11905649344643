import * as React from "react"
import { useState, useEffect } from "react"

// MUI
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"

// Components
import {
  SelectFieldDropdown,
  DropDownOption,
} from "components/SelectFieldDropdown"
import { CreateFolderModal } from "components/Modal/CreateFolderModal"

// Types
import { CATEGORY, TAG } from "constants/Global"

interface SelectFolderFormInterface {
  dropdownOptions: readonly DropDownOption[]
  title: string
  selectedOption: null | DropDownOption
  handleSelect: (event: any, value: any) => void
  folderType: typeof CATEGORY | typeof TAG
}

export const SelectFolderForm = (props: SelectFolderFormInterface) => {
  const { dropdownOptions, selectedOption, handleSelect, title, folderType } =
    props
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [newFolder, setNewFolder] = useState<string | null>(null)

  const createFolder = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const createFolderCallBack = (newFolderResponse: any) => {
    // save ID of folder that was created while 'select folder' was open
    setNewFolder(newFolderResponse.id)
  }

  useEffect(() => {
    // if newFolder exists, select our new folder from dropdown options
    // once dropdownOptions has been asynchronously updated
    if (newFolder) {
      const dropdownMatchIndex = dropdownOptions.findIndex((dropdownOption) => {
        return dropdownOption.value === newFolder
      })

      if (dropdownMatchIndex > 0) {
        handleSelect(null, dropdownOptions[dropdownMatchIndex])
        setNewFolder(null)
      }
    }
  }, [dropdownOptions, handleSelect, newFolder])

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6}>
          <SelectFieldDropdown
            styles={{mt: 2, width: "100%" }}
            dropdownOptions={dropdownOptions}
            title={title}
            selectedOption={selectedOption}
            handleSelect={handleSelect}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
            <Button sx={{ mt: 3 }} variant="outlined" onClick={createFolder}>
              New Folder
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth={true}>
        <DialogContent>
          <CreateFolderModal
            callBack={createFolderCallBack}
            isControlledByContext={false}
            handleClose={handleCloseModal}
            folderType={folderType}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}
