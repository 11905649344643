import { useParams } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { Navigate } from "react-router-dom"
import Typography from "@mui/material/Typography"

// components
import { OrganizationMemberList } from "components/OrganizationMemberList"
import { OrganizationResourceUse } from "components/OrganizationResourceUse"

// queries
import { getOrganizationWithResourceCountsQuery } from "queries/queries"

export function ManageOrganizationPage() {
  const { organization_id } = useParams()

  const { loading, data } = useQuery(getOrganizationWithResourceCountsQuery, {
    fetchPolicy: "network-only",
    variables: {
      id: organization_id,
    },
  })

  const organizationTitle = () => (
    <Typography variant="h3" sx={{ textAlign: "center", mt: 2 }}>
      {data?.organization?.name ? data?.organization?.name : "Organization"}
    </Typography>
  )

  const renderPage = () => {
    if (loading) {
      // wait if we are still fetching org
      return <></>
    } else if (data) {
      // display org if we fetched it
      return (
        <>
          {organizationTitle()}
          <OrganizationResourceUse
            linkCount={data.organization.linkCount}
            categoryCount={data.organization.categoryCount}
            tagCount={data.organization.tagCount}
            folderCount={data.organization.folderCount}
            hasActiveSubscription={data.organization.hasActiveSubscription}
            maxLinkCount={data.organization.maxLinkCount}
            maxCategoryCount={data.organization.maxCategoryCount}
            maxTagCount={data.organization.maxTagCount}
            maxFolderCount={data.organization.maxFolderCount}
          />
          <OrganizationMemberList />
        </>
      )
    } else {
      // redirect user to their org list
      return <Navigate to="/organizations" replace />
    }
  }

  return <>{renderPage()}</>
}
