import React from "react"
import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import "./index.css"
import AppThemeWrapper from "./AppThemeWrapper"
import { CURRENT_VERSION_KEY } from "constants/Global"
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client"

import { onError } from "@apollo/client/link/error"

// Can also log any GraphQL errors or network error that occurred
const errorLink = onError(({ operation, forward }) => {
  localStorage.setItem(
    CURRENT_VERSION_KEY,
    operation.getContext().response.headers.get("linkidex-fe-v")
  )
  const event = new Event("itemInserted")
  document.dispatchEvent(event)
})

const httpLink = createHttpLink({
  uri: "/graphql",
  credentials: "include",
})

const clientVersionAfterware = new ApolloLink((operation, forward) =>
  forward(operation).map((response: any) => {
    const {
      response: { headers },
    } = operation.getContext()
    // note you MUST explicitly get the header by name
    localStorage.setItem(CURRENT_VERSION_KEY, headers.get("linkidex-fe-v"))
    const event = new Event("itemInserted")
    document.dispatchEvent(event)
    return response
  })
)

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          links: {
            // Don't cache separate results based on
            // any of this field's arguments.
            keyArgs: false,
            // Concatenate the incoming list items with
            // the existing list items.
            // @ts-ignore
            merge(existing, incoming, { args: { offset = 0 } }) {
              // Slicing is necessary because the existing data is
              // immutable, and frozen in development.
              // @ts-ignore
              const merged = existing ? existing.slice(0) : []
              for (let i = 0; i < incoming.length; ++i) {
                merged[offset + i] = incoming[i]
              }
              return merged
            },
          },
          userOrganizations: {
            // replace cache with data from backend, do not merge
            merge(existing, incoming) {
              return incoming
            },
          },
        },
      },
    },
  }),
  link: ApolloLink.from([
    errorLink,
    // place any other links before the line below
    clientVersionAfterware.concat(httpLink),
  ]),
})

const root = createRoot(document.getElementById("root")!)
root.render(
  <ApolloProvider client={client}>
    <React.StrictMode>
      <BrowserRouter>
        <AppThemeWrapper />
      </BrowserRouter>
    </React.StrictMode>
  </ApolloProvider>
)

serviceWorkerRegistration.register()
