import React from "react"

// Components
import { VerifyEmail } from "components/VerifyEmail"

export function VerifyEmailPage() {
  return (
    <>
      <VerifyEmail />
    </>
  )
}
