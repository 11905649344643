import Grid from "@mui/material/Grid"
import { useQuery } from "@apollo/client"
import { getFileUploadsQuery } from "queries/queries"
import Button from "@mui/material/Button"
import { SxProps } from "@mui/material"

interface ShowTableButtonInterface {
  textStyle: SxProps
  setShowTable: (arg: boolean) => void
}

export const ShowTableButton = (props: ShowTableButtonInterface) => {
  const { textStyle, setShowTable } = props
  const { data } = useQuery(getFileUploadsQuery)

  const handleClick = () => {
    setShowTable(true)
  }

  const renderTableButton = () => (
    <Grid item xs={12}>
      <Button sx={textStyle} onClick={handleClick}>
        Show Past Imports
      </Button>
    </Grid>
  )

  return <>{data?.fileUploads?.length ? renderTableButton() : ""}</>
}
