import QRCode from "react-qr-code"

// mui
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import FormControl from "@mui/material/FormControl"
import Alert from "@mui/material/Alert"
import Divider from "@mui/material/Divider"

// types
import { UserSecondFactorInterface } from "interfaces/UserSecondFactor"

interface SecondFactorDetailsPropsInterface {
  closeModal: () => void
  userSecondFactor: UserSecondFactorInterface
  openConfirmRemoveTwoFactor: () => void
}

export const SecondFactorDetails = (
  props: SecondFactorDetailsPropsInterface
) => {
  const { closeModal, openConfirmRemoveTwoFactor, userSecondFactor } = props

  return (
    <>
      {userSecondFactor.enabled && (
        <Alert variant="outlined" severity="success">
          Two-factor authentication is enabled on your account!
        </Alert>
      )}

      <Typography sx={{ my: 1 }}>
        To add another device, scan the QR code below with your authenticator
        app. Do not share this QR code with anyone.
      </Typography>
      <Divider sx={{ my: 1 }} />
      <Box sx={{ textAlign: "center", backgroundColor: "white", py: 5 }}>
        <QRCode value={userSecondFactor.provisioning_uri} />
      </Box>
      <Box sx={{ textAlign: "center", mt: 1 }}>
        {userSecondFactor.otp_secret}
      </Box>
      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Button
              variant="outlined"
              color="warning"
              onClick={closeModal}
              sx={{ width: { xs: "100%", md: "auto" } }}
              aria-label={`Cancel`}
            >
              Close
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              mt: { xs: 2, md: 0 },
              display: "flex",
              flexDirection: { xs: "row", md: "row-reverse" },
            }}
          >
            <FormControl
              error={false}
              sx={{ width: { xs: "100%", md: "auto" } }}
            >
              <Button
                variant="contained"
                onClick={openConfirmRemoveTwoFactor}
                disabled={false}
                color="error"
                aria-label={`remove two factor`}
              >
                Remove 2FA
              </Button>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
