// mui
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import FormControl from "@mui/material/FormControl"
import FormHelperText from "@mui/material/FormHelperText"

interface ConfirmRemoveTwoFactorPropsInterface {
  closeModal: () => void
  inputErrorText: string
  debouncedHandleRemoveTwoFactor: () => void
}

export const ConfirmRemoveTwoFactor = (
  props: ConfirmRemoveTwoFactorPropsInterface
) => {
  const { closeModal, inputErrorText, debouncedHandleRemoveTwoFactor } = props
  return (
    <>
      <Typography variant="h6" sx={{ textAlign: "center" }}>
        Removing Two-Factor will make your account less secure!
      </Typography>
      <Typography sx={{ my: 3 }}>
        Please confirm you want to remove Two-Factor authentication from your
        account. You can add Two-Factor back at any time.
      </Typography>
      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Button
              variant="outlined"
              color="warning"
              onClick={closeModal}
              sx={{ width: { xs: "100%", md: "auto" } }}
              aria-label={`Cancel`}
            >
              Cancel
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              mt: { xs: 2, md: 0 },
              display: "flex",
              flexDirection: { xs: "row", md: "row-reverse" },
            }}
          >
            <FormControl
              error={!!inputErrorText}
              sx={{ width: { xs: "100%", md: "auto" } }}
            >
              <Button
                variant="contained"
                onClick={debouncedHandleRemoveTwoFactor}
                disabled={false}
                color="error"
                aria-label={`Submit Two Factor Code`}
              >
                Remove Two-Factor
              </Button>
              <FormHelperText aria-live="polite">
                {inputErrorText}
              </FormHelperText>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
