import { StripeUserTable } from "./StripeUserTable"
import { useParams } from "react-router-dom"

export const UpgradeUserTable = () => {
  return (
    <div>
      <StripeUserTable />
    </div>
  )
}
