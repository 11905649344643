import * as React from "react"
import { createContext, useState } from "react"

// Utils
import { ModalStateInterface } from "../interfaces/Modal"

interface ModalContextInterface {
  modalState: ModalStateInterface
  setModalState: React.Dispatch<React.SetStateAction<ModalStateInterface>>
}

const defaultState = { isOpen: false, modalType: "" }

export const ModalContext = createContext<ModalContextInterface>({
  modalState: defaultState,
  setModalState: () => {},
})

export const ModalContextProvider = (props: { children: React.ReactNode }) => {
  const [modalState, setModalState] = useState(defaultState)

  return (
    <ModalContext.Provider
      value={{
        modalState,
        setModalState,
      }}
    >
      {props.children}
    </ModalContext.Provider>
  )
}
