// MUI
import IconButton from "@mui/material/IconButton"

// Icons
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined"

interface CategoryIconProps {
  toggleCategoryDropdown: () => void
}
export const CategoryIcon = (props: CategoryIconProps) => {
  const { toggleCategoryDropdown } = props

  return (
    <IconButton
      onClick={toggleCategoryDropdown}
      aria-label="show-category-list"
      tabIndex={-1}
    >
      <CategoryOutlinedIcon />
    </IconButton>
  )
}
