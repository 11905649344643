import { useState, useContext, useRef } from "react"

// MUI Components
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Alert from "@mui/material/Alert"
import { useMutation } from "@apollo/client"

// Contexts
import { ModalContext } from "contexts/ModalContext"
import { SnackBarContext } from "contexts/SnackBarContext"

// Utils
import { SNACK_BAR_TYPES } from "components/SnackBar/SnackBarTypes"
import { deleteTagMutation } from "queries/queries"
import { debounceFunction } from "Utils/Utils"

// Components
import { CloseModalButton } from "components/Modal/CloseModalButton"

// Types
import { DEBOUNCE_TIME } from "constants/Global"

interface DeleteTagModalInterface {
  id: number
  title: string
  description: string
}

export const DeleteTagModal = () => {
  const timeout = useRef<any>()
  const { modalState, setModalState } = useContext(ModalContext)
  const { setSnackBarState } = useContext(SnackBarContext)
  const [values] = useState<DeleteTagModalInterface>({
    id: modalState.data.id,
    title: modalState.data.title,
    description: modalState.data.description,
  })

  const [deleteTag] = useMutation(deleteTagMutation, {
    update(cache, { data: { deleteTag } }) {
      cache.evict({ id: cache.identify(deleteTag) })
    },
  })

  const closeModal = () => {
    setModalState({ isOpen: false, modalType: "" })
  }

  const handleDeleteTag = () => {
    deleteTag({
      variables: {
        id: values.id,
      },
    }).then(
      () => {
        setSnackBarState({
          isOpen: true,
          snackBarType: SNACK_BAR_TYPES.SUCCESS,
          message: "Tag deleted!",
        })
        closeModal()
      },
      (res: any) => {
        setSnackBarState({
          isOpen: true,
          snackBarType: SNACK_BAR_TYPES.ERROR,
          message: "Failed to delete Tag",
        })
        closeModal()
      }
    )
  }

  const debouncedhandleDeleteTag = () => {
    debounceFunction(timeout, handleDeleteTag, DEBOUNCE_TIME)
  }

  return (
    <>
      <CloseModalButton closeModal={closeModal} />
      <Typography
        textAlign="center"
        variant="h4"
        sx={{ my: 2 }}
        aria-live="polite"
        aria-label={`Cancel or Confirm Delete Tag ${values.title}`}
      >
        Delete Tag?
      </Typography>

      {modalState.data.organization && (
        <Alert severity="info" variant="outlined" sx={{ mb: 1 }}>
          This tag belongs to{" "}
          <strong>{modalState.data.organization.name}</strong>
        </Alert>
      )}

      <Typography
        variant="h5"
        sx={{
          flexGrow: 1,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          flexBasis: "100%",
        }}
      >
        Title: {values.title}
      </Typography>

      <Typography variant="body1" color="text.secondary">
        Note: Deleting a tag will also remove the tag from all of your links.
      </Typography>

      <Divider sx={{ my: 3 }}>
        <Typography>Description</Typography>
      </Divider>

      <Typography variant="body1" sx={{ wordWrap: "break-word" }}>
        {values.description}
      </Typography>

      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Button
              variant="outlined"
              color="warning"
              onClick={closeModal}
              sx={{ width: { xs: "100%", md: "auto" } }}
              aria-label={`Cancel delete Tag ${values.title}`}
            >
              Cancel
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              mt: { xs: 2, md: 0 },
              display: "flex",
              flexDirection: { xs: "row", md: "row-reverse" },
            }}
          >
            <Button
              variant="contained"
              color="error"
              onClick={debouncedhandleDeleteTag}
              sx={{ width: { xs: "100%", md: "auto" } }}
              aria-label={`Confirm delete Tag ${values.title}`}
            >
              Delete Tag
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
