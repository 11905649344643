import React, { useContext, useState, useRef, useEffect } from "react"

// mui
import Typography from "@mui/material/Typography"

// Components
import { CloseModalButton } from "components/Modal/CloseModalButton"
import { PasswordChallenge } from "../PasswordChallenge"
import { ShowBackupCodes } from "./ShowBackupCodes"

// Contexts
import { ModalContext } from "contexts/ModalContext"

// Utils
import { ApiUtils } from "Utils/ApiUtils"
import { debounceFunction } from "Utils/Utils"

// Types
import { DEBOUNCE_TIME } from "constants/Global"
import { SNACK_BAR_TYPES } from "components/SnackBar/SnackBarTypes"

// contexts
import { SnackBarContext } from "contexts/SnackBarContext"

export const ManageBackupCodesModal = () => {
  const timeout = useRef<any>()
  const { setModalState } = useContext(ModalContext)
  const { setSnackBarState } = useContext(SnackBarContext)

  const [inputPassword, setInputPassword] = useState("")
  const [inputErrorText, setInputErrorText] = useState("")

  const [userBackupCodes, setUserBackupCodes] = useState<string[]>([])
  const [stageTwoCode, setStageTwoCode] = useState<string>("")

  const closeModal = () => {
    setModalState({ isOpen: false, modalType: "" })
  }

  useEffect(() => {
    // enter submits form
    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault()
        debouncedHandleSubmitPassword()
      }
    }
    document.addEventListener("keydown", listener)
    return () => {
      document.removeEventListener("keydown", listener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputPassword])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputPassword(event.target.value)
  }

  const debouncedHandleSubmitPassword = () => {
    debounceFunction(timeout, handleSubmitPassword, DEBOUNCE_TIME)
  }

  const debouncedHandleResetCodes = () => {
    debounceFunction(timeout, handleResetCodes, DEBOUNCE_TIME)
  }

  const handleSubmitPassword = () => {
    setInputErrorText("")
    ApiUtils.getBackupCodes({
      password: inputPassword,
    }).then(
      (res: any) => {
        setUserBackupCodes(res.codes)
        setStageTwoCode(res.login_stage_two_code)
      },
      (res: object) => {
        setInputErrorText("Validation failed! Please try again.")
      }
    )
  }

  const handleResetCodes = () => {
    ApiUtils.resetBackupCodes({
      login_stage_two_code: stageTwoCode,
    }).then(
      (res: any) => {
        setUserBackupCodes(res.codes)
        setSnackBarState({
          isOpen: true,
          snackBarType: SNACK_BAR_TYPES.SUCCESS,
          message: "Backup Codes Reset.",
        })
      },
      (res: object) => {
        setInputErrorText("Something went wrong")
      }
    )
  }

  const determineModalBody = () => {
    if (userBackupCodes.length > 0) {
      return (
        <ShowBackupCodes
          closeModal={closeModal}
          debouncedHandleResetCodes={debouncedHandleResetCodes}
          userBackupCodes={userBackupCodes}
        />
      )
    } else {
      return (
        <PasswordChallenge
          closeModal={closeModal}
          debouncedHandleSubmitPassword={debouncedHandleSubmitPassword}
          handleChange={handleChange}
          inputPassword={inputPassword}
          inputErrorText={inputErrorText}
        />
      )
    }
  }

  return (
    <>
      <CloseModalButton closeModal={closeModal} />
      <Typography textAlign="center" variant="h4" sx={{ my: 2 }}>
        Backup Codes
      </Typography>
      {determineModalBody()}
    </>
  )
}
