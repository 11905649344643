import { useContext } from "react"
import { Navigate } from "react-router-dom"

// Components
import { LandingHowTo } from "components/LandingHowTo"
import { BottomLegalLinks } from "components/BottomLegalLinks"

// Contexts
import { CurrentUserContext } from "contexts/CurrentUserContext"

export function LandingPage() {
  const { currentUserState } = useContext(CurrentUserContext)

  if (currentUserState.email) {
    return <Navigate to="/links" />
  }
  return (
    <>
      <LandingHowTo />
      <BottomLegalLinks />
    </>
  )
}
