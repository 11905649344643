import React from "react"
import { useContext, useState, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { useApolloClient } from "@apollo/client"

// MUI Components
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"
import FormControl from "@mui/material/FormControl"
import FormHelperText from "@mui/material/FormHelperText"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Link from "@mui/material/Link"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import Checkbox from "@mui/material/Checkbox"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"

// Components
import { InputTextField } from "../InputTextField"

// Utils
import { ApiUtils } from "Utils/ApiUtils"
import { isValidEmail, debounceFunction } from "Utils/Utils"
import { CurrentUserContext } from "contexts/CurrentUserContext"
import { ModalContext } from "contexts/ModalContext"

// Types
import { DEBOUNCE_TIME } from "constants/Global"

export interface SignInStateInterface {
  password: string
  email: string
  showPassword: boolean
  rememberMe: boolean
}

export const SignUpForm = () => {
  const timeout = useRef<any>()
  const [emailError, setEmailError] = useState<string>("")
  const [passwordError, setPasswordError] = useState<string>("")
  const { updateCurrentUserState } = useContext(CurrentUserContext)
  const { setModalState } = useContext(ModalContext)
  const navigate = useNavigate()
  const [signInErrorText, setSignInErrorText] = React.useState<string>(" ")
  const [values, setValues] = React.useState<SignInStateInterface>({
    password: "",
    email: "",
    showPassword: false,
    rememberMe: false
  })
  const [hasBeenFocused, setHasBeenFocused] = React.useState({
    email: false,
    password: false,
  })
  const client = useApolloClient()

  const handleChange =
    (prop: keyof SignInStateInterface) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value })
    }

  const handleFocus = (prop: keyof SignInStateInterface) => {
    setHasBeenFocused({ ...hasBeenFocused, [prop]: true })
  }

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    })
  }

  const handleClickRememberMe = () => {
    setValues({
      ...values,
      rememberMe: !values.rememberMe,
    })
  }

  const closeSignUpModal = () => {
    setModalState({ isOpen: false, modalType: "" })
  }

  const handleSignUp = () => {
    client.clearStore()
    setSignInErrorText("")
    ApiUtils.createUser({
      email: values.email,
      password: values.password,
      rememberMe: values.rememberMe
    }).then(
      (res: object) => {
        closeSignUpModal()
        updateCurrentUserState(res)
        navigate("/links")
      },
      (res: any) => {
        setSignInErrorText(
          "Error - Something went wrong, please try again. If you already have an account, please login."
        )
      }
    )
  }

  useEffect(() => {
    // validate email
    if (!hasBeenFocused.email) {
    } else if (!isValidEmail(values.email)) {
      setEmailError("invalid email address!")
    } else {
      setEmailError("")
    }
  }, [hasBeenFocused.email, values.email])

  useEffect(() => {
    // validate password
    if (!hasBeenFocused.password) {
    } else if (values.password.length < 8) {
      setPasswordError("Password too short! Must be at least 8 characters")
    } else if (values.password.length > 64) {
      setPasswordError("Password too long! Must be no more than 64 characters")
    } else {
      setPasswordError("")
    }
  }, [hasBeenFocused.password, values.password])

  const debouncedHandleSignup = () => {
    debounceFunction(timeout, handleSignUp, DEBOUNCE_TIME)
  }

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <IconButton
          onClick={closeSignUpModal}
          color="warning"
          sx={{ marginLeft: "auto" }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Typography
        variant="h4"
        textAlign="center"
        sx={{ my: 2 }}
        aria-live="polite"
      >
        Create Account
      </Typography>
      <Typography textAlign="center" sx={{ my: 0 }}>
        Don't have an account? Creating one is easy!
      </Typography>
      <Typography textAlign="center" sx={{ mb: 2 }}>
        Already have an account? <Link href="/login">Login</Link>
      </Typography>
      <Divider sx={{ my: 4 }} />

      {/* Email Input */}
      <InputTextField
        name="email"
        helperText="email"
        onBlur={() => handleFocus("email")}
        handleChange={handleChange("email")}
        canToggleTextFieldVisibility={false}
        shouldShowTextField={true}
        handleClickShowTextField={() => {}}
        textField={values.email}
        error={!!emailError}
        errorText={emailError}
      />

      {/* Password Input */}
      <InputTextField
        name="password"
        helperText="password"
        onBlur={() => handleFocus("password")}
        handleChange={handleChange("password")}
        canToggleTextFieldVisibility={true}
        shouldShowTextField={values.showPassword}
        handleClickShowTextField={handleClickShowPassword}
        textField={values.password}
        error={!!passwordError}
        errorText={passwordError}
      />

      <FormGroup>
        <FormControlLabel control={ <Checkbox
          sx={{ ml: 1 }}
          checked={values.rememberMe}
          onClick={handleClickRememberMe}
        />} label="Remember Me" />
      </FormGroup>
      
      <Grid item xs={12}>
        <Typography sx={{ my: 2, width: "100%" }}>
          By creating an account, you agree to our{" "}
          <Link href="/terms_of_service" target="_blank">
            Terms of Service
          </Link>{" "}
          &{" "}
          <Link href="/privacy_policy" target="_blank">
            Privacy Policy
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            mt: { xs: 2, md: 0 },
            display: "flex",
            flexDirection: { xs: "row", md: "row-reverse" },
          }}
        >
          <Button
            variant="contained"
            onClick={debouncedHandleSignup}
            disabled={!!passwordError || !!emailError}
            sx={{ my: 1, width: { xs: "100%", md: "auto" } }}
            aria-label="Sign up. By Signing up, you are agreeing to our terms of service and privacy policy"
          >
            Sign Up
          </Button>
        </Box>
      </Grid>
      <FormControl
        error={!!signInErrorText}
        sx={{ width: { xs: "100%", md: "auto" } }}
      >
        <FormHelperText aria-live="polite">{signInErrorText}</FormHelperText>
      </FormControl>
    </>
  )
}
