import { useNavigate } from "react-router-dom"

// mui
import { styled, useTheme } from "@mui/material/styles"
import Typography from "@mui/material/Typography"
import Card from "@mui/material/Card"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip"
import InfoIcon from "@mui/icons-material/Info"

export function PricingDisplay() {
  const navigate = useNavigate()
  const theme = useTheme()

  const render3PricingCard = () => {
    const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        maxWidth: 240,
        minWidth: 240,
        border: "1px solid #dadde9",
      },
    }))

    return (
      <Card
        component="div"
        sx={{
          mx: 2,
          my: 2,
          p: 2,
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "700px",
          borderRadius: "25px",
        }}
      >
        <Typography color="secondary" variant="h4" sx={{ mt: 2, mb: 1, textAlign: "center" }}>
          $3.00 / Month
        </Typography>
        <Typography component="div">
          <List sx={{ listStyleType: "disc", pl: 4 }}>
            <ListItem sx={{ display: "list-item" }}>Everything in the free tier.</ListItem>
            <ListItem sx={{ display: "list-item" }}>2,000 Links.</ListItem>
            <ListItem sx={{ display: "list-item" }}>2,000 Categories.</ListItem>
            <ListItem sx={{ display: "list-item" }}>500 Tags.</ListItem>
            <ListItem sx={{ display: "list-item" }}>500 Folders.</ListItem>
            <ListItem sx={{ display: "list-item", cursor: "pointer" }}>
              <HtmlTooltip
                theme={theme}
                enterDelay={0}
                enterNextDelay={100}
                leaveDelay={0}
                followCursor
                title={
                  <>
                    <Typography color="inherit">Use OpenAI to suggest categories and tags for your links.</Typography>
                  </>
                }
              >
                <span>
                  100,000 monthly AI credits.
                  <InfoIcon sx={{ ml: 0.5, mb: 0.25, fontSize: "20px", verticalAlign: "middle" }} />
                </span>
              </HtmlTooltip>
            </ListItem>
          </List>
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "center", pb: 1 }}>
          <Button
            sx={{ margin: "auto", textAlign: "center" }}
            onClick={() => {
              window.open("/upgrade/")
            }}
            aria-label="Resend Email Verification"
            variant="contained"
            color="success"
          >
            Subscribe
          </Button>
        </Box>
      </Card>
    )
  }

  const renderFreePricingCard = () => {
    return (
      <Card
        component="div"
        sx={{
          mx: 2,
          my: 2,
          p: 2,
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "700px",
          borderRadius: "25px",
        }}
      >
        <Typography variant="h4" color="primary" sx={{ mt: 2, mb: 1, textAlign: "center" }}>
          $0.00 / Month
        </Typography>
        <Typography component="div">
          <List sx={{ listStyleType: "disc", pl: 4 }}>
            <ListItem sx={{ display: "list-item" }}>200 Links.</ListItem>
            <ListItem sx={{ display: "list-item" }}>200 Categories.</ListItem>
            <ListItem sx={{ display: "list-item" }}>50 Tags.</ListItem>
            <ListItem sx={{ display: "list-item" }}>50 Folders.</ListItem>
            <ListItem sx={{ display: "list-item" }}>Create and Join Organizations.</ListItem>
            <ListItem sx={{ display: "list-item" }}>Secure your account with 2 Factor.</ListItem>
            <ListItem sx={{ display: "list-item" }}>Secure your account with WebAuthn (yubikey, biometrics).</ListItem>
            <ListItem sx={{ display: "list-item" }}>Import and Export Bookmarks.</ListItem>
            <ListItem sx={{ display: "list-item" }}>Unlimited number of devices.</ListItem>
            <ListItem sx={{ display: "list-item" }}>Search by Url, Title, Category, and Tags all at once.</ListItem>
          </List>
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "center", pb: 1 }}>
          <Button
            sx={{ margin: "auto", textAlign: "center" }}
            onClick={() => {
              navigate("/")
            }}
            aria-label="Resend Email Verification"
            variant="contained"
            color="success"
          >
            Sign Up
          </Button>
        </Box>
      </Card>
    )
  }

  return (
    <>
      <Box sx={{ margin: "auto", maxWidth: "1000px" }}>
        <Typography variant="h4" sx={{ my: 2, mb: 1, textAlign: "center" }}>
          Linkidex Pricing
        </Typography>

        <Grid sx={{ pt: 2 }} container spacing={2}>
          <Grid item xs={12} sm={6}>
            {renderFreePricingCard()}
          </Grid>
          <Grid item xs={12} sm={6}>
            {render3PricingCard()}
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
