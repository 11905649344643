import React from "react"
import { useRef, useEffect, useState, useContext } from "react"
import { useMutation } from "@apollo/client"

// MUI
import Grid from "@mui/material/Grid"
import Chip from "@mui/material/Chip"
import Box from "@mui/material/Box"
import Tooltip from "@mui/material/Tooltip"
import IconButton from "@mui/material/IconButton"

// MUI Icons
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"

// Contexts
import { SnackBarContext } from "contexts/SnackBarContext"

// Types
import { TagInterface } from "interfaces/Tags"
import { SNACK_BAR_TYPES } from "components/SnackBar/SnackBarTypes"
import { DEBOUNCE_TIME } from "constants/Global"
// Queries
import { deleteTagLinkMutation } from "queries/queries"

// Utils
import { debounceFunction } from "Utils/Utils"

interface HorizontalTagListInterface {
  tags?: TagInterface[]
  shouldShowDelete: boolean
  linkId: number
}

export const HorizontalTagList = (props: HorizontalTagListInterface) => {
  const timeout = useRef<any>()
  const { tags, linkId, shouldShowDelete } = props
  const scrollRef = useRef<HTMLElement>(null)
  const [scrollX, setScrollX] = useState(0) // detect if we are at start of scroll
  const [scrollEnd, setScrollEnd] = useState(false) // detect if we are at end of scroll
  const [deleteTagLink] = useMutation(deleteTagLinkMutation)
  // deleteTagLinkMutation returns a new Link object which apollo updates for us in the cache
  const { setSnackBarState } = useContext(SnackBarContext)

  const buildTags = () => {
    if (!tags) {
      return null
    }

    return tags.map((tag: TagInterface, idx) => {
      if (shouldShowDelete) {
        return (
          <Tooltip enterDelay={500} key={idx} followCursor title="Click 'X' to remove this tag from your link">
            <Chip
              sx={{ mb: 2, mx: 0.25 }}
              key={idx}
              // variant="outlined"
              label={tag.title}
              aria-controls="simple-menu"
              aria-haspopup="true"
              onDelete={() => debouncedHandleDeleteTagLink(tag)}
            />
          </Tooltip>
        )
      } else {
        return (
          <Chip
            sx={{ mb: 2, mx: 0.25 }}
            key={idx}
            // variant="outlined"
            label={tag.title}
            aria-controls="simple-menu"
            aria-haspopup="true"
          />
        )
      }
    })
  }

  const handleDeleteTagLink = (tag: TagInterface) => {
    deleteTagLink({
      variables: {
        tagId: tag.id,
        linkId: linkId,
      },
    }).then(
      () => {
        setSnackBarState({
          isOpen: true,
          snackBarType: SNACK_BAR_TYPES.SUCCESS,
          message: "Tag removed!",
        })
      },
      (res: any) => {
        setSnackBarState({
          isOpen: true,
          snackBarType: SNACK_BAR_TYPES.ERROR,
          message: "Failed to remove tag, please try again.",
        })
      }
    )
  }

  const debouncedHandleDeleteTagLink = (tag: TagInterface) => {
    debounceFunction(timeout, () => handleDeleteTagLink(tag), DEBOUNCE_TIME)
  }

  const slide = (shift: number) => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.scrollBy({ left: shift, top: 0, behavior: "smooth" })
      setScrollX(scrollX + shift)

      if (Math.floor(scrollRef.current.scrollWidth - scrollRef.current.scrollLeft) <= scrollRef.current.offsetWidth) {
        setScrollEnd(true)
      } else {
        setScrollEnd(false)
      }
    }
  }

  // on scroll event, update setScrollEnd if we are at end of scrollable area
  // this is required because clicking buttons and triggering `slide`isn't
  //  the only way a user can scroll
  const scrollCheck = () => {
    if (scrollRef && scrollRef.current) {
      setScrollX(scrollRef.current.scrollLeft)
      if (Math.floor(scrollRef.current.scrollWidth - scrollRef.current.scrollLeft) <= scrollRef.current.offsetWidth) {
        setScrollEnd(true)
      } else {
        setScrollEnd(false)
      }
    }
  }

  // update setScrollEnd if we are at end of scrollable area
  useEffect(() => {
    if (scrollRef.current && scrollRef?.current?.scrollWidth === scrollRef?.current?.offsetWidth) {
      setScrollEnd(true)
    } else {
      setScrollEnd(false)
    }
    return () => {}
  }, [scrollRef?.current?.scrollWidth, scrollRef?.current?.offsetWidth])

  return (
    <Grid sx={{ mt: 2 }} container spacing={0}>
      {/* scroll left button */}
      <Grid item xs={1}>
        {scrollX !== 0 && (
          <IconButton onClick={() => slide(-400)}>
            <ChevronLeftIcon sx={{ fontSize: "30px" }} />
          </IconButton>
        )}
      </Grid>

      {/* Tags List */}
      <Grid item xs={10}>
        <Box
          onScroll={scrollCheck}
          ref={scrollRef}
          sx={{
            flexWrap: "wrap",
            justifyContent: "space-around",
            overflow: "auto",
            whiteSpace: "nowrap",
            "&::-webkit-scrollbar": {
              width: "4px",
              height: "4px",
            },
            "&::-webkit-scrollbar-track": {
              bgcolor: "scrollbarBGColor",
            },
            "&::-webkit-scrollbar-thumb": {
              bgcolor: "scrollbarThumbColor",
            },
          }}
        >
          {buildTags()}
        </Box>
      </Grid>

      {/* scroll right button */}
      <Grid item xs={1}>
        {!scrollEnd && (
          <IconButton onClick={() => slide(400)}>
            <ChevronRightIcon sx={{ fontSize: "30px" }} />
          </IconButton>
        )}
      </Grid>
    </Grid>
  )
}
