// mui
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import FormControl from "@mui/material/FormControl"
import FormHelperText from "@mui/material/FormHelperText"

// Components
import { InputTextField } from "components/InputTextField"

interface PasswordChallengePropsInterface {
  closeModal: () => void
  debouncedHandleSubmitPassword: () => void
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  inputPassword: string
  inputErrorText: string
}

export const PasswordChallenge = (props: PasswordChallengePropsInterface) => {
  const {
    closeModal,
    debouncedHandleSubmitPassword,
    handleChange,
    inputPassword,
    inputErrorText,
  } = props

  return (
    <>
      <Typography textAlign="center" sx={{ my: 2 }}>
        Please enter your password to continue.
      </Typography>
      <InputTextField
        autoComplete={"off"}
        name="password"
        helperText="Password"
        handleChange={handleChange}
        canToggleTextFieldVisibility={false}
        shouldShowTextField={false}
        handleClickShowTextField={() => {}}
        textField={inputPassword}
        error={!!inputErrorText}
        errorText={inputErrorText}
      />

      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Button
              variant="outlined"
              color="warning"
              onClick={closeModal}
              sx={{ width: { xs: "100%", md: "auto" } }}
              aria-label={`Cancel`}
            >
              Cancel
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              mt: { xs: 2, md: 0 },
              display: "flex",
              flexDirection: { xs: "row", md: "row-reverse" },
            }}
          >
            <FormControl
              error={!!inputErrorText}
              sx={{ width: { xs: "100%", md: "auto" } }}
            >
              <Button
                variant="contained"
                onClick={debouncedHandleSubmitPassword}
                disabled={false}
                aria-label={`submit password`}
              >
                Submit
              </Button>
              <FormHelperText aria-live="polite">
                {inputErrorText}
              </FormHelperText>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
