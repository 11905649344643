import { useState, useContext } from "react"

// MUI
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import IconButton from "@mui/material/IconButton"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"

// contexts
import { ModalContext } from "contexts/ModalContext"

// types
import { MODAL_TYPES } from "components/Modal/ModalTypes"
import { CATEGORY } from "constants/Global"

interface UserCategoryDropdownProps {
  organizationId?: string
}

export const UserCategoryDropdown = (props: UserCategoryDropdownProps) => {
  const { organizationId } = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { setModalState } = useContext(ModalContext)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    event.stopPropagation()

    setIsOpen(true)
  }

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()

    setIsOpen(false)
  }

  const doNotRipple = (event: any) => {
    // this prevents the ripple effect from propagating up
    event.stopPropagation()
  }

  const handleAddCategory = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation()
    event.preventDefault()
    setIsOpen(false)

    setModalState({
      isOpen: true,
      modalType: MODAL_TYPES.CREATE_COLLECTION,
      data: { organizationId: organizationId },
    })
  }

  const handleAddFolder = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation()
    event.preventDefault()
    setIsOpen(false)

    setModalState({
      isOpen: true,
      modalType: MODAL_TYPES.CREATE_FOLDER,
      data: {
        folderType: CATEGORY,
        organizationId: organizationId,
      },
    })
  }

  return (
    <>
      <IconButton onMouseDown={doNotRipple} onClick={handleClick}>
        <AddCircleOutlineIcon />
      </IconButton>

      <Menu id="basic-menu" anchorEl={anchorEl} open={isOpen} onClose={handleClose}>
        <MenuItem onClick={handleAddCategory}>Create Category</MenuItem>
        <MenuItem onClick={handleAddFolder}>Create Folder</MenuItem>
      </Menu>
    </>
  )
}
