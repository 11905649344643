import * as React from "react"
import { createContext, useState } from "react"

import { AlertColor } from "@mui/material/Alert"

import { SNACK_BAR_TYPES } from "components/SnackBar/SnackBarTypes"

export interface SnackBarStateInterface {
  isOpen: boolean
  snackBarType: SNACK_BAR_TYPES
  message: string
}

interface SnackBarContextInterface {
  snackBarState: SnackBarStateInterface
  setSnackBarState: React.Dispatch<React.SetStateAction<SnackBarStateInterface>>
}

const defaultState = {
  isOpen: false,
  snackBarType: SNACK_BAR_TYPES.SUCCESS,
  message: "",
}

export const SnackBarContext = createContext<SnackBarContextInterface>({
  snackBarState: defaultState,
  setSnackBarState: () => {},
})

export const SnackBarContextProvider = (props: {
  children: React.ReactNode
}) => {
  const [snackBarState, setSnackBarState] = useState(defaultState)

  return (
    <SnackBarContext.Provider
      value={{
        snackBarState,
        setSnackBarState,
      }}
    >
      {props.children}
    </SnackBarContext.Provider>
  )
}
