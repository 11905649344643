import FolderIcon from "@mui/icons-material/Folder"
import { FOLDER } from "constants/Global"

export const TypeIcon = (props: any) => {
  switch (props.contentType) {
    case FOLDER:
      return <FolderIcon />
    default:
      return null
  }
}
