import { useParams } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { Navigate } from "react-router-dom"
import Typography from "@mui/material/Typography"

// components
import { UpgradeOrganizationTable } from "components/UpgradeOrganizationTable"

// queries
import { getOrganizationQuery } from "queries/queries"

export function UpgradeOrganizationPage() {
  const { organization_id } = useParams()

  const { loading, data } = useQuery(getOrganizationQuery, {
    fetchPolicy: "network-only",
    variables: {
      id: organization_id,
    },
  })

  const organizationTitle = () => (
    <Typography variant="h3" sx={{ textAlign: "center", mt: 2 }}>
      {data?.organization?.name ? data?.organization?.name : "Organization"}
    </Typography>
  )

  const renderPage = () => {
    if (loading) {
      // wait if we are still fetching org
      return <></>
    } else if (data) {
      // display org if we fetched it
      return (
        <>
          {organizationTitle()}
          <UpgradeOrganizationTable />
        </>
      )
    } else {
      // redirect user to their org list
      return <Navigate to="/organizations" replace />
    }
  }

  return <>{renderPage()}</>
}
