import React from "react"

// Components
import { JoinOrganization } from "components/JoinOrganization"

export function JoinOrganizationPage() {
  return (
    <>
      <JoinOrganization />
    </>
  )
}
