import * as React from "react"
import { createContext, useState } from "react"

interface PersonalLinksSelectedContextInterface {
  personalLinksSelectedState: boolean
  setPersonalLinksSelectedState: React.Dispatch<React.SetStateAction<boolean>>
}

const defaultState = false

export const PersonalLinksSelectedContext = createContext<PersonalLinksSelectedContextInterface>({
  personalLinksSelectedState: defaultState,
  setPersonalLinksSelectedState: () => {},
})

export const PersonalLinksSelectedContextProvider = (props: { children: React.ReactNode }) => {
  const [personalLinksSelectedState, setPersonalLinksSelectedState] = useState(defaultState)

  return (
    <PersonalLinksSelectedContext.Provider
      value={{
        personalLinksSelectedState,
        setPersonalLinksSelectedState,
      }}
    >
      {props.children}
    </PersonalLinksSelectedContext.Provider>
  )
}
