import React from "react"
import { useContext } from "react"
import { useQuery } from "@apollo/client"

// MUI
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"
import { SxProps } from "@mui/material"

// Components
import { ModalContext } from "contexts/ModalContext"
// Types
import { MODAL_TYPES } from "components/Modal/ModalTypes"

// Queries
import { getActiveUserQuery } from "queries/queries"

interface SecondFactorEnabledRowInterface {
  textStyle: SxProps
}

export function SecondFactorEnabledRow({ textStyle }: SecondFactorEnabledRowInterface) {
  const { setModalState } = useContext(ModalContext)
  const { data } = useQuery(getActiveUserQuery)

  const openManageTwoFactorModal = () => {
    setModalState({ isOpen: true, modalType: MODAL_TYPES.MANAGE_TWO_FACTOR })
  }

  const TwoFactorTitle = () => {
    if (data?.activeUser?.secondFactorEnabled) {
      return "Two-Factor Enabled"
    } else {
      return "Two-Factor Disabled"
    }
  }

  const TwoFactorSubtitle = () => {
    if (!data?.activeUser?.secondFactorEnabled) {
      return "Please enable Two-Factor to protect your account."
    }
  }

  return (
    <>
      {/* Left part of row */}
      <Grid item sx={{ display: { marginTop: 20 } }} xs={12} sm={8}>
        <Typography sx={textStyle} variant="h6">
          {TwoFactorTitle()}
        </Typography>
        <Typography sx={textStyle} variant="subtitle2">
          {TwoFactorSubtitle()}
        </Typography>
      </Grid>

      {/* Right part of row */}
      <Grid item sx={{ display: { marginTop: 20 } }} xs={12} sm={4}>
        <Button
          aria-label="manage-two-factor"
          onClick={openManageTwoFactorModal}
          variant="outlined"
          sx={{ mt: { xs: 0, sm: 1.5 }, ml: { xs: 2, sm: 0 } }}
        >
          Manage 2FA
        </Button>
      </Grid>
    </>
  )
}
