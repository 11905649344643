import * as React from "react"
import { createContext, useState } from "react"

interface SelectedCategoriesInterface {
  [key: number]: boolean
}

interface SelectedCategoriesContextInterface {
  selectedCategoriesState: SelectedCategoriesInterface
  setSelectedCategoriesState: React.Dispatch<
    React.SetStateAction<SelectedCategoriesInterface>
  >
}

const defaultState = {}

export const SelectedCategoriesContext =
  createContext<SelectedCategoriesContextInterface>({
    selectedCategoriesState: defaultState,
    setSelectedCategoriesState: () => {},
  })

export const SelectedCategoriesContextProvider = (props: {
  children: React.ReactNode
}) => {
  const [selectedCategoriesState, setSelectedCategoriesState] =
    useState(defaultState)

  return (
    <SelectedCategoriesContext.Provider
      value={{
        selectedCategoriesState,
        setSelectedCategoriesState,
      }}
    >
      {props.children}
    </SelectedCategoriesContext.Provider>
  )
}
