// MUI
import ListItemButton from "@mui/material/ListItemButton"
import IconButton from "@mui/material/IconButton"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import Typography from "@mui/material/Typography"
import Card from "@mui/material/Card"

// Types
import { SEARCH_ROW_MOBILE } from "constants/Global"
import { LinkInterface } from "interfaces/Link"

// Components
import { LinkDropdown } from "./LinkDropdown"
import { ToggleFavorite } from "./ToggleFavorite"

interface SearchRowMobilePropsInterface {
  style: React.CSSProperties
  index: number
  linkId: LinkInterface["id"]
  linkTitle: LinkInterface["title"]
  linkCategory: LinkInterface["category"]
  linkTags: LinkInterface["tags"]
  linkDescription: LinkInterface["description"]
  linkUserLinkFavorites: LinkInterface["userLinkFavorites"]
  copyLink: () => void
  openLink: (event: any) => void
  editLink: () => void
  deleteLink: () => void
}

export const SearchRowMobile = (props: SearchRowMobilePropsInterface) => {
  const {
    style,
    index,
    linkId,
    linkTitle,
    linkCategory,
    linkTags,
    linkDescription,
    linkUserLinkFavorites,
    copyLink,
    openLink,
    editLink,
    deleteLink,
  } = props

  const categoryTitle = () => {
    if (linkCategory?.title) {
      return (
        <Typography
          aria-label={`Link Category: ${linkCategory?.title || "None"}`}
          variant="body2"
          component="div"
          color="text.secondary"
          sx={{
            mr: 1,
            flexGrow: 1,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            flexBasis: "100%",
          }}
        >
          {linkCategory?.title}
        </Typography>
      )
    }

    return (
      <Typography
        aria-label={`Link Category: ${linkCategory?.title || "None"}`}
        variant="body2"
        component="div"
        color="text.secondary"
        sx={{
          mr: 1,
          flexGrow: 1,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          flexBasis: "100%",
        }}
      >
        (no category)
      </Typography>
    )
  }

  const determineLinkDescription = () => {
    if (linkDescription) {
      return (
        <Typography
          aria-label={`Link Category: ${linkCategory?.title || "None"}`}
          variant="body2"
          component="div"
          color="text.secondary"
          sx={{
            mr: 1,
            flexGrow: 1,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            flexBasis: "100%",
          }}
        >
          {linkDescription}
        </Typography>
      )
    }

    return (
      <Typography
        aria-label={`Link Category: ${linkCategory?.title || "None"}`}
        variant="body2"
        component="div"
        color="text.secondary"
        sx={{
          mr: 1,
          flexGrow: 1,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          flexBasis: "100%",
        }}
      >
        (no description)
      </Typography>
    )
  }

  return (
    <Card
      style={style}
      key={index}
      // variant="outlined"
      sx={{
        maxWidth: "99%",
        mt: 1,
        display: "flex",
      }}
    >
      {/* ------------------------ Copy Link ------------------------ */}
      <ToggleFavorite linkId={linkId} linkTitle={linkTitle} userLinkFavorites={linkUserLinkFavorites} />

      {/* ------------------------ List Item Button ------------------------ */}

      <ListItemButton
        onClick={openLink}
        sx={{
          py: 1,
          display: "flex",
          minWidth: "0px",
          flexWrap: "wrap",
          minHeight: `${SEARCH_ROW_MOBILE}px`, // same as FixSizedList's `itemSize`
        }}
      >
        {/* ------------------------ Title ------------------------ */}

        <Typography
          aria-label={`Link Title: ${linkTitle}`}
          variant="body1"
          sx={{
            flexGrow: 1,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            flexBasis: "100%",
          }}
          component="div"
        >
          {linkTitle}
        </Typography>

        {/* ------------------------ Category ------------------------ */}

        {categoryTitle()}
        {determineLinkDescription()}
      </ListItemButton>
      <LinkDropdown linkTitle={linkTitle} editLink={editLink} deleteLink={deleteLink} copyLink={copyLink} />
    </Card>
  )
}
