import { ThemeOptions } from "@mui/material"
import darkScrollbar from "@mui/material/darkScrollbar"

declare module "@mui/material/styles" {
  interface PaletteOptions {
    scrollbarBGColor?: React.CSSProperties["color"]
    scrollbarThumbColor?: React.CSSProperties["color"]
  }
}

export const light: ThemeOptions = {
  palette: {
    mode: "light",
  },
}

export const dark: ThemeOptions = {
  palette: {
    mode: "dark",
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          ...darkScrollbar(),
        },
      },
    },
  },
}
