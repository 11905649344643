import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Link from "@mui/material/Link"
import Divider from "@mui/material/Divider"

export const UserPolicyPage = () => {
  return (
    <Box sx={{ p: 4 }}>
      <Typography>
        The following activity/material isn’t allowed on Linkidex.com.
      </Typography>
      <Typography variant="h5" sx={{ mt: 2 }}>
        {" "}
        Illegal content and conduct.
      </Typography>
      <Typography>Self-explanatory.</Typography>
      <Typography variant="h5" sx={{ mt: 2 }}>
        {" "}
        Intellectual property infringement.
      </Typography>
      <Typography>
        Do not use Linkidex.com to share or distribute content that violates any
        other person's or organization's intellectual property rights.
      </Typography>
      <Typography variant="h5" sx={{ mt: 2 }}>
        {" "}
        Technologically harmful content.
      </Typography>
      <Typography>
        Do not upload or link to malware, spyware, adware, or other malicious or
        destructive code.
      </Typography>
      <Typography variant="h5" sx={{ mt: 2 }}>
        {" "}
        Impersonation.
      </Typography>
      <Typography>
        Don’t claim to be a person or organization you’re not. (Parody and
        satire are ok though!)
      </Typography>
      <Typography variant="h5" sx={{ mt: 2 }}>
        {" "}
        Directly threatening material.
      </Typography>
      <Typography>
        Do not post direct and realistic threats of violence. That is, you
        cannot post a genuine call for violence—or death—against an individual
        person, or groups of persons. This doesn’t mean that we’ll remove all
        hyperbole or offensive language.
      </Typography>
      <Typography variant="h5" sx={{ mt: 2 }}>
        {" "}
        Posting private information.
      </Typography>
      <Typography>
        Don’t share someone’s personal information without their consent.
      </Typography>
      <Typography variant="h5" sx={{ mt: 2 }}>
        {" "}
        Spam or machine-generated content.
      </Typography>
      <Typography>
        Self-explanatory. Do not use Linkidex to pollute the internet.
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Typography>
        Bear in mind that these are just guidelines — interpretations are solely
        up to us. These guidelines are not exhaustive and are subject to change.
        If you are aware of anything in violation of the above or our{" "}
        <Link href="/terms_of_service">terms of service</Link> or{" "}
        <Link href="/privacy_policy">privacy policy,</Link> please reach out to
        help@linkidex.com.
      </Typography>
    </Box>
  )
}
