/* eslint-disable no-restricted-globals */
import React from "react"
import { useEffect, useRef } from "react"
import { useParams, useNavigate } from "react-router-dom"

// MUI Components
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"
import Alert from "@mui/material/Alert"
import Collapse from "@mui/material/Collapse"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import FormControl from "@mui/material/FormControl"
import FormHelperText from "@mui/material/FormHelperText"

// Components
import { InputTextField } from "../InputTextField"

// Utils
import { ApiUtils } from "../../Utils/ApiUtils"
import { debounceFunction } from "../../Utils/Utils"

// TYPES
import { DEBOUNCE_TIME } from "constants/Global"

// Styles
import { resetPasswordFormStyle } from "./styles"

export interface ForgotPasswordFormInterface {
  newPassword: string
  confirmNewPassword: string
}

export const ResetPasswordForm = () => {
  const timeout = useRef<any>()
  const navigate = useNavigate()
  const [updatePasswordError, setUpdatePasswordError] =
    React.useState<string>("")
  const [updateConfirmPasswordError, setUpdateConfirmPasswordError] =
    React.useState<string>("")
  const [isSubmitDisabled, setIsSubmitDisabled] = React.useState<boolean>(false)
  const [updatePasswordErrorText, setUpdatePasswordErrorText] =
    React.useState<string>(" ")
  const [isOpen, setIsOpen] = React.useState<boolean | undefined>(false)
  const [isPasswordResetSuccessful, setIsPasswordResetSuccessful] =
    React.useState<boolean>(false)
  const [values, setValues] = React.useState<ForgotPasswordFormInterface>({
    newPassword: "",
    confirmNewPassword: "",
  })

  let { code } = useParams()

  const handleChange =
    (prop: keyof ForgotPasswordFormInterface) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value })
    }

  const handleResetPassword = () => {
    setUpdatePasswordErrorText("")
    ApiUtils.recoverAccount({
      newPassword: values.newPassword,
      code: code,
    }).then(
      (res: object) => {
        setIsOpen(true)
        setIsPasswordResetSuccessful(true)
      },
      (res: object) => {
        setUpdatePasswordErrorText(
          "Something went wrong, please make sure you have the correct link and try again."
        )
      }
    )
  }

  const debouncedHandleResetPassword = () => {
    debounceFunction(timeout, handleResetPassword, DEBOUNCE_TIME)
  }

  useEffect(() => {
    // user hasn't finished filling out the form
    if (values.newPassword.length === 0) {
      setIsSubmitDisabled(true)
      // password is not long enough
    } else if (values.newPassword.length < 8) {
      setUpdatePasswordError("password too short!")
    } else {
      setUpdatePasswordError("")
    }
  }, [values])

  useEffect(() => {
    // user hasn't finished filling out the form

    if (
      values.confirmNewPassword.length > 0 &&
      values.newPassword !== values.confirmNewPassword
    ) {
      setUpdateConfirmPasswordError("passwords do not match!")
    } else {
      setUpdateConfirmPasswordError("")
    }
  }, [values])

  const successAlert = () => {
    return (
      <>
        <Collapse in={isOpen} sx={{ px: { xs: 2, md: 6 } }}>
          <Alert
            sx={{ mt: 1 }}
            variant="outlined"
            severity="success"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setIsOpen(false)
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <Typography>Password reset successfully!</Typography>
          </Alert>
        </Collapse>
      </>
    )
  }

  const inputPasswordForm = () => {
    if (isPasswordResetSuccessful) {
      return
    }
    return (
      <Box sx={resetPasswordFormStyle}>
        <Typography variant="h4" textAlign="center" sx={{ my: 2 }}>
          Reset Password
        </Typography>

        {/* input email */}
        <InputTextField
          name="newPassword"
          helperText="New Password"
          handleChange={handleChange("newPassword")}
          canToggleTextFieldVisibility={false}
          shouldShowTextField={false}
          handleClickShowTextField={() => {}}
          textField={values.newPassword}
          error={!!updatePasswordError}
          errorText={updatePasswordError}
        />

        <InputTextField
          name="confirmNewPassword"
          helperText="Confirm New Password"
          handleChange={handleChange("confirmNewPassword")}
          canToggleTextFieldVisibility={false}
          shouldShowTextField={false}
          handleClickShowTextField={() => {}}
          textField={values.confirmNewPassword}
          error={!!updateConfirmPasswordError}
          errorText={updateConfirmPasswordError}
        />

        <Button
          variant="contained"
          disabled={!!updatePasswordError || !!updateConfirmPasswordError}
          onClick={debouncedHandleResetPassword}
        >
          Update Password
        </Button>

        <br />

        <FormControl
          error={!!updatePasswordErrorText}
          sx={{ width: { xs: "100%", md: "auto" } }}
        >
          <FormHelperText aria-live="polite">
            {updatePasswordErrorText}
          </FormHelperText>
        </FormControl>

        <Divider sx={{ my: 2 }} />

        <Button
          variant="outlined"
          color="warning"
          onClick={() => navigate("/")}
        >
          Cancel
        </Button>
      </Box>
    )
  }

  const successMessage = () => {
    if (isPasswordResetSuccessful) {
      return (
        <>
          <Box sx={resetPasswordFormStyle}>
            <Typography variant="h4" sx={{ m: 2 }}>
              You may now login with your new password.
            </Typography>

            <Button
              sx={{ margin: "auto" }}
              variant="contained"
              onClick={() => navigate("/")}
            >
              Login
            </Button>
          </Box>
        </>
      )
    }
  }

  return (
    <>
      {successAlert()}
      {inputPasswordForm()}
      {successMessage()}
    </>
  )
}
