import React, { useState, useContext, useEffect, useRef } from "react"
import { useMutation, useQuery } from "@apollo/client"

// MUI Components
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import FormHelperText from "@mui/material/FormHelperText"
import FormControl from "@mui/material/FormControl"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"

// Components
import { InputTextField } from "components/InputTextField"
import { CloseModalButton } from "components/Modal/CloseModalButton"
import { SelectOrganizationForm } from "components/SelectOrganizationForm"

// Contexts
import { ModalContext } from "contexts/ModalContext"
import { SnackBarContext } from "contexts/SnackBarContext"

// Utils
import { SNACK_BAR_TYPES } from "components/SnackBar/SnackBarTypes"
import { debounceFunction } from "Utils/Utils"
import { writeFolderQuery, createFolderMutation, getOrganizationsQuery, getOrganizationQuery } from "queries/queries"

// Types
import { DEBOUNCE_TIME, CATEGORY, TAG } from "constants/Global"
import { OrganizationInterface } from "interfaces/Organization"
import { DropDownOption } from "components/SelectFieldDropdown"
import { SelectChangeEvent } from "@mui/material/Select"

interface CreateFolderModalStateInterface {
  title: string
  description: string
  folderType: typeof CATEGORY | typeof TAG
}

interface CreateFolderModalPropsInterface {
  isControlledByContext?: boolean // is the modal controlled by modalContext or not
  handleClose?: () => void // function to close modal if it is not controlled by context
  callBack?: (folder: any) => void // function called after successfully creating category
  folderType?: typeof CATEGORY | typeof TAG // when modal is not controlled by context
}

export const CreateFolderModal = (props: CreateFolderModalPropsInterface) => {
  const timeout = useRef<any>()
  const { isControlledByContext, handleClose, callBack, folderType } = props
  const [titleError, setTitleError] = useState<string>("")
  const [descriptionError, setDescriptionError] = useState<string>("")
  const [errorText, setErrorText] = useState<string>(" ")
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
  const { modalState, setModalState } = useContext(ModalContext)
  const { setSnackBarState } = useContext(SnackBarContext)
  const getOrgsResponse = useQuery(getOrganizationsQuery)
  const [values, setValues] = useState<CreateFolderModalStateInterface>({
    folderType: modalState.data?.folderType || folderType,
    title: "",
    description: "",
  })

  const getOrganizationQueryResponse = useQuery(getOrganizationQuery, {
    variables: {
      id: modalState.data?.organizationId,
    },
  })

  const [orgDropdownOption, setOrgDropdownOption] = useState<DropDownOption>({
    name: getOrganizationQueryResponse?.data?.organization?.name || "Personal",
    value: modalState.data?.organizationId || "0",
  })

  const [addFolder] = useMutation(createFolderMutation, {
    update(cache, { data: { createFolder } }) {
      cache.modify({
        fields: {
          folders(existingFolders = []) {
            const newFolderRef = cache.writeFragment({
              data: createFolder,
              fragment: writeFolderQuery,
            })
            return [...existingFolders, newFolderRef]
          },
        },
      })
    },
  })

  const buildOrgDropdownOptions = () => {
    if (getOrgsResponse.loading || getOrgsResponse.error) {
      return []
    }

    return getOrgsResponse.data.organizations
      .map((organization: OrganizationInterface) => {
        return { name: organization.name, value: organization.id }
      })
      .concat([{ name: "Personal", value: "0" }])
  }

  const handleOrgSelect = (event: SelectChangeEvent | null, value: DropDownOption) => {
    if (event && event.target) {
      setOrgDropdownOption(event.target)
    }
  }

  const closeModal = () => {
    if (isControlledByContext !== false) {
      setModalState({ isOpen: false, modalType: "" })
    } else if (handleClose) {
      handleClose()
    }
  }

  const handleChange =
    (prop: keyof CreateFolderModalStateInterface) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value })
    }

  const handleCreateFolder = () => {
    addFolder({
      variables: {
        title: values.title,
        description: values.description,
        folderType: values.folderType,
        organizationId: orgDropdownOption?.value !== "0" ? orgDropdownOption?.value : null,
      },
    }).then(
      (res: any) => {
        if (callBack) {
          callBack(res.data.createFolder)
        } else {
          setSnackBarState({
            isOpen: true,
            snackBarType: SNACK_BAR_TYPES.SUCCESS,
            message: "Folder Created!",
          })
        }
        closeModal()
      },
      (res: any) => {
        setTitleError("")
        setDescriptionError("")
        if (res && res.message) {
          setErrorText(res.message)
        } else {
          setErrorText("Something went wrong, please try again.")
        }
      }
    )
  }

  const debouncedHandleCreateFolder = () => {
    debounceFunction(timeout, handleCreateFolder, DEBOUNCE_TIME)
  }

  useEffect(() => {
    let tempTitleError = ""
    let tempDescriptionError = ""

    if (values.title.length < 3) {
      tempTitleError = "Title too short."
    }
    if (values.title.length > 64) {
      tempTitleError = "Title too long."
    }
    if (values.description && values.description.length > 128) {
      tempDescriptionError = "Description too long!"
    }

    if (values.title.length === 0) {
      setIsSubmitDisabled(true)
    } else if (!tempTitleError && !tempDescriptionError) {
      setErrorText("")
      setTitleError("")
      setDescriptionError("")
      setIsSubmitDisabled(false)
    } else {
      setIsSubmitDisabled(true)
      setTitleError(tempTitleError)
      setDescriptionError(tempDescriptionError)
    }
  }, [values])

  useEffect(() => {
    const listener = (event: any) => {
      if (
        (event.code === "Enter" || event.code === "NumpadEnter") &&
        (event.srcElement.id === "outlined-adornment-folder-title" ||
          event.srcElement.id === "outlined-adornment-folder-description")
      ) {
        event.preventDefault()
        if (isSubmitDisabled) {
          return
        }
        debouncedHandleCreateFolder()
      }
    }
    document.addEventListener("keydown", listener)
    return () => {
      document.removeEventListener("keydown", listener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, isSubmitDisabled])

  return (
    <>
      <CloseModalButton closeModal={closeModal} />
      <Typography textAlign="center" variant="h4" sx={{ my: 2 }} aria-live="polite">
        Create Folder
      </Typography>

      <InputTextField
        autoFocus={true}
        autoComplete={"off"}
        name="folder-title"
        helperText="Title*"
        handleChange={handleChange("title")}
        canToggleTextFieldVisibility={false}
        shouldShowTextField={true}
        handleClickShowTextField={() => {}}
        textField={values.title}
        error={!!titleError}
        errorText={titleError}
      />

      <SelectOrganizationForm
        dropdownOptions={buildOrgDropdownOptions()}
        title="Select who owns this folder"
        selectedOption={orgDropdownOption}
        handleSelect={handleOrgSelect}
      />

      <Divider sx={{ my: 3 }}>Optional</Divider>

      <InputTextField
        name="folder-description"
        helperText="Description"
        autoComplete={"off"}
        handleChange={handleChange("description")}
        canToggleTextFieldVisibility={false}
        shouldShowTextField={true}
        handleClickShowTextField={() => {}}
        textField={values.description}
        error={!!descriptionError}
        errorText={descriptionError}
      />

      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Button variant="outlined" color="warning" onClick={closeModal} sx={{ width: { xs: "100%", md: "auto" } }}>
              Cancel
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              mt: { xs: 2, md: 0 },
              display: "flex",
              flexDirection: { xs: "row", md: "row-reverse" },
            }}
          >
            <FormControl error={!!errorText} sx={{ width: { xs: "100%", md: "auto" } }}>
              <Button variant="contained" onClick={debouncedHandleCreateFolder} disabled={isSubmitDisabled}>
                Create Folder
              </Button>
              <FormHelperText aria-live="polite">{errorText}</FormHelperText>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
