// utils
import { sortByText } from "Utils/Utils"

// Components
import { SimpleDropdown } from "components/SimpleDropdown"
import { DropDownOption } from "components/SelectFieldDropdown"

interface SelectOrganizationFormInterface {
  dropdownOptions: DropDownOption[]
  title: string
  selectedOption: DropDownOption
  handleSelect: (event: any, value: any) => void
}

export const SelectOrganizationForm = (
  props: SelectOrganizationFormInterface
) => {
  const { dropdownOptions, selectedOption, handleSelect, title } = props

  return (
    <>
      {dropdownOptions.length > 1 && (
        <SimpleDropdown
          styles={{ mt: 2, width: "100%" }}
          dropdownOptions={dropdownOptions.sort(function (a, b) {
            return sortByText(a.name, b.name)
          })}
          title={title}
          selectedOption={selectedOption}
          handleSelect={handleSelect}
        />
      )}
    </>
  )
}
