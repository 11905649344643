import { useState } from "react"
import { SxProps } from "@mui/material"

import { ImportBookmarksTextAndButton } from "./ImportBookmarksTextAndButton"
import { ShowTableButton } from "./ShowTableButton"
import { ImportTable } from "./ImportTable"

interface ImportBookmarksRowInterface {
  textStyle: SxProps
}

export const ImportBookmarksRow = (props: ImportBookmarksRowInterface) => {
  const { textStyle } = props
  const [showTable, setShowTable] = useState(false)

  return (
    <>
      <ImportBookmarksTextAndButton textStyle={textStyle} />
      {showTable ? (
        <ImportTable textStyle={textStyle} setShowTable={setShowTable} />
      ) : (
        <ShowTableButton textStyle={textStyle} setShowTable={setShowTable} />
      )}
    </>
  )
}
