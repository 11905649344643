import { useContext, useRef } from "react"
import { useMutation } from "@apollo/client"
import { gql } from "@apollo/client"
import styles from "./CustomNode.module.css"

// mui
import { IconButton } from "@mui/material"
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder"
import FavoriteIcon from "@mui/icons-material/Favorite"

import { LinkInterface } from "interfaces/Link"
import { createUserLinkFavoriteMutation, deleteUserLinkFavoriteMutation } from "queries/queries"
import { SnackBarContext } from "contexts/SnackBarContext"
import { SNACK_BAR_TYPES } from "components/SnackBar/SnackBarTypes"
import { debounceFunction } from "Utils/Utils"
import { DEBOUNCE_TIME } from "constants/Global"
import { FAVORITE_COLOR } from "constants/Global"
interface LinkDropdownPropsInterface {
  userLinkFavorites: LinkInterface["userLinkFavorites"]
  linkId: LinkInterface["id"]
  linkTitle: LinkInterface["title"]
}

export const ToggleFavorite = (props: LinkDropdownPropsInterface) => {
  const { userLinkFavorites, linkId, linkTitle } = props
  const timeout = useRef<any>()

  const [addUserLinkFavorite, addUserLinkFavoriteRef] = useMutation(createUserLinkFavoriteMutation, {
    update(cache, { data: { createUserLinkFavorite } }) {
      cache.modify({
        id: `Link:${linkId}`,
        fields: {
          userLinkFavorites(existingUserLinkFavorites = [], { readField }) {
            const newUserLinkFavoriteRef = cache.writeFragment({
              data: createUserLinkFavorite,
              fragment: gql`
                fragment NewUserLinkFavorite on Link {
                  id
                  linkId
                }
              `,
            })
            return [...existingUserLinkFavorites, newUserLinkFavoriteRef]
          },
        },
      })
    },
  })

  const [deleteUserLinkFavorite, deleteUserLinkFavoriteRef] = useMutation(deleteUserLinkFavoriteMutation, {
    update(cache, { data: { deleteUserLinkFavorite } }) {
      cache.evict({ id: cache.identify(deleteUserLinkFavorite) })
    },
  })

  const { setSnackBarState } = useContext(SnackBarContext)

  const addToFavorites = () => {
    addUserLinkFavorite({
      variables: {
        linkId: linkId,
      },
      // optimisticResponse: {
      //   createUserLinkFavorite: {
      //     id: `temp-favorite-link${linkId}`,
      //     __typename: "UserLinkFavorite",
      //     linkId: linkId,
      //   },
      // },
    }).then(
      () => {},
      (res: any) => {
        if (res?.message) {
          setSnackBarState({
            isOpen: true,
            snackBarType: SNACK_BAR_TYPES.ERROR,
            message: res.message,
          })
        } else {
          setSnackBarState({
            isOpen: true,
            snackBarType: SNACK_BAR_TYPES.ERROR,
            message: "Failed to add favorite - something went wrong",
          })
        }
      }
    )
  }

  const removeFromFavorites = () => {
    deleteUserLinkFavorite({
      variables: {
        userLinkFavoriteId: userLinkFavorites?.[0]?.id,
      },
    }).then(
      () => {},
      (res: any) => {
        if (res?.message) {
          setSnackBarState({
            isOpen: true,
            snackBarType: SNACK_BAR_TYPES.ERROR,
            message: res.message,
          })
        } else {
          setSnackBarState({
            isOpen: true,
            snackBarType: SNACK_BAR_TYPES.ERROR,
            message: "Failed to add favorite - something went wrong",
          })
        }
      }
    )
  }

  const debouncedAddToFavorites = () => {
    debounceFunction(timeout, addToFavorites, DEBOUNCE_TIME)
  }

  const debouncedRemoveFromFavorites = () => {
    debounceFunction(timeout, removeFromFavorites, DEBOUNCE_TIME)
  }

  const ariaLabelId = () => {
    return `favorite-link-${linkTitle}`
  }

  const isFavorite = () => {
    return userLinkFavorites?.length !== 0
  }

  const determineIcon = () => {
    if (isFavorite()) {
      return <FavoriteIcon sx={{ color: FAVORITE_COLOR }} />
    } else {
      return <FavoriteBorderIcon />
    }
  }

  const toggleFavorite = () => {
    if (isFavorite()) {
      debouncedRemoveFromFavorites()
    } else {
      debouncedAddToFavorites()
    }
  }

  const determineClassName = () => {
    if (isFavorite() || deleteUserLinkFavoriteRef?.loading || addUserLinkFavoriteRef?.loading) {
      return ""
    } else {
      return `${styles.searchResultChild} }`
    }
  }

  return (
    <>
      <IconButton
        className={determineClassName()}
        disabled={deleteUserLinkFavoriteRef?.loading || addUserLinkFavoriteRef?.loading}
        id={ariaLabelId()}
        onClick={toggleFavorite}
        aria-label={`Toggle favorite link ${linkTitle}`}
      >
        {determineIcon()}
      </IconButton>
    </>
  )
}
