import { gql } from "@apollo/client"

// Links -----------------------------------------------------------------

export const getPaginatedLinksQuery = gql`
  query Link($offset: Int, $limit: Int) {
    links(offset: $offset, limit: $limit) {
      id
      url
      description
      title
      organization {
        id
        name
      }
      category {
        id
        title
        description
      }
      tags {
        id
        title
        description
      }
      userLinkFavorites {
        id
        linkId
      }
    }
    totalLinks
  }
`

export const getLinksQuery = gql`
  {
    links {
      id
      url
      description
      title
      organization {
        id
        name
      }
      category {
        id
        title
        description
      }
      tags {
        id
        title
      }
    }
  }
`

export const createLinkMutation = gql`
  mutation CreateLink(
    $url: String!
    $title: String!
    $description: String
    $categoryId: String
    $tagIds: [String!]!
    $organizationId: String
  ) {
    createLink(
      input: {
        url: $url
        title: $title
        description: $description
        categoryId: $categoryId
        tagIds: $tagIds
        organizationId: $organizationId
      }
    ) {
      id
      url
      description
      title
      organization {
        id
        name
      }
      category {
        id
        title
        description
      }
      tags {
        id
        title
        description
      }
      userLinkFavorites {
        id
        linkId
      }
    }
  }
`

export const writeLinkQuery = gql`
  fragment MyLink on Link {
    id
    url
    description
    title
    organization {
      id
      name
    }
    category {
      id
      title
      description
    }
    tags {
      id
      title
      description
    }
    userLinkFavorites {
      id
      linkId
    }
  }
`

export const editLinkMutation = gql`
  mutation UpdateLink($id: ID!, $url: String!, $title: String!, $description: String, $categoryId: String) {
    updateLink(input: { id: $id, url: $url, title: $title, description: $description, categoryId: $categoryId }) {
      id
      url
      description
      title
      organization {
        id
        name
      }
      category {
        id
        title
        description
      }
      tags {
        id
        title
        description
      }
      userLinkFavorites {
        id
        linkId
      }
    }
  }
`

export const softDeleteLinkMutation = gql`
  mutation SoftDeleteLink($id: ID!) {
    softDeleteLink(input: { id: $id }) {
      id
    }
  }
`

export const deleteLinkMutation = gql`
  mutation DeleteLink($id: ID!) {
    deleteLink(input: { id: $id }) {
      id
    }
  }
`

export const viewLinkMutation = gql`
  mutation ViewLink($id: ID!) {
    viewLink(input: { id: $id }) {
      id
      views
    }
  }
`

export const getOpenGraphDataQuery = gql`
  query OpenGraphData($url: String!) {
    openGraphData(url: $url) {
      title
      description
    }
  }
`

// Categories -----------------------------------------------------------------

export const getCategoriesQuery = gql`
  {
    categories {
      id
      title
      description
      organization {
        id
        name
      }
      folder {
        id
        title
      }
      pin {
        id
      }
    }
  }
`

export const createCategoryMutation = gql`
  mutation CreateCategory($title: String!, $organizationId: String, $description: String, $folderId: ID) {
    createCategory(
      input: { title: $title, organizationId: $organizationId, description: $description, folderId: $folderId }
    ) {
      id
      title
      description
      organization {
        id
        name
      }
      folder {
        id
        title
      }
      pin {
        id
      }
    }
  }
`

export const updateCategoryMutation = gql`
  mutation UpdateCategory($id: ID!, $title: String!, $description: String, $folderId: ID) {
    updateCategory(input: { id: $id, title: $title, description: $description, folderId: $folderId }) {
      id
      title
      description
      organization {
        id
        name
      }
      folder {
        id
        title
      }
      pin {
        id
      }
    }
  }
`

export const softDeleteCategoryMutation = gql`
  mutation SoftDeleteCategory($id: ID!) {
    softDeleteCategory(input: { id: $id }) {
      id
    }
  }
`

export const deleteCategoryMutation = gql`
  mutation DeleteCategory($id: ID!) {
    deleteCategory(input: { id: $id }) {
      id
    }
  }
`

export const writeCategoryQuery = gql`
  fragment MyCategory on Category {
    id
    title
    description
    organization {
      id
      name
    }
    folder {
      id
      title
    }
    pin {
      id
    }
  }
`

// Tags -----------------------------------------------------------------

export const getTagsQuery = gql`
  {
    tags {
      id
      title
      description
      organization {
        id
        name
      }
      folder {
        id
        title
      }
      pin {
        id
      }
    }
  }
`

export const createTagMutation = gql`
  mutation CreateTag($title: String!, $organizationId: String, $description: String, $folderId: ID) {
    createTag(
      input: { title: $title, organizationId: $organizationId, description: $description, folderId: $folderId }
    ) {
      id
      title
      description
      organization {
        id
        name
      }
      folder {
        id
        title
      }
      pin {
        id
      }
    }
  }
`

export const updateTagMutation = gql`
  mutation UpdateTag($id: ID!, $title: String!, $description: String, $folderId: ID) {
    updateTag(input: { id: $id, title: $title, description: $description, folderId: $folderId }) {
      id
      title
      description
      organization {
        id
        name
      }
      folder {
        id
        title
      }
      pin {
        id
      }
    }
  }
`

export const softDeleteTagMutation = gql`
  mutation SoftDeleteTag($id: ID!) {
    softDeleteTag(input: { id: $id }) {
      id
    }
  }
`

export const writeTagQuery = gql`
  fragment MyTag on Tag {
    id
    title
    description
    organization {
      id
      name
    }
    folder {
      id
      title
    }
    pin {
      id
    }
  }
`

export const deleteTagMutation = gql`
  mutation DeleteTag($id: ID!) {
    deleteTag(input: { id: $id }) {
      id
    }
  }
`

export const createTagLinkMutation = gql`
  mutation CreateTagLink($tagId: ID!, $linkId: ID!) {
    createTagLink(input: { tagId: $tagId, linkId: $linkId }) {
      id
      url
      description
      title
      organization {
        id
        name
      }
      category {
        id
        title
        description
      }
      tags {
        id
        title
      }
    }
  }
`

export const softDeleteTagLinkMutation = gql`
  mutation SoftDeleteTagLink($linkId: ID!, $tagId: ID!) {
    softDeleteTagLink(input: { linkId: $linkId, tagId: $tagId }) {
      id
    }
  }
`

export const deleteTagLinkMutation = gql`
  mutation DeleteTagLink($linkId: ID!, $tagId: ID!) {
    deleteTagLink(input: { linkId: $linkId, tagId: $tagId }) {
      id
      url
      description
      title
      category {
        id
        title
        description
      }
      tags {
        id
        title
      }
    }
  }
`

// Folders -----------------------------------------------------------------

export const getFoldersQuery = gql`
  {
    folders {
      id
      parentFolderId
      parentFolderTitle
      title
      description
      folderType
      organization {
        id
        name
      }
    }
  }
`

export const createFolderMutation = gql`
  mutation CreateFolder(
    $title: String!
    $folderType: String!
    $parentFolderId: ID
    $description: String
    $organizationId: String
  ) {
    createFolder(
      input: {
        parentFolderId: $parentFolderId
        title: $title
        description: $description
        folderType: $folderType
        organizationId: $organizationId
      }
    ) {
      id
      parentFolderId
      parentFolderTitle
      title
      description
      folderType
      organization {
        id
        name
      }
    }
  }
`

export const updateFolderMutation = gql`
  mutation UpdateFolder($id: ID!, $parentFolderId: ID, $title: String, $description: String) {
    updateFolder(input: { id: $id, parentFolderId: $parentFolderId, title: $title, description: $description }) {
      id
      parentFolderId
      parentFolderTitle
      title
      description
      folderType
      organization {
        id
        name
      }
    }
  }
`

export const deleteFolderMutation = gql`
  mutation DeleteFolder($id: ID!) {
    deleteFolder(input: { id: $id }) {
      id
      title
      parentFolderId
    }
  }
`

export const writeFolderQuery = gql`
  fragment MyFolder on Folder {
    id
    parentFolderId
    parentFolderTitle
    title
    description
    folderType
    organization {
      id
      name
    }
  }
`

// Folder Items -------------------------------------------------------------

// returns a category
export const createOrUpdateCategoryFolderItemMutation = gql`
  mutation CreateOrUpdateCategoryFolderItem($itemId: ID!, $itemType: String!, $folderId: ID!) {
    createOrUpdateCategoryFolderItem(input: { itemId: $itemId, itemType: $itemType, folderId: $folderId }) {
      id
      title
      description
      folder {
        id
        title
      }
    }
  }
`

export const createOrUpdateTagFolderItemMutation = gql`
  mutation CreateOrUpdateTagFolderItem($itemId: ID!, $itemType: String!, $folderId: ID!) {
    createOrUpdateTagFolderItem(input: { itemId: $itemId, itemType: $itemType, folderId: $folderId }) {
      id
      title
      description
      folder {
        id
        title
      }
    }
  }
`

// returns category
export const deleteCategoryFolderItemMutation = gql`
  mutation DeleteCategoryFolderItem($itemId: ID!, $itemType: String!) {
    deleteCategoryFolderItem(input: { itemId: $itemId, itemType: $itemType }) {
      id
      title
      description
      folder {
        id
        title
      }
    }
  }
`

export const deleteTagFolderItemMutation = gql`
  mutation DeleteTagFolderItem($itemId: ID!, $itemType: String!) {
    deleteTagFolderItem(input: { itemId: $itemId, itemType: $itemType }) {
      id
      title
      description
      folder {
        id
        title
      }
    }
  }
`

// Users -----------------------------------------------------------------

export const updateUserPasswordMutation = gql`
  mutation UpdateUserPassword($password: String!, $newPassword: String!) {
    updateUserPassword(input: { password: $password, newPassword: $newPassword }) {
      id
    }
  }
`

export const updateUserEmailMutation = gql`
  mutation UpdateUserEmail($password: String!, $newEmail: String!) {
    updateUserEmail(input: { password: $password, newEmail: $newEmail }) {
      id
    }
  }
`

export const verifyEmailMutation = gql`
  mutation VerifyEmail($code: String!) {
    verifyEmail(input: { code: $code }) {
      id
    }
  }
`

export const resendVerifyEmailMutation = gql`
  mutation ResendVerifyEmail {
    resendVerifyEmail(input: {}) {
      id
    }
  }
`

export const getActiveUserQuery = gql`
  {
    activeUser {
      id
      email
      emailVerified
      secondFactorEnabled
      isPro
      hasActiveSubscription
    }
  }
`

export const getActiveUserWithResourcesQuery = gql`
  {
    activeUser {
      id
      email
      emailVerified
      secondFactorEnabled
      isPro
      linkCount
      categoryCount
      tagCount
      folderCount
      remainingAiCredits
      hasActiveSubscription
      maxLinkCount
      maxCategoryCount
      maxTagCount
      maxFolderCount
    }
  }
`

export const getFileUploadsQuery = gql`
  {
    fileUploads {
      userFriendlyState
      errorMessage
      createdAt
    }
  }
`

// Organizations -----------------------------------------------------------------

export const getOrganizationsQuery = gql`
  {
    organizations {
      id
      name
      userOrganizationState
    }
  }
`

export const getOrganizationQuery = gql`
  query Organization($id: String!) {
    organization(id: $id) {
      id
      name
    }
  }
`

export const getOrganizationWithResourceCountsQuery = gql`
  query Organization($id: String!) {
    organization(id: $id) {
      id
      name
      linkCount
      categoryCount
      tagCount
      folderCount
      hasActiveSubscription
      maxLinkCount
      maxCategoryCount
      maxTagCount
      maxFolderCount
    }
  }
`

export const writeOrganizationQuery = gql`
  fragment MyOrganization on Organization {
    id
    name
  }
`

export const createOrganizationMutation = gql`
  mutation CreateOrganization($name: String!) {
    createOrganization(input: { name: $name }) {
      id
      name
    }
  }
`

export const deleteUserOrganizationMutation = gql`
  mutation DeleteUserOrganization($organizationId: String!, $userId: ID!) {
    deleteUserOrganization(input: { organizationId: $organizationId, userId: $userId }) {
      id
      email
      userId
      organizationId
    }
  }
`

export const getUserOrganizationQuery = gql`
  query UserOrganization($id: String!) {
    userOrganization(id: $id) {
      id
      email
      userId
      disabledNoSeatAt
      organizationId
      organizationUserRoles {
        id
        userId
        organizationRoleId
        userFacingName
      }
    }
  }
`

export const getOrganizationRolesQuery = gql`
  query OrganizationRoles($organizationId: String!) {
    organizationRoles(organizationId: $organizationId) {
      id
      name
      userFacingName
      organizationId
    }
  }
`

export const getPaginatedUserOrganizationsQuery = gql`
  query UserOrganizations($id: String!, $limit: Int!, $offset: Int!, $email: String) {
    userOrganizations(id: $id, limit: $limit, offset: $offset, email: $email) {
      id
      email
      userId
      organizationId
      disabledNoSeatAt
      organizationUserRoles {
        id
        userId
        organizationRoleId
        userFacingName
      }
    }
  }
`
// totalUserOrganizations(id: $id, email: $email)

export const getTotalUserOrganizationsQuery = gql`
  query TotalUserOrganizations($id: String!, $email: String) {
    totalUserOrganizations(id: $id, email: $email)
  }
`

export const createOrganizationUserRoleMutation = gql`
  mutation CreateOrganizationUserRole($userId: ID!, $organizationRoleId: String!) {
    createOrganizationUserRole(input: { userId: $userId, organizationRoleId: $organizationRoleId }) {
      id
      userId
      organizationRoleId
      userFacingName
    }
  }
`

export const writeOrganizationUserRoleQuery = gql`
  fragment NewOrganizationUserRole on OrganizationUserRole {
    id
    userId
    organizationRoleId
    userFacingName
  }
`

export const deleteOrganizationUserRoleMutation = gql`
  mutation DeleteOrganizationUserRole($id: String!) {
    deleteOrganizationUserRole(input: { id: $id }) {
      id
      userId
      organizationRoleId
      userFacingName
    }
  }
`

export const createOrganizationInviteMutation = gql`
  mutation CreateOrganizationInvite($organizationId: String!, $rule: String!, $value: String!) {
    createOrganizationInvite(input: { organizationId: $organizationId, rule: $rule, value: $value }) {
      id
      code
      rule
      value
      expiresAt
    }
  }
`

export const createUserOrganizationMutation = gql`
  mutation CreateUserOrganization($organizationId: String!, $code: String!) {
    createUserOrganization(input: { organizationId: $organizationId, code: $code }) {
      id
    }
  }
`

export const getStripeSubscriptionForOrganizationQuery = gql`
  query StripeSubscriptionForOrganization($organizationId: String!) {
    stripeSubscriptionForOrganization(organizationId: $organizationId) {
      id
    }
  }
`

export const getOrganizationSeatCounts = gql`
  query OrganizationSeatCounts($organizationId: String!) {
    organizationSeatCounts(organizationId: $organizationId) {
      id
      numberOfSeats
      numberOfUsedSeats
      numberOfAvailableSeats
      hasAvailableSeats
    }
  }
`

export const getAiCategoriesAndTagsQuery = gql`
  query AiCategoryAndTags($url: String!) {
    aiCategoryAndTags(url: $url) {
      categories
      tags
    }
  }
`

export const getUserSettingsQuery = gql`
  query UserSettings {
    userSettings {
      id
      settingType
      settingValue
    }
  }
`

export const createUserSettingMutation = gql`
  mutation CreateUserSetting($settingValue: String!, $settingType: String!) {
    createUserSetting(input: { settingValue: $settingValue, settingType: $settingType }) {
      id
      settingValue
      settingType
    }
  }
`

export const updateUserSettingMutation = gql`
  mutation UpdateUserSetting($id: ID!, $settingValue: String!, $settingType: String!) {
    updateUserSetting(input: { id: $id, settingValue: $settingValue, settingType: $settingType }) {
      id
      settingValue
      settingType
    }
  }
`

export const writeUserSettingQuery = gql`
  fragment MyUserSetting on UserSetting {
    id
    settingValue
    settingType
  }
`

export const createUserLinkFavoriteMutation = gql`
  mutation CreateUserLinkFavorite($linkId: ID!) {
    createUserLinkFavorite(input: { linkId: $linkId }) {
      id
      linkId
    }
  }
`

export const deleteUserLinkFavoriteMutation = gql`
  mutation DeleteUserLinkFavorite($userLinkFavoriteId: ID!) {
    deleteUserLinkFavorite(input: { userLinkFavoriteId: $userLinkFavoriteId }) {
      id
      linkId
    }
  }
`

export const createPinMutation = gql`
  mutation CreatePin($itemableId: ID!, $itemableType: String!) {
    createPin(input: { itemableId: $itemableId, itemableType: $itemableType }) {
      id
      itemableId
      itemableType
    }
  }
`

export const deletePinMutation = gql`
  mutation DeletePin($id: ID!) {
    deletePin(input: { id: $id }) {
      id
    }
  }
`
