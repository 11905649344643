import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"

interface CloseModalButtonProps {
  closeModal: () => void
}

export const CloseModalButton = (props: CloseModalButtonProps) => {
  const { closeModal } = props
  return (
    <Box sx={{}}>
      <IconButton aria-label="close" onClick={closeModal} color="warning" sx={{ float: "right" }}>
        <CloseIcon />
      </IconButton>
    </Box>
  )
}
