import React from "react"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Link from "@mui/material/Link"

export const AiGuidelinesPage = () => {
  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" id="ai-guidelines">
        AI Guidelines
      </Typography>

      <Typography>
        This page outlines practices for utilizing AI, details about Linkidex's AI implementation 
        (including our third-party providers and associated policies), and resources on AI safety. 
      </Typography>
      <br />
      <br />
      <Typography variant='h5'>
        Using AI: Key Considerations
      </Typography>

      <Typography>
        We encourage you to explore and utilize our AI features, and to be mindful of common issues associated with 
        AI systems, whether within Linkidex or elsewhere:
        <br />
        <br />
        <strong>AI Output:</strong> Recognize that AI-generated output may occasionally be 
        unexpected or flawed. Due to the nature of AI, outputs could contain inaccuracies, 
        offensive content, biases, or misinformation. Users are accountable for any output 
        they utilize and should thoroughly review and validate it before usage. For 
        instance, if AI assists in generating categories and tags for one of your links, 
        ensure to review that the generated categories and tags are acceptable before using them.
        <br />
        <br />
        <strong>Data Handling:</strong> Exercise caution when providing input data, as 
        it will be shared with third-party AI providers for model training purposes.
        Refrain from giving confidential or sensitive information to any AI.
        <br />
        <br />
        <strong>Ownership and Transparency:</strong> Linkidex does not claim ownership 
        over content generated from using our AI features, but users should be aware that 
        generated content may bear similarities to existing materials, potentially 
        implicating trademark or copyright concerns. Transparent disclosure of AI assistance 
        in content creation is encouraged, particularly to prevent misinformation or confusion.
        <br />
        <br />
        <strong>Feedback:</strong> Your feedback is invaluable in refining and improving 
        our AI features. If you encounter any issues or have suggestions for enhancement,
        please reach out to us.
        <br />
        <br />
      </Typography>
      <Typography variant='h5'>
        AI at Linkidex: Partnerships and Usage Policies
      </Typography>
      <Typography>
        Our AI capabilities are facilitated by <Link href='https://openai.com/'>OpenAI</Link> Please 
        familiarize yourself with OpenAI's <Link href='https://openai.com/policies'>terms and policies</Link>, 
        which govern your usage of AI features on Linkidex. While Linkidex 
        does not utilize your input data for model training or service enhancement, be aware 
        that any data provided as input will be subject to OpenAI's <Link href="https://openai.com/enterprise-privacy">data usage policies</Link>.
      </Typography>
    </Box>
  )
}
