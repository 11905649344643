import React from "react"
import { useState, useEffect } from "react"

// MUI
import Box from "@mui/material/Box"
import Fade from "@mui/material/Fade"
import LinearProgress from "@mui/material/LinearProgress"

interface LoadingBarPropsInterface {
  totalLinkCount?: number
  loadedLinkCount?: number
}

export function LoadingBar(props: LoadingBarPropsInterface) {
  const { totalLinkCount, loadedLinkCount } = props
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const progressComplete = () => {
    if (loadedLinkCount && totalLinkCount) {
      return loadedLinkCount >= totalLinkCount // if we create a link without refreshing page, loadedLinkCount can end up greater than totalLinkCount
    }
    return false
  }

  useEffect(() => {
    const delay = progressComplete() ? 2000 : 500
    const handler = setTimeout(() => {
      setIsLoading(!progressComplete())
    }, delay)

    // Return a cleanup function that will be called every time useEffect is re-called. This is how we prevent
    // isLoading from changing if loadedLinkCount or loadedLinkCount change within the delay period. Timeout gets cleared and restarted.
    return () => {
      clearTimeout(handler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalLinkCount, loadedLinkCount])

  const calculateProgress = () => {
    if (loadedLinkCount && totalLinkCount) {
      return (loadedLinkCount / totalLinkCount) * 100
    }
    return 0
  }

  return (
    <>
      <Fade in={isLoading}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ width: "100%" }}>
            <LinearProgress variant="determinate" value={calculateProgress()} />
          </Box>
        </Box>
      </Fade>
    </>
  )
}
