import { useState, useContext, useRef } from "react"
import { useMutation, useQuery } from "@apollo/client"

// mui
import Paper from "@mui/material/Paper"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Typography from "@mui/material/Typography"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import Divider from "@mui/material/Divider"
import Button from "@mui/material/Button"
import FormHelperText from "@mui/material/FormHelperText"
import Alert from "@mui/material/Alert"
import IconButton from "@mui/material/IconButton"
import InputBase from "@mui/material/InputBase"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"

// components
import { CloseModalButton } from "components/Modal/CloseModalButton"
import { InputTextField } from "components/InputTextField"

// Contexts
import { ModalContext } from "contexts/ModalContext"
import { SnackBarContext } from "contexts/SnackBarContext"

// Utils
import { isValidEmail, debounceFunction, formatDateTime } from "Utils/Utils"

// TYPES
import { DEBOUNCE_TIME } from "constants/Global"
import { SNACK_BAR_TYPES } from "components/SnackBar/SnackBarTypes"

// Queries
import {
  createOrganizationInviteMutation,
  getOrganizationSeatCounts,
} from "queries/queries"

export const CreateOrganizationInviteModal = () => {
  const INVITE_THIS_DOMAIN = "this_domain"
  const INVITE_THIS_EMAIL = "this_email"
  const INVITE_ANY = "any"
  const timeout = useRef<any>()

  const [inviteType, setInviteType] = useState(INVITE_THIS_EMAIL)
  const [inviteRuleValue, setInviteRuleValue] = useState("")
  const [errorText, setErrorText] = useState("")
  const { modalState, setModalState } = useContext(ModalContext)
  const { setSnackBarState } = useContext(SnackBarContext)
  const [createOrganizationInvite] = useMutation(
    createOrganizationInviteMutation
  )
  const [createdInvite, setCreatedInvite] = useState<any>()

  const handleChange = (event: SelectChangeEvent) => {
    setInviteType(event.target.value as string)
  }

  const closeModal = () => {
    setModalState({ isOpen: false, modalType: "" })
  }

  const handleChangeRuleValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInviteRuleValue(event.target.value)
  }

  const determineInputHelperText = () =>
    inviteType === INVITE_THIS_DOMAIN ? "enter domain " : "enter email"

  const determineInputHelperSubText = () => {
    if (inviteType === INVITE_THIS_DOMAIN) {
      return "example: @linkidex.com"
    } else if (inviteType === INVITE_THIS_EMAIL) {
      return "example: david@linkidex.com"
    } else {
      return ""
    }
  }

  const getOrganizationSeatCountsResponse = useQuery(
    getOrganizationSeatCounts,
    {
      fetchPolicy: "network-only",
      variables: {
        organizationId: modalState.data.organizationId,
      },
    }
  )

  const publicInviteWarning = () => {
    if (inviteType === INVITE_ANY) {
      return (
        <Alert sx={{ my: 2 }} variant="outlined" severity="warning">
          <Typography>
            You are creating a public invite! Anyone who obtains this link will
            be able to join your Organization.
          </Typography>
        </Alert>
      )
    }
  }

  const isSubmitDisabled = () => {
    if (
      inviteType === INVITE_THIS_DOMAIN &&
      (!inviteRuleValue || inviteRuleValue[0] !== "@")
    ) {
      return true
    } else if (
      inviteType === INVITE_THIS_EMAIL &&
      (!inviteRuleValue || !isValidEmail(inviteRuleValue))
    ) {
      return true
    }
    return false
  }

  const debouncedHandleCreateInvite = () => {
    debounceFunction(timeout, handleCreateInvite, DEBOUNCE_TIME)
  }

  const handleCreateInvite = () => {
    setErrorText(" ")
    createOrganizationInvite({
      variables: {
        organizationId: modalState.data.organizationId,
        rule: inviteType,
        value: inviteRuleValue,
      },
    }).then(
      (res: any) => {
        setSnackBarState({
          isOpen: true,
          snackBarType: SNACK_BAR_TYPES.SUCCESS,
          message: "Invite created successfully",
        })
        setCreatedInvite(res?.data?.createOrganizationInvite)
      },
      (res: any) => {
        if (res && res.error) {
          setErrorText(res.error)
        } else {
          setErrorText("Something went wrong, please try again.")
        }
      }
    )
  }

  const copyInviteUrl = () => {
    setSnackBarState({
      isOpen: true,
      snackBarType: SNACK_BAR_TYPES.SUCCESS,
      message: `Invite copied to clipboard!`,
    })
    navigator.clipboard.writeText(buildInviteUrl())
  }

  const buildInviteUrl = () =>
    `https://www.linkidex.com/join_org/${modalState.data.organizationId}/${createdInvite.code}`

  const whoCanUseInvite = () => {
    if (createdInvite.rule === INVITE_ANY) {
      return "Anyone"
    } else if (createdInvite.rule === INVITE_THIS_EMAIL) {
      return `Only ${createdInvite.value}`
    } else if (createdInvite.rule === INVITE_THIS_DOMAIN) {
      return `Anyone with a verified email from ${createdInvite.value}`
    }
  }

  const displayInvite = () => {
    if (!createdInvite) {
      return null
    }
    return (
      <>
        <Typography textAlign="center" variant="h6" sx={{ my: 2 }}>
          Your Invite Code:
        </Typography>

        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton
            sx={{ p: "10px" }}
            aria-label="menu"
            onClick={copyInviteUrl}
          >
            <ContentCopyIcon />
          </IconButton>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Invite Url"
            value={buildInviteUrl()}
            inputProps={{ "aria-label": "invite url" }}
          />
        </Paper>
        <Typography sx={{ my: 2 }}>
          This inivte expires on{` ${formatDateTime(createdInvite.expiresAt)}.`}{" "}
          {whoCanUseInvite()} may use this invite.
        </Typography>
      </>
    )
  }

  const fullOrgAlert = () => {
    // check if getOrganizationSeatCountsResponse exists first to prevent alert appearing before we have gotten response back from BE
    if (
      getOrganizationSeatCountsResponse?.data &&
      !getOrganizationSeatCountsResponse.data.organizationSeatCounts
        .hasAvailableSeats
    )
      return (
        <Alert sx={{ my: 1 }} variant="outlined" severity="error">
          <Typography>
            No more room in organization! Please upgrade or edit your
            subscription to add more seats
          </Typography>
        </Alert>
      )
  }

  return (
    <>
      <CloseModalButton closeModal={closeModal} />
      <Typography textAlign="center" variant="h4" sx={{ my: 2 }}>
        Create Invite
      </Typography>
      {fullOrgAlert()}

      <Typography>
        You can make an invite link work for anyone who has the link, for anyone
        from a specific email domain (such as @linkidex.com), or for a specific
        email address.
      </Typography>

      <Divider sx={{ my: 2 }} />

      <Grid container>
        <Grid item xs={12} md={6}>
          <Box sx={{ width: "100%" }}>
            <FormControl sx={{ mt: 1 }} fullWidth>
              <InputLabel id="demo-simple-select-label">
                Invite Scope
              </InputLabel>
              <Select
                sx={{ ml: 1, width: "95%" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={inviteType}
                label="Invite Type"
                onChange={handleChange}
              >
                <MenuItem value={INVITE_ANY}>Anyone with this link</MenuItem>
                <MenuItem value={INVITE_THIS_DOMAIN}>
                  Anyone from this domain
                </MenuItem>
                <MenuItem value={INVITE_THIS_EMAIL}>
                  Someone with this email
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          {inviteType !== INVITE_ANY && (
            <>
              <InputTextField
                autoComplete={"off"}
                name="invite-rule"
                helperText={determineInputHelperText()}
                handleChange={handleChangeRuleValue}
                canToggleTextFieldVisibility={false}
                shouldShowTextField={true}
                handleClickShowTextField={() => {}}
                textField={inviteRuleValue || ""}
                error={false}
                errorText=""
              />
              <Typography variant="caption" sx={{ ml: 1 }}>
                {determineInputHelperSubText()}
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
      {publicInviteWarning()}

      <Grid container sx={{ mt: 5 }}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Button
              variant="outlined"
              color="warning"
              onClick={closeModal}
              sx={{ width: { xs: "100%", md: "auto" } }}
            >
              Cancel
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              mt: { xs: 2, md: 0 },
              display: "flex",
              flexDirection: { xs: "row", md: "row-reverse" },
            }}
          >
            <FormControl
              error={!!errorText}
              sx={{ width: { xs: "100%", md: "auto" } }}
            >
              <Button
                variant="contained"
                onClick={debouncedHandleCreateInvite}
                disabled={isSubmitDisabled()}
              >
                Create Invite
              </Button>
              <FormHelperText aria-live="polite">{errorText}</FormHelperText>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
      {displayInvite()}
    </>
  )
}
