// mui
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import FormControl from "@mui/material/FormControl"
import Divider from "@mui/material/Divider"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import IconButton from "@mui/material/IconButton"

// types
import { CredentialResponse } from "interfaces/Apis"

// utils
import { formatDateTime, supportsWebauthn } from "Utils/Utils"

interface ShowWebauthnCredentialsPropsInterface {
  closeModal: () => void
  setIsRegisterNewWebauthnCredentialOpen: (arg: boolean) => void
  webauthnCredentials: CredentialResponse
  debouncedHandleDeleteWebauthnCredential: (id: string) => void
}

export const ShowWebauthnCredentials = (
  props: ShowWebauthnCredentialsPropsInterface
) => {
  const {
    closeModal,
    setIsRegisterNewWebauthnCredentialOpen,
    webauthnCredentials,
    debouncedHandleDeleteWebauthnCredential,
  } = props

  const renderCredentials = () => {
    return webauthnCredentials.credentials.map((code, idx) => (
      <Box
        key={idx}
        sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
      >
        <IconButton
          sx={{}}
          aria-label="delete"
          onClick={() => {
            debouncedHandleDeleteWebauthnCredential(code.id)
          }}
        >
          <DeleteForeverIcon color="error" sx={{ verticalAlign: "middle" }} />
        </IconButton>

        <Typography key={idx} sx={{ textAlign: "center" }}>
          {code.nickname}, created {formatDateTime(code.created_at)}
        </Typography>
      </Box>
    ))
  }

  const modalText = () => {
    if (webauthnCredentials.credentials.length > 0) {
      return "These are your current Webauthn Credentials:"
    } else {
      return "No Webauthn Credentials registered. Press 'Add Credential to create one"
    }
  }

  return (
    <>
      <Typography sx={{ my: 1 }}>{modalText()}</Typography>
      <Divider sx={{ my: 1 }} />

      {renderCredentials()}

      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Button
              variant="outlined"
              color="warning"
              onClick={closeModal}
              sx={{ width: { xs: "100%", md: "auto" } }}
              aria-label={`Cancel`}
            >
              Close
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              mt: { xs: 2, md: 0 },
              display: "flex",
              flexDirection: { xs: "row", md: "row-reverse" },
            }}
          >
            <FormControl
              error={false}
              sx={{ width: { xs: "100%", md: "auto" } }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  setIsRegisterNewWebauthnCredentialOpen(true)
                }}
                disabled={!supportsWebauthn()}
                color="info"
                aria-label={`remove two factor`}
              >
                Add Credential
              </Button>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
