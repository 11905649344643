import { useContext } from "react"

// MUI
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import Link from "@mui/material/Link"
import Box from "@mui/material/Box"
import { SxProps } from "@mui/material"

// Contexts
import { SnackBarContext } from "contexts/SnackBarContext"

// Types
import { ActiveUserWithResourcesInterface } from "interfaces/User"
import { SNACK_BAR_TYPES } from "components/SnackBar/SnackBarTypes"

// Utils
import { ApiUtils } from "Utils/ApiUtils"

interface ResourceUsageRowInterface {
  textStyle: SxProps
  activeUser?: ActiveUserWithResourcesInterface
}

export const ResourceUsageRow = (props: ResourceUsageRowInterface) => {
  const { textStyle, activeUser } = props
  const { setSnackBarState } = useContext(SnackBarContext)

  const upgradeBlurb = () => {
    if (activeUser?.isPro && !activeUser?.hasActiveSubscription) {
      return (
        <Typography sx={textStyle} variant="subtitle2">
          Thank you for supporting Linkidex. Your account includes everything other than AI credits. Reach out to david
          or <Link href="/upgrade">Click here to upgrade your account.</Link>
        </Typography>
      )
    } else if (activeUser?.hasActiveSubscription) {
      return (
        <Typography sx={textStyle} variant="subtitle2">
          Thank you for supporting Linkidex.{" "}
          <Link
            sx={{
              cursor: "pointer",
            }}
            onClick={createStripePortalSession}
          >
            Click here to manage your subscription.
          </Link>
        </Typography>
      )
    } else {
      return (
        <Box sx={{ mb: 2 }}>
          <Typography sx={textStyle} variant="subtitle2">
            Need more storage on Linkidex? <Link href="/upgrade">Click here to upgrade your account.</Link>
          </Typography>
        </Box>
      )
    }
  }

  const createStripePortalSession = () => {
    if (!activeUser?.id) {
      return ""
    }
    ApiUtils.createCustomerPortalSession("User", activeUser.id).then(
      (res: any) => {
        window.open(res.url)
      },
      (res: any) => {
        setSnackBarState({
          isOpen: true,
          snackBarType: SNACK_BAR_TYPES.ERROR,
          message: res.error || "Something went wrong! Please try again, or reach out to help@linkidex.com",
        })
      }
    )
  }

  return (
    <>
      <Grid item sx={{ display: { marginTop: 20 } }} xs={12}>
        {upgradeBlurb()}
        <TableContainer sx={{ mt: 1 }} component={Paper}>
          <Table sx={{ minWidth: 300 }} size="small" aria-label="Uploads">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <Typography>Links</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>Categories</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>Tags</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>Folders</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>AI Credits</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={"orgResourceUseTable1"} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell align="center" component="th" scope="row">
                  <Typography>
                    {activeUser?.linkCount}/{activeUser?.maxLinkCount}
                  </Typography>
                </TableCell>
                <TableCell align="center" component="th" scope="row" sx={{}}>
                  <Typography>
                    {activeUser?.categoryCount} / {activeUser?.maxCategoryCount}
                  </Typography>
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                  <Typography>
                    {activeUser?.tagCount} / {activeUser?.maxTagCount}
                  </Typography>
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                  <Typography>
                    {activeUser?.folderCount} / {activeUser?.maxFolderCount}
                  </Typography>
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                  <Typography>{activeUser?.remainingAiCredits}</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  )
}
