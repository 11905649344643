import { useQuery } from "@apollo/client"
import { getOrganizationsQuery } from "queries/queries"
import { sortByText } from "Utils/Utils"

// mui
import Box from "@mui/material/Box"

// components
import { OrganizationCard } from "./OrganizationCard"
import { NoOrganizationCard } from "./NoOrganizationsCard"

// interfaces
import { OrganizationInterface } from "interfaces/Organization"

interface UserOrganizationsPropsInterface {
  isNotMobile: boolean
  toggleOrganization: (organizationId: string) => void
  isOrganizationChecked: (organizationId: string) => boolean | undefined
  isUserOnLinksPage: () => boolean
  clearSelectedTags: () => void
  clearSelectedCategories: () => void
  countOfSelectedCategories: () => number
  countOfSelectedTags: () => number
  toggleTag: (tagId: number) => void
  toggleCategory: (categoryId: number) => void
  isCategoryChecked: (categoryId: number) => boolean | undefined
  isTagChecked: (tagId: number) => boolean | undefined
}

export const UserOrganizations = (props: UserOrganizationsPropsInterface) => {
  const {
    isNotMobile,
    toggleOrganization,
    isOrganizationChecked,
    isUserOnLinksPage,
    clearSelectedTags,
    clearSelectedCategories,
    countOfSelectedCategories,
    countOfSelectedTags,
    toggleTag,
    toggleCategory,
    isCategoryChecked,
    isTagChecked,
  } = props

  const { data } = useQuery(getOrganizationsQuery)

  const buildOrgList = () => {
    if (!data?.organizations) {
      return null
    }

    // slice returns shallow copy of data.organizations, which is otherwise frozen.
    const sorted = data.organizations.slice().sort(function (a: OrganizationInterface, b: OrganizationInterface) {
      return sortByText(a.name, b.name)
    })

    const result = sorted.map((organization: OrganizationInterface, idx: number) => (
      <Box key={`org - card - wrapper - ${idx}`}>
        <OrganizationCard
          organization={organization}
          idx={idx}
          isNotMobile={isNotMobile}
          toggleOrganization={toggleOrganization}
          isOrganizationChecked={isOrganizationChecked}
          isUserOnLinksPage={isUserOnLinksPage}
          clearSelectedTags={clearSelectedTags}
          clearSelectedCategories={clearSelectedCategories}
          countOfSelectedCategories={countOfSelectedCategories}
          countOfSelectedTags={countOfSelectedTags}
          toggleTag={toggleTag}
          toggleCategory={toggleCategory}
          isCategoryChecked={isCategoryChecked}
          isTagChecked={isTagChecked}
        />
      </Box>
    ))
    return result
  }

  if (!data?.organizations || data.organizations.length === 0) {
    return <NoOrganizationCard />
  } else {
    return <div>{buildOrgList()}</div>
  }
}
