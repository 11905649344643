import * as React from "react"
import { useContext } from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Switch from "@mui/material/Switch"

// Contexts
import { LightDarkModeContext } from "contexts/LightDarkModeContext"

// Types
import { light, dark } from "constants/LightDarkMode"

interface LightDarkSliderProps {}
export function LightDarkSlider(props: LightDarkSliderProps) {
  const { currentModeState, updateCurrentModeState } =
    useContext(LightDarkModeContext)

  const isDarkModeEnabled = currentModeState.palette?.mode === "dark"

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        color: "text.primary",
        borderRadius: 1,
      }}
    >
      <Typography>Light Mode</Typography>

      <Switch
        checked={isDarkModeEnabled}
        onChange={(event) => {
          updateCurrentModeState(() => (isDarkModeEnabled ? light : dark))
        }}
        inputProps={{ "aria-label": "controlled" }}
      />
      <Typography>Dark Mode</Typography>
    </Box>
  )
}
