import React from "react"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Link from "@mui/material/Link"

export const CookiesPolicyPage = () => {
  return (
    <Box>
      <Typography>
        Our <Link href="/privacy_policy">Privacy Policy</Link> explains our
        principles when it comes to the category, processing, and storage of
        your information. This policy specifically explains how we, our
        partners, and users of our services deploy cookies, as well as the
        options you have to control them.
      </Typography>
      <Typography variant="h4" sx={{ mt: 2, mb: 2 }} id="what-are-cookies-">
        What are cookies?
      </Typography>
      <Typography>
        Cookies are small pieces of data, stored in text files, that are stored
        on your computer or other device when websites are loaded in a browser.
        They are widely used to &quot;remember&quot; you and your preferences,
        either for a single visit (through a &quot;session cookie&quot;) or for
        multiple repeat visits (using a &quot;persistent cookie&quot;). They
        ensure a consistent and efficient experience for visitors, and perform
        essential functions such as allowing users to register and remain logged
        in. Cookies may be set by the site that you are visiting (known as
        &quot;first party cookies&quot;), or by third parties, such as those who
        serve content or provide advertising or analytics services on the
        website (&quot;third party cookies&quot;). Both websites and HTML emails
        may also contain other tracking technologies such as &quot;web
        beacons&quot; or &quot;pixels.&quot; These are typically small
        transparent images that provide us with statistics, for similar purposes
        as cookies. They are often used in conjunction with cookies, though they
        are not stored on your computer in the same way. As a result, if you
        disable cookies, web beacons may still load, but their functionality
        will be restricted.
      </Typography>
      <Typography variant="h4" sx={{ mt: 2, mb: 2 }} id="-how-we-use-cookies-">
        <strong>How we use cookies</strong>
      </Typography>
      <Typography>
        We use cookies for a number of different purposes. Some cookies are
        necessary for technical reasons; some enable a personalized experience
        for both visitors and registered users; and some allow the display of
        advertising from selected third party networks. Some of these cookies
        may be set when a page is loaded, or when a visitor takes a particular
        action (clicking the &quot;like&quot; or &quot;follow&quot; button on a
        post, for example). Many of the cookies we use are only set if you are a
        registered Linkidex.com user (so you don’t have to log in every time,
        for example), while others are set whenever you visit one of our
        websites, irrespective of whether you have an account. For more
        information on the choices you have about the cookies we use, please see
        the Controlling Cookies section below.
      </Typography>
      <Typography
        variant="h4"
        sx={{ mt: 2, mb: 2 }}
        id="-where-we-place-cookies-"
      >
        <strong>Where we place cookies</strong>
      </Typography>
      <Typography>
        We set cookies in a number of different locations across our services.
        These include:
      </Typography>
      <ul>
        <li>
          <Typography>On our websites (including linkidex.com)</Typography>
        </li>
      </ul>
      <Typography variant="h4" sx={{ mt: 2, mb: 2 }} id="-types-of-cookie-">
        <strong>Types of Cookie</strong>
      </Typography>
      <Typography>
        The table below explains the types of cookies we use on our websites and
        why we use them.
      </Typography>
      <table>
        <thead>
          <tr>
            <th>
              <Typography>Category of cookies</Typography>
            </th>
            <th>
              <Typography>Why we use these cookies</Typography>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Typography>Required</Typography>
            </td>
            <td>
              <Typography>
                These cookies are essential for our websites and services to
                perform basic functions and are necessary for us to operate
                certain features. These include those required to allow
                registered users to authenticate and perform account-related
                functions, store preferences set by users such as account name,
                language, and location, and ensure our services are operating
                properly.
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography>Analytics and Performance</Typography>
            </td>
            <td>
              <Typography>
                These cookies allow us to optimize performance by collecting
                information on how users interact with our websites, including
                which pages are visited most, as well as other analytical data.
                We use these details to improve how our websites function and to
                understand how users interact with them.
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography>Advertising</Typography>
            </td>
            <td>
              <Typography>
                These cookies are set by us and our advertising partners to
                provide you with relevant content and to understand that
                content’s effectiveness. They may be used to collect information
                about your online activities over time and across different
                websites to predict your preferences and to display more
                relevant advertisements to you. These cookies also allow a
                profile to be built about you and your interests and enable
                personalized ads to be shown to you based on your profile.
              </Typography>
            </td>
          </tr>
        </tbody>
      </table>

      <Typography variant="h4" sx={{ mt: 2, mb: 2 }} id="controlling-cookies">
        Controlling Cookies
      </Typography>
      <Typography>
        Visitors may wish to restrict the use of cookies or completely prevent
        them from being set. Most browsers provide for ways to control cookie
        behavior such as the length of time they are stored – either through
        built-in functionality or by utilizing third party plugins. If you
        disable cookies, please be aware that some of the features of our
        service may not function correctly. To find out more on how to manage
        and delete cookies, visit aboutcookies.org. For more details on your
        choices regarding use of your web browsing activity for interest-based
        advertising visit{" "}
        <Link href="http://youronlinechoices.eu">youronlinechoices.eu</Link> (EU
        based) or{" "}
        <Link href="http://optout.aboutads.info">optout.aboutads.info</Link> (US
        based).{" "}
      </Typography>
      <Typography>
        On a mobile device, you may also be able to adjust your settings to
        limit tracking.
      </Typography>
      <Typography>
        Some analytics services we use, which place their own cookies or web
        tags on your browser, offer their own opt out choices. For example, you
        can opt out of Google Analytics by installing{" "}
        <Link href="https://tools.google.com/dlpage/gaoptout">
          Google’s opt-out browser add-on
        </Link>
        .
      </Typography>

      <Typography
        variant="h4"
        sx={{ mt: 2, mb: 2 }}
        id="consent-for-advertising-cookies-on-our-sites"
      >
        Consent for Advertising Cookies on Our Sites
      </Typography>
      <Typography>
        You may see a “cookie banner” on our websites and dashboards. If you are
        visiting one of our sites from the EU, then we do not set, or allow our
        ad partners to set, cookies that are used to show you targeted ads
        before you click to accept. When you consent in this manner, we and our
        advertising partners may set advertising cookies on the site you are
        visiting and on our other websites, dashboards, and services, which we
        use to show you advertisements about our various linkidex products.
        We’ll display the banner to you periodically, just in case you change
        your mind.
      </Typography>
      <Typography variant="h4" sx={{ mt: 2, mb: 2 }} id="contact-us">
        Contact Us
      </Typography>
      <Typography>
        If you have any questions about our use of cookies, you can find the
        most relevant contact details in our{" "}
        <Link href="/privacy_policy/">Privacy Policy</Link>. You may also email
        help@linkidex.com
      </Typography>
    </Box>
  )
}
