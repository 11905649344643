import React, { useContext } from "react"
import { Navigate, useLocation } from "react-router-dom"
import { Outlet } from "react-router"

// Utils
import { CurrentUserContext } from "../contexts/CurrentUserContext"

export const RecoveryRoutes = () => {
  const { currentUserState } = useContext(CurrentUserContext)
  const isLoggedIn = !!currentUserState.email

  const location = useLocation()

  if (!isLoggedIn) {
    return <Outlet />
  } else {
    return <Navigate to="/" replace state={{ from: location }} />
  }
}
