import * as React from "react"
import { createContext, useState, useEffect } from "react"

// Types
import { CurrentUserInterface } from "../interfaces/User"

// Utils
import { ApiUtils } from "../Utils/ApiUtils"

// current user is defaulted to isLoading: true so that when a user refreshes the front end we can differentiate
// between a user not being logged in vs a current user being logged in but not fetched yet from the react app.
const defaultState = { isLoading: true }

interface CreateUserContextInterface {
  currentUserState: CurrentUserInterface
  updateCurrentUserState: React.Dispatch<
    React.SetStateAction<CurrentUserInterface>
  >
}

export const CurrentUserContext = createContext<CreateUserContextInterface>({
  currentUserState: defaultState,
  updateCurrentUserState: () => {},
})

export const CurrentUserContextProvider = (props: {
  children: React.ReactNode
}) => {
  const [currentUserState, updateCurrentUserState] =
    useState<CurrentUserInterface>(defaultState)

  useEffect(() => {
    updateCurrentUserState({ isLoading: true })
    ApiUtils.getCurrentSession().then(updateCurrentUserState)
  }, [])

  return (
    <CurrentUserContext.Provider
      value={{
        currentUserState,
        updateCurrentUserState,
      }}
    >
      {props.children}
    </CurrentUserContext.Provider>
  )
}
