import React, { useState, useContext, useEffect, useRef } from "react"

// MUI Components
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import FormHelperText from "@mui/material/FormHelperText"
import FormControl from "@mui/material/FormControl"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import { useMutation } from "@apollo/client"

// Components
import { InputTextField } from "components/InputTextField"
import { CloseModalButton } from "components/Modal/CloseModalButton"

// Contexts
import { ModalContext } from "contexts/ModalContext"
import { SnackBarContext } from "contexts/SnackBarContext"

// Utils
import { SNACK_BAR_TYPES } from "components/SnackBar/SnackBarTypes"
import {
  writeOrganizationQuery,
  createOrganizationMutation,
} from "queries/queries"
import { debounceFunction } from "Utils/Utils"

// TYPES
import { DEBOUNCE_TIME } from "constants/Global"

interface CreateOrganizationModalStateInterface {
  name: string
}

interface CreateOrganizationModalPropsInterface {
  isControlledByContext?: boolean // is the modal controlled by modalContext or not
  handleClose?: () => void // function to close modal if it is not controlled by context
  callBack?: (Organization: any) => void // function called after successfully creating Organization
}

export const CreateOrganizationModal = (
  props: CreateOrganizationModalPropsInterface
) => {
  const { isControlledByContext, handleClose, callBack } = props
  const timeout = useRef<any>()
  const [nameError, setNameError] = useState<string>("")
  const [errorText, setErrorText] = useState<string>(" ")
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
  const [values, setValues] = useState<CreateOrganizationModalStateInterface>({
    name: "",
  })
  const { setModalState } = useContext(ModalContext)
  const { setSnackBarState } = useContext(SnackBarContext)

  const [addOrganization] = useMutation(createOrganizationMutation, {
    update(cache, { data: { createOrganization } }) {
      cache.modify({
        fields: {
          organizations(existingOrganizations = []) {
            const newOrganizationRef = cache.writeFragment({
              data: createOrganization,
              fragment: writeOrganizationQuery,
            })
            return [...existingOrganizations, newOrganizationRef]
          },
        },
      })
    },
  })

  const closeModal = () => {
    if (isControlledByContext !== false) {
      setModalState({ isOpen: false, modalType: "" })
    } else if (handleClose) {
      handleClose()
    }
  }

  const handleChange =
    (prop: keyof CreateOrganizationModalStateInterface) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value })
    }

  const handleCreateOrganization = () => {
    addOrganization({
      variables: {
        name: values.name,
      },
    }).then(
      (res: any) => {
        setSnackBarState({
          isOpen: true,
          snackBarType: SNACK_BAR_TYPES.SUCCESS,
          message: "Organization Created.",
        })
        if (callBack) {
          callBack(res.data.createOrganization)
        }
        closeModal()
      },
      (res: any) => {
        setNameError("")
        if (res && res.message) {
          setErrorText(res.message)
        } else {
          setErrorText("Something went wrong, please try again.")
        }
      }
    )
  }

  const debouncedHandleCreateOrganization = () => {
    debounceFunction(timeout, handleCreateOrganization, DEBOUNCE_TIME)
  }

  useEffect(() => {
    let tempTitleError = ""

    if (values.name.length < 3) {
      tempTitleError = "Title too short."
    }
    if (values.name.length > 64) {
      tempTitleError = "Title too long."
    }

    if (values.name.length === 0) {
      setIsSubmitDisabled(true)
    } else if (!tempTitleError) {
      setErrorText("")
      setNameError("")
      setIsSubmitDisabled(false)
    } else {
      setIsSubmitDisabled(true)
      setNameError(tempTitleError)
    }
  }, [values])

  useEffect(() => {
    const listener = (event: any) => {
      if (
        (event.code === "Enter" || event.code === "NumpadEnter") &&
        event.srcElement.id === "outlined-adornment-Organization-name"
      ) {
        event.preventDefault()
        debouncedHandleCreateOrganization()
      }
    }
    document.addEventListener("keydown", listener)
    return () => {
      document.removeEventListener("keydown", listener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  return (
    <>
      <CloseModalButton closeModal={closeModal} />
      <Typography textAlign="center" variant="h4" sx={{ my: 2 }}>
        Create Organization
      </Typography>

      <InputTextField
        autoFocus={true}
        autoComplete={"off"}
        name="Organization-name"
        helperText="Name*"
        handleChange={handleChange("name")}
        canToggleTextFieldVisibility={false}
        shouldShowTextField={true}
        handleClickShowTextField={() => {}}
        textField={values.name}
        error={!!nameError}
        errorText={nameError}
      />

      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Button
              variant="outlined"
              color="warning"
              onClick={closeModal}
              sx={{ width: { xs: "100%", md: "auto" } }}
            >
              Cancel
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              mt: { xs: 2, md: 0 },
              display: "flex",
              flexDirection: { xs: "row", md: "row-reverse" },
            }}
          >
            <FormControl
              error={!!errorText}
              sx={{ width: { xs: "100%", md: "auto" } }}
            >
              <Button
                variant="contained"
                onClick={debouncedHandleCreateOrganization}
                disabled={isSubmitDisabled}
              >
                Create Organization
              </Button>
              <FormHelperText aria-live="polite">{errorText}</FormHelperText>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
