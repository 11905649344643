import { useEffect, useState, useContext } from "react"
import { useMutation } from "@apollo/client"
import { useParams, useNavigate } from "react-router-dom"

// Contexts
import { SnackBarContext } from "contexts/SnackBarContext"

// MUI
import Typography from "@mui/material/Typography"
import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"

import { createUserOrganizationMutation } from "queries/queries"

// Types
import { SNACK_BAR_TYPES } from "components/SnackBar/SnackBarTypes"

export const JoinOrganization = () => {
  const [isVerifying, setIsVerifying] = useState<Boolean>(false)
  const [isError, setIsError] = useState<Boolean>(false)
  const [errorText, setErrorText] = useState<string | null>("")
  const [isVerified, setIsVerified] = useState<Boolean>(false)

  const { setSnackBarState } = useContext(SnackBarContext)
  const navigate = useNavigate()

  const [createUserOrganization] = useMutation(createUserOrganizationMutation)

  let { organization_id, invite_code } = useParams()

  useEffect(() => {
    if (organization_id && invite_code) {
      setIsVerifying(true)
      setIsError(false)
      createUserOrganization({
        variables: {
          organizationId: organization_id,
          code: invite_code,
        },
      }).then(
        () => {
          setIsVerifying(false)
          setIsError(false)
          setIsVerified(true)
          setErrorText(null)

          setSnackBarState({
            isOpen: true,
            snackBarType: SNACK_BAR_TYPES.SUCCESS,
            message: "You have joined the organization.",
          })
        },
        (res: any) => {
          setIsVerifying(false)
          setIsError(true)
          setErrorText(res.message)
          setIsVerified(false)

          if (res?.message) {
            setSnackBarState({
              isOpen: true,
              snackBarType: SNACK_BAR_TYPES.ERROR,
              message: res.message,
            })
          } else {
            setSnackBarState({
              isOpen: true,
              snackBarType: SNACK_BAR_TYPES.ERROR,
              message: "Failed to join Organization",
            })
          }
        }
      )
    } else {
      setIsVerifying(false)
      setIsError(true)
    }
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invite_code, organization_id])

  const isVerifyingMessage = () => {
    if (isVerifying) {
      return (
        <>
          <Typography sx={{ pt: 3 }} textAlign="center" variant="h4">
            Looking up your invite, please wait...
          </Typography>
          <Box justifyContent="center" alignItems="center" sx={{ pt: 5, display: "flex" }}>
            <CircularProgress />
          </Box>
        </>
      )
    }
  }

  const errorMessage = () => {
    if (errorText) {
      return errorText
    }
    return "Something went wrong. Please make sure you have the entire invite url and try again. If that doesn't work, please reach out to your organization admin for a new invite."
  }

  const isErrorMessage = () => {
    if (isError) {
      return (
        <>
          <Typography sx={{ pt: 3 }} textAlign="center" variant="h5">
            {errorMessage()}
          </Typography>
          <Box justifyContent="center" alignItems="center" sx={{ pt: 5, display: "flex" }}>
            <Button
              onClick={() => navigate("/links")}
              variant="outlined"
              color="success"
              sx={{ mt: { xs: 0, sm: 1.5 }, ml: { xs: 2, sm: 0 } }}
            >
              Back to Linkidex
            </Button>
          </Box>
        </>
      )
    }
  }

  const isVerifiedMessage = () => {
    if (isVerified) {
      return (
        <>
          <Typography sx={{ pt: 3 }} textAlign="center" variant="h5">
            You have joined the organization!
          </Typography>

          <Box justifyContent="center" alignItems="center" sx={{ pt: 5, display: "flex" }}>
            <Button
              onClick={() => navigate("/links")}
              variant="outlined"
              color="success"
              sx={{ mt: { xs: 0, sm: 1.5 }, ml: { xs: 2, sm: 0 } }}
            >
              Back to Linkidex
            </Button>
          </Box>
        </>
      )
    }
  }

  return (
    <>
      {isVerifyingMessage()}
      {isErrorMessage()}
      {isVerifiedMessage()}
    </>
  )
}
