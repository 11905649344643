import * as React from "react"
import { useContext } from "react"
import MenuItem from "@mui/material/MenuItem"
import Typography from "@mui/material/Typography"

// Contexts
import { LightDarkModeContext } from "contexts/LightDarkModeContext"

// Types
import { light, dark } from "constants/LightDarkMode"

interface LightDarkToggleProps {
  onClick: (event: React.MouseEvent<HTMLElement>) => void
}
export function LightDarkToggle(props: LightDarkToggleProps) {
  const { onClick } = props
  const { currentModeState, updateCurrentModeState } =
    useContext(LightDarkModeContext)

  const isDarkModeEnabled = currentModeState.palette?.mode === "dark"

  return (
    <MenuItem
      onClick={(event) => {
        onClick(event)
        updateCurrentModeState(() => (isDarkModeEnabled ? light : dark))
      }}
    >
      <Typography textAlign="center">
        {isDarkModeEnabled ? "Light Mode" : "Dark Mode"}
      </Typography>
    </MenuItem>
  )
}
