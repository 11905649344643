import React from "react"

// Components
import { PricingDisplay } from "../components/PricingDisplay"
import { FAQDisplay } from "components/FAQDisplay"
import { BottomLegalLinks } from "components/BottomLegalLinks"

export const PricingPage = () => {
  return (
    <>
      <PricingDisplay />
      <FAQDisplay />
      <BottomLegalLinks />
    </>
  )
}
