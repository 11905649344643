import Typography from "@mui/material/Typography"
import Link from "@mui/material/Link"
import Box from "@mui/material/Box"

export const PrivacyPolicyPage = () => {
  return (
    <Box sx={{ p: 4 }}>
      <Typography
        variant="h4"
        sx={{ mt: 2, mb: 2 }}
        id="who-we-are-and-what-this-policy-covers"
      >
        Who We Are and What This Privacy Policy Covers
      </Typography>
      <Typography>
        This Privacy Policy applies to information that we collect about you
        when you use:
      </Typography>
      <ul>
        <li>
          <Typography>Our websites (including Linkidex.com);</Typography>
        </li>
        <li>
          <Typography>Our mobile applications;</Typography>
        </li>
        <li>
          <Typography>
            Our other Linkidex products, services, and features that are
            available on or through our websites;
          </Typography>
        </li>
      </ul>
      <Typography>
        This Privacy Policy also applies to information we collect when you
        apply for a job at Linkidex. Throughout this Privacy Policy we will
        refer to our websites, mobile applications, and other products and
        services collectively as "Services." Below we explain how we collect,
        use, and share information about you, along with the choices that you
        have with respect to that information.
      </Typography>
      <Typography
        variant="h4"
        sx={{ mt: 2, mb: 2 }}
        id="information-we-collect"
      >
        Information We Collect
      </Typography>
      <Typography>
        We only collect information about you if we have a reason to do so — for
        example, to provide our Services, to communicate with you, or to make
        our Services better. We collect this information from three sources: if
        and when you provide information to us, automatically through operating
        our Services, and from outside sources. Let’s go over the information
        that we collect.
      </Typography>
      <Typography variant="h6" id="_information-you-provide-to-us_">
        <em>Information You Provide to Us</em>
      </Typography>
      <Typography>
        It’s probably no surprise that we collect information that you provide
        to us directly. Here are some examples:
      </Typography>
      <ul>
        <li>
          <Typography>
            <strong>Basic account information:</strong> We ask for basic
            information from you in order to set up your account. For example,
            we require individuals who sign up for a Linkidex.com account to
            provide an email address and password, along with a username or name
            — and that’s it. You may provide us with more information — like
            your address and other information you want to share — but we don’t
            require that information to create a Linkidex.com account.
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Public profile information:</strong> If you have an account
            with us, we collect the information that you provide for your public
            profile. For example, if you have a Linkidex.com account, your
            username is part of that public profile, along with any other
            information you put into your public profile, like a photo or an
            "About Me" description. Your public profile information is just that
            — public — so please keep that in mind when deciding what
            information you would like to include.
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Payment and contact information:</strong> There are various
            ways in which you may provide us payment information and associated
            contact information. For example, if you buy something from us
            you’ll provide additional personal and payment information like your
            name, credit card information, and contact information. We also keep
            a record of the purchases you’ve made.
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Business Profile:</strong> Some of our products collect
            additional information from you as part of creating a user/customer
            profile.
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Content information:</strong> You might provide us with
            information about you in draft and published content (a blog post or
            comment that includes biographic information about you, or any media
            or files you upload).
          </Typography>
        </li>

        <li>
          <Typography>
            <strong>Communications with us (hi there!):</strong> You may also
            provide us with information when you respond to surveys, communicate
            with our team about a support question, or sign up for a newsletter.
            When you communicate with us via form, email, phone, Linkidex.com
            comment, or otherwise, we store a copy of our communications
            (including any call recordings as permitted by applicable law).
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Job applicant information:</strong> If you apply for a job
            with us — awesome! You may provide us with information like your
            name, contact information, resume or CV, and work authorization
            verification as part of the application process.
          </Typography>
        </li>
      </ul>
      <Typography variant="h6" id="_information-we-collect-automatically_">
        <em>Information We Collect Automatically</em>
      </Typography>
      <Typography>We also collect some information automatically:</Typography>
      <ul>
        <li>
          <Typography>
            <strong>Log information:</strong> Like most online service
            providers, we collect information that web browsers, mobile devices,
            and servers typically make available, including the browser type, IP
            address, unique device identifiers, language preference, referring
            site, the date and time of access, operating system, and mobile
            network information. We collect log information when you use our
            Services.
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Transactional information:</strong> When you make a purchase
            through our Services, we collect information about the transaction,
            such as product details, purchase price, and the date and location
            of the transaction.
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Usage information:</strong> We collect information about
            your usage of our Services. For example, we collect information
            about the actions that site administrators and users perform on a
            site using our Linkidex.com — in other words, who did what and when
            (e.g., [Linkidex.com user_id deleted "[link_id]" at
            [time/date]). We also collect information about what happens when
            you use our Services (e.g., page views, interactions with parts of
            our Services) along with information about your device (e.g., screen
            size, name of cellular network, and mobile device manufacturer). We
            use this information to, for example, provide our Services to you,
            get insights on how people use our Services so we can make our
            Services better, and understand and make predictions about user
            retention.
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Location information:</strong> We may determine the
            approximate location of your device from your IP address. We collect
            and use this information to, for example, calculate how many people
            visit our Services from certain geographic regions. We may also
            collect information about your precise location via our mobile apps
            if you allow us to do so through your mobile device operating
            system’s permissions.
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Stored information:</strong> We may access information
            stored on your mobile device via our mobile apps. We access this
            stored information through your device operating system’s
            permissions. For example, if you give us permission to access the
            photographs on your mobile device’s camera roll, our Services may
            access the photos stored on your device when you upload a really
            amazing photograph of the sunrise to our website.
          </Typography>
        </li>

        <li>
          <Typography>
            <strong>Information from cookies &amp; other technologies:</strong>{" "}
            A cookie is a string of information that a website stores on a
            visitor’s computer, and that the visitor’s browser provides to the
            website each time the visitor returns. Pixel tags (also called web
            beacons) are small blocks of code placed on websites and emails.
            Linkidex uses cookies and other technologies like pixel tags to help
            us identify and track visitors, usage, and access preferences for
            our Services, as well as track and understand email campaign
            effectiveness. For more information about our use of cookies and
            other technologies for tracking, including how you can control the
            use of cookies, please see our{" "}
            <Link href="/cookies_policy">Cookie Policy</Link>.
          </Typography>
        </li>
      </ul>
      <Typography variant="h6" id="_information-we-collect-from-other-sources_">
        <em>Information We Collect from Other Sources</em>
      </Typography>
      <Typography>
        We may also get information about you from other sources. For example:{" "}
      </Typography>
      <ul>
        <li>
          <Typography>
            <strong>Third Party Login:</strong> If you create or log in to your
            Linkidex.com account through another service (like Google) we’ll
            receive associated login information (e.g. a connection token, your
            username, your email address)
          </Typography>
        </li>
      </ul>
      <Typography>
        The information we receive depends on which services you use or
        authorize and what options are available.{" "}
      </Typography>
      <Typography
        variant="h4"
        sx={{ mt: 2, mb: 2 }}
        id="how-and-why-we-use-information"
      >
        How and Why We Use Information
      </Typography>
      <Typography variant="h6" id="_purposes-for-using-information_">
        <em>Purposes for Using Information</em>
      </Typography>
      <Typography>
        We use information about you for the purposes listed below:
      </Typography>
      <ul>
        <li>
          <Typography>
            <strong>To provide our Services.</strong> For example, to set up and
            maintain your account, backup and restore your account, provide
            customer service, process payments and orders, and verify user
            information.
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>
              To ensure quality, maintain safety, and improve our Services.
            </strong>{" "}
            For example, by providing automatic upgrades and new versions of our
            Services. Or, for example, by monitoring and analyzing how users
            interact with our Services so we can create new features that we
            think our users will enjoy or make our Services easier to use.
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>
              To market our Services and measure, gauge, and improve the
              effectiveness of our marketing.
            </strong>{" "}
            For example, by targeting our marketing messages to groups of our
            users (like those who have a particular plan with us or have been
            users for a certain length of time), advertising our Services,
            analyzing the results of our marketing campaigns (like how many
            people purchased a paid plan after receiving a marketing message),
            and understanding and forecasting user retention.
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>To protect our Services, our users, and the public.</strong>{" "}
            For example, by detecting security incidents; detecting and
            protecting against malicious, deceptive, fraudulent, or illegal
            activity; fighting spam; complying with our legal obligations; and
            protecting the rights and property of Linkidex and others, which may
            result in us, for example, declining a transaction or terminating
            Services.
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>To fix problems with our Services.</strong> For example, by
            monitoring, debugging, repairing, and preventing issues.
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>To customize the user experience.</strong> For example, to
            personalize your experience by serving you relevant notifications
            and advertisements for our Services.
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>To communicate with you.</strong> For example, by emailing
            you to ask for your feedback, share tips for getting the most out of
            our products, or keep you up to date on Linkidex; If you don’t want
            to hear from us, you can opt out of marketing communications at any
            time. (If you opt out, we’ll still send you important updates
            relating to your account.)
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>To recruit and hire new team members.</strong> For example,
            by evaluating job applicants and communicating with them.
          </Typography>
        </li>
      </ul>
      <Typography
        variant="h6"
        id="_legal-bases-for-collecting-and-using-information_"
      >
        <em>Legal Bases for Collecting and Using Information</em>
      </Typography>
      <Typography>
        A note here for those in the European Union about our legal grounds for
        processing information about you under EU data protection laws, which is
        that our use of your information is based on the grounds that: (1) The
        use is necessary in order to fulfill our commitments to you under the
        applicable terms of service or other agreements with you or is necessary
        to administer your account — for example, in order to enable access to
        our website on your device or charge you for a paid plan; or (2) The use
        is necessary for compliance with a legal obligation; or (3) The use is
        necessary in order to protect your vital interests or those of another
        person; or (4) We have a legitimate interest in using your information —
        for example, to provide and update our Services; to improve our Services
        so that we can offer you an even better user experience; to safeguard
        our Services; to communicate with you; to measure, gauge, and improve
        the effectiveness of our advertising; and to understand our user
        retention and attrition; to monitor and prevent any problems with our
        Services; and to personalize your experience; or (5) You have given us
        your consent — for example before we place certain cookies on your
        device and access and analyze them later on, as described in our{" "}
        <Link href="/cookies_policy">Cookie Policy</Link>.
      </Typography>
      <Typography variant="h4" sx={{ mt: 2, mb: 2 }} id="sharing-information">
        Sharing Information
      </Typography>
      <Typography variant="h6" id="_how-we-share-information_">
        <em>How We Share Information</em>
      </Typography>
      <Typography>
        We share information about you in limited circumstances, and with
        appropriate safeguards on your privacy. These are spelled out below:
      </Typography>
      <ul>
        <li>
          <Typography>
            <strong>Subsidiaries and independent contractors:</strong> We may
            disclose information about you to our subsidiaries and independent
            contractors who need the information to help us provide our Services
            or process the information on our behalf. We require our
            subsidiaries and independent contractors to follow this Privacy
            Policy for any personal information that we share with them.
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Third-party vendors:</strong> We may share information about
            you with third-party vendors who need the information in order to
            provide their services to us, or to provide their services to you or
            your site. This includes vendors that help us provide our Services
            to you; those that assist us with our marketing efforts (e.g., by
            providing tools for identifying a specific marketing target group or
            improving our marketing campaigns, and by placing ads to market our
            services); those that help us understand and enhance our Services
            (like analytics providers); those that make tools to help us run our
            operations (like programs that help us with task management,
            scheduling, word processing, email and other communications, and
            collaboration among our teams); other third-party tools that help us
            manage operations; and companies that make products available on our
            websites, who may need information about you in order to, for
            example, provide technical or other support services to you. We
            require vendors to agree to privacy commitments in order to share
            information with them. Other vendors are listed in our more specific
            policies (e.g., our{" "}
            <Link href="/cookies_policy/">Cookie Policy</Link>
            ).
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Legal and regulatory requirements:</strong> We may disclose
            information about you in response to a subpoena, court order, or
            other governmental request .
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>To protect rights, property, and others:</strong> We may
            disclose information about you when we believe in good faith that
            disclosure is reasonably necessary to protect the property or rights
            of Linkidex, third parties, or the public at large. For example, if
            we have a good faith belief that there is an imminent danger of
            death or serious physical injury, we may disclose information
            related to the emergency without delay.
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Business transfers:</strong> In connection with any merger,
            sale of company assets, or acquisition of all or a portion of our
            business by another company, or in the unlikely event that Linkidex
            goes out of business or enters bankruptcy, user information would
            likely be one of the assets that is transferred or acquired by a
            third party. If any of these events were to happen, this Privacy
            Policy would continue to apply to your information and the party
            receiving your information may continue to use your information, but
            only consistent with this Privacy Policy.
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>With your consent:</strong> We may share and disclose
            information with your consent or at your direction. For example, we
            may share your information with third parties when you authorize us
            to do so, like when you connected your site to a social media
            service.
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Aggregated or de-identified information:</strong> We may
            share information that has been aggregated or de-identified, so that
            it can no longer reasonably be used to identify you. For instance,
            we may publish aggregate statistics about the use of our Services,
            or share a hashed version of your email address to facilitate
            customized ad campaigns on other platforms.
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Published support requests:</strong> If you send us a
            request for assistance (for example, via a support email or one of
            our other feedback mechanisms), we reserve the right to publish that
            request in order to clarify or respond to your request, or to help
            us support other users.
          </Typography>
        </li>
      </ul>
      <Typography>
        We do not sell our users&#39; data. We aren&#39;t a data broker, we
        don&#39;t sell your personal information to data brokers, and we
        don&#39;t sell your information to other companies that want to spam you
        with marketing emails. We may show adds on parts of our service, and the
        revenue they generate lets us offer free access to some of our Services
        so that money doesn’t become an obstacle
      </Typography>
      <Typography variant="h6" id="_information-shared-publicly_">
        <em>Information Shared Publicly</em>
      </Typography>
      <Typography>
        Information that you choose to make public is — you guessed it —
        disclosed publicly. That means information like your public profile,
        posts, other content that you make public on your account are all
        available to others — and we hope they get a lot of views! For example,
        the photo that you upload to your public profile, or a default image if
        you haven’t uploaded one, is your <strong>G</strong>lobally{" "}
        <strong>R</strong>ecognized Avatar, or Gravatar — get it? :) Your
        Gravatar, along with other public profile information, displays
        alongside the comments and "Likes" that you make on other users’
        websites while logged in to your Linkidex.com account. Your Gravatar and
        public profile information may also display with your comments, "Likes,"
        and other interactions on websites that use our Gravatar service, if the
        email address associated with your account is the same email address you
        use on the other website. Public information may also be indexed by
        search engines or used by third parties. Please keep all of this in mind
        when deciding what you would like to share publicly.
      </Typography>
      <Typography
        variant="h4"
        sx={{ mt: 2, mb: 2 }}
        id="how-long-we-keep-information"
      >
        How Long We Keep Information
      </Typography>
      <Typography>
        We generally discard information about you when it’s no longer needed
        for the purposes for which we collect and use it — described in the
        section above on How and Why We Use Information — and we’re not legally
        required to keep it. For example, we keep web server logs that record
        information about a visitor to one of Linkidex’s websites, like the
        visitor’s IP address, browser type, and operating system, for
        approximately 30 days. We retain the logs for this period of time in
        order to, among other things, analyze traffic to Linkidex’s websites and
        investigate issues if something goes wrong on one of our websites. As
        another example, when you delete a link, tag, or category from your
        Linkidex.com site, it may stay in your Trash folder for thirty days in
        case you change your mind and would like to restore that content,
        because starting from scratch is no fun. After the thirty days are up,
        the deleted content may remain on our backups and caches until purged.
      </Typography>
      <Typography variant="h4" sx={{ mt: 2, mb: 2 }} id="children">
        Children
      </Typography>
      <Typography>
        Our Service is not directed to children who are under the age of 13.
        Linkidex does not knowingly collect Personal Information from children
        under the age of 13. If you have reason to believe that a child under
        the age of 13 has provided Personal Information to Linkidex through the
        Service, please contact us and we will endeavor to delete that
        information from our databases.
      </Typography>
      <Typography
        variant="h4"
        sx={{ mt: 2, mb: 2 }}
        id="links-to-other-websites"
      >
        Links to Other Websites
      </Typography>
      <Typography>
        The Service may contain links to other websites not operated or
        controlled by Linkidex, including social media services (“Third Party
        Sites”). The information that you share with Third Party Sites will be
        governed by the specific privacy policies and terms of service of the
        Third Party Sites and not by this Privacy Policy. By providing these
        links we do not imply that we endorse or have reviewed these sites.
        Please contact the Third Party Sites directly for information on their
        privacy practices and policies.
      </Typography>
      <Typography variant="h4" sx={{ mt: 2, mb: 2 }} id="security">
        Security
      </Typography>
      <Typography>
        While no online service is 100% secure, we work very hard to protect
        information about you against unauthorized access, use, alteration, or
        destruction, and take reasonable measures to do so. We monitor our
        Services for potential vulnerabilities and attacks.
      </Typography>
      <Typography variant="h4" sx={{ mt: 2, mb: 2 }} id="choices">
        Choices
      </Typography>
      <Typography>
        You have several choices available when it comes to information about
        you:
      </Typography>
      <ul>
        <li>
          <Typography>
            <strong>Limit the information that you provide:</strong> If you have
            an account with us, you can choose not to provide the optional
            account information, profile information, and transaction and
            billing information. Please keep in mind that if you do not provide
            this information, certain features of our Services may not be
            accessible.
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Limit access to information on your mobile device:</strong>{" "}
            Your mobile device operating system should provide you with the
            option to discontinue our ability to collect stored information or
            location information via our mobile apps. If you choose to limit
            this, you may not be able to use certain features.
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Opt out of marketing communications:</strong> You may opt
            out of receiving promotional communications from us. Just follow the
            instructions in those communications or let us know. If you opt out
            of promotional communications, we may still send you other
            communications, like those about your account and legal notices.
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Set your browser to reject cookies:</strong> At this time,
            Linkidex does not respond to "do not track" signals across all of
            our Services. However, you can usually choose to set your browser to
            remove or reject browser cookies before using Linkidex’s websites,
            with the drawback that certain features of Linkidex’s websites may
            not function properly without the aid of cookies.
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Close your account:</strong> While we’d be very sad to see
            you go, you can close your account if you no longer want to use our
            Services. Please keep in mind that we may continue to retain your
            information after closing your account, as described in How Long We
            Keep Information above — for example, when that information is
            reasonably needed to comply with (or demonstrate our compliance
            with) legal obligations such as law enforcement requests, or
            reasonably needed for our legitimate business interests. Reach out
            to help@linkidex.com for assistance.
          </Typography>
        </li>
      </ul>
      <Typography variant="h4" sx={{ mt: 2, mb: 2 }} id="your-rights">
        Your Rights
      </Typography>
      <Typography>
        If you are located in certain parts of the world, including California
        and countries that fall under the scope of the European General Data
        Protection Regulation (aka the "GDPR"), you may have certain rights
        regarding your personal information, like the right to request access to
        or deletion of your data.
      </Typography>
      <Typography
        variant="h6"
        id="_european-general-data-protection-regulation-gdpr-_"
      >
        <em>European General Data Protection Regulation (GDPR)</em>
      </Typography>
      <Typography>
        If you are located in a country that falls under the scope of the GDPR,
        data protection laws give you certain rights with respect to your
        personal data, subject to any exemptions provided by the law, including
        the rights to:
      </Typography>
      <ul>
        <li>
          <Typography>Request access to your personal data;</Typography>
        </li>
        <li>
          <Typography>
            Request correction or deletion of your personal data;
          </Typography>
        </li>
        <li>
          <Typography>
            Object to our use and processing of your personal data;
          </Typography>
        </li>
        <li>
          <Typography>
            Request that we limit our use and processing of your personal data;
            and
          </Typography>
        </li>
        <li>
          <Typography>Request portability of your personal data.</Typography>
        </li>
      </ul>
      <Typography>
        You also have the right to make a complaint to a government supervisory
        authority.
      </Typography>
      <Typography variant="h6" id="_contacting-us-about-these-rights_">
        <em>Contacting Us About These Rights</em>
      </Typography>
      <Typography>
        You can usually access, correct, or delete your personal data using your
        account settings and tools that we offer, but if you aren’t able to or
        you’d like to contact us about one of the other rights, reach out to
        help@linkidex.com. When you contact us about one of your rights under
        this section, we’ll need to verify that you are the right person before
        we disclose or delete anything. For example, if you are a user, we will
        need you to contact us from the email address associated with your
        account. You can also designate an authorized agent to make a request on
        your behalf by giving us written authorization. We may still require you
        to verify your identity with us.
      </Typography>
      <Typography variant="h4" sx={{ mt: 2, mb: 2 }} id="how-to-reach-us">
        How to Reach Us
      </Typography>
      <Typography>
        If you have a question about this Privacy Policy, or you would like to
        contact us about any of the rights mentioned above, please contact us
        via <Link href="mailto:help@Linkidex.com">help@linkidex.com</Link>.
      </Typography>
      <Typography
        variant="h4"
        sx={{ mt: 2, mb: 2 }}
        id="other-things-you-should-know-keep-reading-"
      >
        Other Things You Should Know (Keep Reading!)
      </Typography>
      <Typography variant="h6" id="_transferring-information_">
        <em>Transferring Information Internationally</em>
      </Typography>
      <Typography>
        By using our Service, you understand and acknowledge that your Personal
        Information will be transferred from your location to our facilities and
        servers in the United States.
      </Typography>
      <Typography
        variant="h6"
        id="_ads-and-analytics-services-provided-by-others_"
      >
        <em>Ads and Analytics Services Provided by Others</em>
      </Typography>
      <Typography>
        Ads appearing on any of our Services may be delivered by advertising
        networks. Other parties may also provide analytics services via our
        Services. These ad networks and analytics providers may set tracking
        technologies (like cookies) to collect information about your use of our
        Services and across other websites and online services. These
        technologies allow these third parties to recognize your device to
        compile information about you or others who use your device. This
        information allows us and other companies to, among other things,
        analyze and track usage, determine the popularity of certain content,
        and deliver ads that may be more targeted to your interests. Please note
        this Privacy Policy only covers the category of information by Linkidex
        and does not cover the category of information by any third-party
        advertisers or analytics providers.
      </Typography>
      <Typography
        variant="h4"
        sx={{ mt: 2, mb: 2 }}
        id="privacy-policy-changes"
      >
        Privacy Policy Changes
      </Typography>
      <Typography>
        Although most changes are likely to be minor, Linkidex may change its
        Privacy Policy from time to time. Linkidex encourages visitors to
        frequently check this page for any changes to its Privacy Policy. If we
        make changes, we will notify you by revising the change log below, and,
        in some cases, we may provide additional notice (like adding a statement
        to our homepage or sending you a notification through email or your
        dashboard). Your further use of the Services after a change to our
        Privacy Policy will be subject to the updated policy.
      </Typography>
      <Typography variant="h4" sx={{ mt: 2, mb: 2 }} id="change-log">
        Change log
      </Typography>
      <ul>
        <li>
          <Typography>
            <em>May 30, 2022</em>: Original Privacy Policy Published. Hello
            world!
          </Typography>
        </li>
      </ul>
      <Typography variant="caption">
        Thank you to the team at{" "}
        <Link href="https://auttomatic.com/about/">Auttomatic</Link> for making
        their Terms of Service available under a{" "}
        <Link href="https://creativecommons.org/licenses/by-sa/4.0/">
          Creative Commons Sharealike
        </Link>{" "}
        License. You can grab a copy of their Terms of Service and other legal
        documents on{" "}
        <Link
          href="https://github.com/Automattic/legalmattic/"
          target="_blank"
          rel="noreferrer"
        >
          GitHub
        </Link>
        .
      </Typography>
    </Box>
  )
}
