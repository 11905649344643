import { createRef } from "react"
import useFileUpload from "./useFileUpload"

import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"
import { SxProps } from "@mui/material"

interface ImportBookmarksTextAndButtonInterface {
  textStyle: SxProps
}

export const ImportBookmarksTextAndButton = ({ textStyle }: ImportBookmarksTextAndButtonInterface) => {
  const { handleFileUpload } = useFileUpload()
  const fileUploadRef = createRef<any>()

  const handleFileChange = () => {
    handleFileUpload(fileUploadRef.current.files[0])
    fileUploadRef.current.value = ""
  }

  return (
    <>
      {/* Left part of row */}
      <Grid item sx={{ display: { marginTop: 20 } }} xs={12} sm={8}>
        <Typography sx={textStyle} variant="h6">
          Import Bookmarks
        </Typography>
        <Typography sx={textStyle} variant="subtitle2">
          Upload a ".html" file generated by your browser to Linkidex.
        </Typography>
      </Grid>

      {/* Right part of row */}
      <Grid item sx={{ display: { marginTop: 20 } }} xs={12} sm={4}>
        <Button
          variant="outlined"
          component="label"
          aria-label="upload-bookmarks"
          sx={{ mt: { xs: 0, sm: 1.5 }, ml: { xs: 2, sm: 0 } }}
        >
          Upload File
          <input type="file" hidden onChange={handleFileChange} ref={fileUploadRef} />
        </Button>
      </Grid>

      {/* Empty area used for spacing that disappears on small screen sizes */}
      {/* <Grid
        item
        sx={{ display: { xs: "none", md: "block", marginTop: 20 } }}
        md={3}
      ></Grid> */}
    </>
  )
}
