import { useContext } from "react"

// MUI
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"
import Chip from "@mui/material/Chip"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import Tooltip from "@mui/material/Tooltip"

// icons
import GroupsIcon from "@mui/icons-material/Groups" // organizations
import StyleIcon from "@mui/icons-material/Style" // tags
import CategoryIcon from "@mui/icons-material/Category" // categories
import FavoriteIcon from "@mui/icons-material/Favorite" // favorite
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder" // favorite off
import SearchIcon from "@mui/icons-material/Search" // search
import SearchOffIcon from "@mui/icons-material/SearchOff" // search off
import PersonIcon from "@mui/icons-material/Person" // personal links
import PersonOutlineIcon from "@mui/icons-material/PersonOutline" // personal links off
import DeleteIcon from "@mui/icons-material/Delete"
import JoinInnerIcon from "@mui/icons-material/JoinInner" // inclusive search
import JoinFullIcon from "@mui/icons-material/JoinFull" // exclusive search

// Utils
import { countOfSelectedDrawerTypes } from "Utils/Utils"
import { FAVORITE_COLOR } from "constants/Global"
// Contexts
import { SelectedCategoriesContext } from "contexts/SelectedCategoriesContext"
import { PersonalLinksSelectedContext } from "contexts/PersonalLinksSelectedContext"
import { FavoritesSelectedContext } from "contexts/FavoritesSelectedContext"
import { SelectedTagsContext } from "contexts/SelectedTagsContext"
import { SelectedOrganizationsContext } from "contexts/SelectedOrganizationsContext"
import { SideDrawerContext } from "contexts/SideDrawerContext"
import { IsStrictSearchContext } from "contexts/IsStrictSearchContext"

interface LinkSearchDetailsProps {
  searchText: string
  setSearchText: (arg: string) => void
  searchFieldRef: React.RefObject<HTMLElement>
}
export const LinkSearchDetails = (props: LinkSearchDetailsProps) => {
  const { searchText, setSearchText, searchFieldRef } = props
  const { selectedCategoriesState, setSelectedCategoriesState } = useContext(SelectedCategoriesContext)
  const { personalLinksSelectedState, setPersonalLinksSelectedState } = useContext(PersonalLinksSelectedContext)
  const { favoritesSelectedState, setFavoritesSelectedState } = useContext(FavoritesSelectedContext)
  const { selectedTagsState, setSelectedTagsState } = useContext(SelectedTagsContext)
  const { selectedOrganizationsState, setSelectedOrganizationsState } = useContext(SelectedOrganizationsContext)
  const { sideDrawerState, setSideDrawerState } = useContext(SideDrawerContext)
  const { isStrictSearch, setIsStrictSearch } = useContext(IsStrictSearchContext)

  const openSideDrawer = () => {
    setSideDrawerState({ ...sideDrawerState, isOpen: true })
  }

  const focusSearchBar = () => {
    searchFieldRef.current?.focus()
  }

  const clearFilters = () => {
    setSelectedCategoriesState([])
    setPersonalLinksSelectedState(false)
    setSelectedTagsState({})
    setFavoritesSelectedState(false)
    setSelectedOrganizationsState({})
    setSearchText("")
  }

  const determineQuantityToolTipText = (count: number, resource: string) => {
    if (count === 0) {
      return `You are not filtering links by ${resource}`
    }
    if (count === 1) {
      return `Your search results include links from ${count} ${resource}`
    }
    return `Your search results include links from ${count} ${resource}s`
  }

  const determineTagQuantityToolTipText = (count: number) => {
    if (count === 0) {
      return `You are not filtering links by tags`
    }
    if (isStrictSearch) {
      if (count === 1) {
        return `Your search results must have the selected tag`
      } else if (count > 1) {
        return `Your search results must have all of the ${count} selected tags`
      }
    } else {
      if (count === 1) {
        return `Your search results include links with the selected tag`
      } else if (count > 1) {
        return `Your search results include links with any of the ${count} selected tags`
      }
    }
    return ""
  }

  const determineCategoryQuantityToolTipText = (count: number) => {
    if (count === 0) {
      return `You are not filtering links by category`
    }
    if (isStrictSearch) {
      if (count === 1) {
        return `Your search results must have the selected category`
      } else if (count > 1) {
        return `Your search results must have one of the ${count} selected categories`
      }
    } else {
      if (count === 1) {
        return `Your search results include links with the selected category`
      } else if (count > 1) {
        return `Your search results include links from the ${count} selected categories`
      }
    }
    return ""
  }

  const determineOrganizationQuantityToolTipText = (count: number) => {
    if (count === 0) {
      return `You are not filtering links by organization`
    }
    if (isStrictSearch) {
      if (count === 1) {
        return `Your search results must belong to the selected organization`
      } else if (count > 1) {
        return `Your search results must belong to one of the ${count} selected organizations`
      }
    } else {
      if (count === 1) {
        return `Your search results include links belonging to the selected organization`
      } else if (count > 1) {
        return `Your search results include links belonging to the ${count} selected organizations`
      }
    }
    return ""
  }

  const determineBooleanToolTipText = (isSearching: boolean, resource: string) => {
    if (isSearching && isStrictSearch) {
      return `Your search results must be ${resource}s`
    } else if (isSearching && !isStrictSearch) {
      return `Your search results include ${resource}s`
    }
    return `you are not filtering by ${resource}s`
  }

  const determineSearchToolTipText = (searchQuery: string) => {
    if (searchQuery.length > 0) {
      return `you are fuzzy searching across link title, category, and tag for '${searchQuery}'`
    }
    return "you are not fuzzy searching for any specific text."
  }

  const determineSearchTagsTooltipText = () => {
    if (isStrictSearch) {
      return "You are strict searching: The current search results must match all of your current filters."
    }
    return "The current search results can match any of your current filters."
  }

  const searchTags = () => {
    if (isStrictSearch) {
      return <JoinInnerIcon />
    } else {
      return <JoinFullIcon />
    }
  }

  const toggleSearchMustContainAllTags = () => {
    setIsStrictSearch(!isStrictSearch)
  }

  const centerIconSx = { fontSize: "18px", mx: 0.5, mt: 0.5 }
  const tableCellSx = { mx: 0, px: 0, borderBottom: "none" }
  const toolTipSx = { maxWidth: "60px" }
  return (
    <Stack direction="row" sx={{ maxWidth: "300px" }}>
      <TableContainer
        component={Paper}
        sx={{ display: "flex", maxWidth: "200px", minWidth: "150px", borderRadius: "12px", mr: 1 }}
      >
        <Table size="small" aria-label="Search Details">
          <TableHead>
            <TableRow>
              <TableCell sx={tableCellSx} align="center">
                <Tooltip
                  placement="left-start"
                  sx={toolTipSx}
                  followCursor
                  title={determineOrganizationQuantityToolTipText(
                    countOfSelectedDrawerTypes(selectedOrganizationsState)
                  )}
                >
                  <Chip
                    color={countOfSelectedDrawerTypes(selectedOrganizationsState) > 0 ? "primary" : "default"}
                    onClick={openSideDrawer}
                    variant="outlined"
                    size="small"
                    icon={<GroupsIcon />}
                    label={countOfSelectedDrawerTypes(selectedOrganizationsState)}
                  />
                </Tooltip>
              </TableCell>
              <TableCell sx={tableCellSx} align="center">
                <Tooltip
                  placement="left-start"
                  sx={toolTipSx}
                  followCursor
                  title={determineTagQuantityToolTipText(countOfSelectedDrawerTypes(selectedTagsState))}
                >
                  <Chip
                    color={countOfSelectedDrawerTypes(selectedTagsState) > 0 ? "primary" : "default"}
                    onClick={openSideDrawer}
                    variant="outlined"
                    size="small"
                    icon={<StyleIcon />}
                    label={countOfSelectedDrawerTypes(selectedTagsState)}
                  />
                </Tooltip>
              </TableCell>
              <TableCell sx={tableCellSx} align="center">
                <Tooltip
                  placement="left-start"
                  sx={toolTipSx}
                  followCursor
                  title={determineCategoryQuantityToolTipText(countOfSelectedDrawerTypes(selectedCategoriesState))}
                >
                  <Chip
                    color={countOfSelectedDrawerTypes(selectedCategoriesState) > 0 ? "primary" : "default"}
                    onClick={openSideDrawer}
                    variant="outlined"
                    size="small"
                    icon={<CategoryIcon />}
                    label={countOfSelectedDrawerTypes(selectedCategoriesState)}
                  />
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell sx={tableCellSx} align="center">
                <Tooltip
                  placement="left-start"
                  sx={toolTipSx}
                  followCursor
                  title={determineBooleanToolTipText(personalLinksSelectedState, "personal link")}
                >
                  <Chip
                    color={personalLinksSelectedState ? "primary" : "default"}
                    onClick={() => setPersonalLinksSelectedState(!personalLinksSelectedState)}
                    variant="outlined"
                    size="small"
                    label={
                      personalLinksSelectedState ? (
                        <PersonIcon sx={centerIconSx} />
                      ) : (
                        <PersonOutlineIcon sx={centerIconSx} />
                      )
                    }
                  />
                </Tooltip>
              </TableCell>
              <TableCell sx={tableCellSx} align="center">
                <Tooltip
                  placement="left-start"
                  sx={toolTipSx}
                  followCursor
                  title={determineBooleanToolTipText(favoritesSelectedState, "favorite")}
                >
                  <Chip
                    sx={{ color: favoritesSelectedState ? FAVORITE_COLOR : "" }}
                    color={favoritesSelectedState ? "primary" : "default"}
                    onClick={() => setFavoritesSelectedState(!favoritesSelectedState)}
                    variant="outlined"
                    size="small"
                    label={
                      favoritesSelectedState ? (
                        <FavoriteIcon sx={centerIconSx} />
                      ) : (
                        <FavoriteBorderIcon sx={centerIconSx} />
                      )
                    }
                  />
                </Tooltip>
              </TableCell>
              <TableCell sx={tableCellSx} align="center">
                <Tooltip
                  placement="left-start"
                  sx={toolTipSx}
                  followCursor
                  title={determineSearchToolTipText(searchText)}
                >
                  <Chip
                    color={searchText.length > 0 ? "primary" : "default"}
                    onClick={focusSearchBar}
                    variant="outlined"
                    size="small"
                    label={
                      searchText.length > 0 ? <SearchIcon sx={centerIconSx} /> : <SearchOffIcon sx={centerIconSx} />
                    }
                  />
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Stack spacing={1} direction="column" sx={{ maxWidth: "300px" }}>
        <Tooltip placement="left-start" sx={toolTipSx} followCursor title={determineSearchTagsTooltipText()}>
          <Button color="info" variant="outlined" onClick={toggleSearchMustContainAllTags}>
            {searchTags()}
          </Button>
        </Tooltip>
        <Tooltip placement="left-start" sx={toolTipSx} followCursor title="Clear all filters & search query">
          <Button color="error" variant="outlined" onClick={clearFilters}>
            <DeleteIcon />
          </Button>
        </Tooltip>
      </Stack>
    </Stack>
  )
}
