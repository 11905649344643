import { useContext } from "react"
import { useQuery } from "@apollo/client"
import { useNavigate } from "react-router-dom"

// types
import { OrganizationInterface } from "interfaces/Organization"

// mui
import Typography from "@mui/material/Typography"
import Card from "@mui/material/Card"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import SettingsIcon from "@mui/icons-material/Settings"
import Tooltip from "@mui/material/Tooltip"

import { getOrganizationsQuery } from "queries/queries"

import { ModalContext } from "contexts/ModalContext"
import { MODAL_TYPES } from "components/Modal/ModalTypes"

export function ListUsersOrganizations() {
  const { setModalState } = useContext(ModalContext)
  const { loading, data } = useQuery(getOrganizationsQuery, {
    fetchPolicy: "network-only",
  })
  const navigate = useNavigate()

  const openCreateOrganizationModal = () => {
    setModalState({
      isOpen: true,
      modalType: MODAL_TYPES.CREATE_ORGANIZATION,
    })
  }

  const openConfirmLeaveOrgModal = (
    organization_id: string,
    organization_name: string
  ) => {
    setModalState({
      isOpen: true,
      modalType: MODAL_TYPES.LEAVE_ORGANIZATION,
      data: {
        organization_id: organization_id,
        organization_name: organization_name,
      },
    })
  }

  const displayUserOrgs = () =>
    data?.organizations.map(
      (organization: OrganizationInterface, idx: number) => (
        <Card
          variant="outlined"
          key={idx}
          sx={{ mx: 2, my: 1, px: 1, py: 1, display: "flex" }}
        >
          <Typography
            sx={{
              ml: 2,
              flexGrow: 1,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              flexBasis: "100%",
            }}
          >
            {organization.name}
          </Typography>
          <Tooltip title="Manage Organization">
            <IconButton
              disabled={false}
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => navigate(`/organizations/${organization.id}`)}
            >
              <SettingsIcon sx={{ fontSize: "20px" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Leave Organization">
            <IconButton
              aria-label="leave-organization"
              color="inherit"
              size="small"
              onClick={() => {
                openConfirmLeaveOrgModal(organization.id, organization.name)
              }}
            >
              <CloseIcon sx={{ fontSize: "20px" }} />
            </IconButton>
          </Tooltip>
        </Card>
      )
    )

  const renderCreateOrgCard = () => {
    if (loading || data?.organizations?.length) {
      return (
        <>
          <Typography variant="h4" sx={{ ml: 2, mt: 2, mb: 1 }}>
            Your Organizations
          </Typography>

          {displayUserOrgs()}
          <Box sx={{ ml: 2, py: 1 }}>
            <Button
              onClick={openCreateOrganizationModal}
              aria-label="Resend Email Verification"
              variant="contained"
              color="success"
            >
              Create Org
            </Button>
          </Box>
        </>
      )
    }
    return ""
  }

  return <>{renderCreateOrgCard()}</>
}
