import { useContext } from "react"
import { useQuery } from "@apollo/client"

// Contexts
import { LightDarkModeContext } from "contexts/LightDarkModeContext"

// Queries
import { getActiveUserQuery } from "queries/queries"

import { HOSTNAME } from 'constants/Global'

export const StripeUserTable = () => {
  const { data } = useQuery(getActiveUserQuery)
  const { currentModeState } = useContext(LightDarkModeContext)
  const isDarkModeEnabled = currentModeState.palette?.mode === "dark"

  const pricingTableId = () => {
    if (window.location.hostname !== HOSTNAME) {
      // test table
      return "prctbl_1NHloTJg4u7mM7uG902sihC2"
    } else {
      if (isDarkModeEnabled) {
        // dark mode prod table
        return "prctbl_1O2DiXJg4u7mM7uGMcqUKktP"
      }
      // light mode prod table
      return "prctbl_1O2DkDJg4u7mM7uGjRoFPFxI"
    }
  }

  const publishableKey = () => {
    if (window.location.hostname !== HOSTNAME) {
      // test table
      return "pk_test_51Lwde0Jg4u7mM7uG2qjH2ceRt3YO9qKVJKmI0fThJQte87FSDHWlWJza41wtsQiXRWA2GG0L4yctDwBYNzvt44Xp00ab34hALz"
    } else {
      if (isDarkModeEnabled) {
        // dark mode prod table
        return "pk_live_51Lwde0Jg4u7mM7uGB4el908P594QSEKnSE3o8VKjZ5uOmZKYfDtkl3JYHMZxBvlhgvZvtI85bQfC7Vk3QMwlAQD300HHYMBzGc"
      }
      // light mode prod table
      return "pk_live_51Lwde0Jg4u7mM7uGB4el908P594QSEKnSE3o8VKjZ5uOmZKYfDtkl3JYHMZxBvlhgvZvtI85bQfC7Vk3QMwlAQD300HHYMBzGc"
    }
  }

  return (
    <div>
      <stripe-pricing-table
        pricing-table-id={pricingTableId()}
        publishable-key={publishableKey()}
        client-reference-id={`usr-${data.activeUser.id}`}
      ></stripe-pricing-table>
    </div>
  )
}
