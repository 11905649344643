import React, { useContext } from "react"

// mui
import Menu from "@mui/material/Menu"
import Box from "@mui/material/Box"
import MenuItem from "@mui/material/MenuItem"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { IconButton } from "@mui/material"

// Types
import { MODAL_TYPES } from "components/Modal/ModalTypes"

// Contexts
import { ModalContext } from "contexts/ModalContext"
import { CATEGORY, TAG } from "constants/Global"
import { OrganizationInterface } from "interfaces/Organization"

interface FolderDropdownPropsInterface {
  folderId: number
  folderTitle: string
  folderDescription: string
  contentType: typeof CATEGORY | typeof TAG
  parentFolderId: string
  parentFolderTitle: string
  organization?: OrganizationInterface
}
export const FolderDropdown = (props: FolderDropdownPropsInterface) => {
  const { folderId, folderTitle, folderDescription, contentType, parentFolderId, parentFolderTitle, organization } =
    props

  const [open, setIsOpen] = React.useState<boolean>(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { setModalState } = useContext(ModalContext)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleEditFolder = () => {
    handleClose()
    setModalState({
      isOpen: true,
      modalType: MODAL_TYPES.EDIT_FOLDER,
      data: {
        id: folderId,
        title: folderTitle,
        description: folderDescription,
        contentType: contentType,
        parentFolderId: parentFolderId,
        parentFolderTitle: parentFolderTitle,
        organization: organization,
      },
    })
  }

  const handleDeleteFolder = () => {
    handleClose()

    setModalState({
      isOpen: true,
      modalType: MODAL_TYPES.DELETE_FOLDER,
      data: {
        id: folderId,
        title: folderTitle,
        description: folderDescription,
        contentType: contentType,
        organization: organization,
      },
    })
  }

  return (
    <Box component="span">
      <IconButton
        edge="end"
        id="basic-button"
        aria-haspopup="true"
        onClick={handleClick}
        aria-label={`edit folder ${folderTitle}`}
      >
        <MoreVertIcon sx={{ fontSize: 28 }} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleEditFolder}>Edit Folder</MenuItem>
        <MenuItem onClick={handleDeleteFolder}>Delete Folder</MenuItem>
      </Menu>
    </Box>
  )
}
