import { useContext } from "react"
import { useNavigate } from "react-router-dom"

import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"

// Components
import { CloseModalButton } from "components/Modal/CloseModalButton"

// Contexts
import { SideDrawerContext } from "contexts/SideDrawerContext"
import { UserOnboardingTourContext } from "contexts/UserOnboardingTourContext"
import { ModalContext } from "contexts/ModalContext"

export const WelcomeModal = () => {
  let navigate = useNavigate()
  const { setModalState } = useContext(ModalContext)

  const { sideDrawerState, setSideDrawerState } = useContext(SideDrawerContext)

  const { updateUserOnboardingTourState } = useContext(
    UserOnboardingTourContext
  )

  const triggerOnboardingTour = () => {
    navigate("/links")
    closeModal()
    setSideDrawerState({ ...sideDrawerState, isOpen: false })
    updateUserOnboardingTourState({ hasSeenWelcome: true, isOpen: true })
  }

  const closeModal = () => {
    setModalState({ isOpen: false, modalType: "" })
  }

  return (
    <>
      <CloseModalButton closeModal={closeModal} />
      <Typography
        textAlign="center"
        variant="h4"
        sx={{ my: 1 }}
        aria-label={`Welcome To Linkidex!`}
      >
        Welcome To Linkidex!
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Typography textAlign="center" variant="h5" sx={{ my: 1 }}>
            30 Second Tour
          </Typography>
          <Typography textAlign="center" sx={{ my: 1 }}>
            We will show you how to use Linkidex with a quick tutorial.
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              onClick={triggerOnboardingTour}
              sx={{ my: 2, mx: 2 }}
            >
              Show Me Around
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography textAlign="center" variant="h5" sx={{ my: 1 }}>
            I know what I am doing
          </Typography>
          <Typography textAlign="center" sx={{ my: 1 }}>
            Skip tutorial, head straight to Linkidex.
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              onClick={closeModal}
              sx={{ my: 2, mx: 2 }}
            >
              Skip Tutorial
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
