import * as React from "react"
import { useState, useEffect, useContext, useRef } from "react"
import { sortByText, debounceFunction } from "Utils/Utils"

import { useMutation } from "@apollo/client"

// MUI
import Button from "@mui/material/Button"
import Chip from "@mui/material/Chip"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import Tooltip from "@mui/material/Tooltip"
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome"

import { SelectFieldDropdown, DropDownOption } from "components/SelectFieldDropdown"
import { CreateCategoryModal } from "components/Modal/CreateCategoryModal"
import { SnackBarContext } from "contexts/SnackBarContext"

// types
import { SNACK_BAR_TYPES } from "components/SnackBar/SnackBarTypes"
import { DEBOUNCE_TIME } from "constants/Global"

import { writeCategoryQuery, createCategoryMutation } from "queries/queries"
interface SelectCategoryFormInterface {
  dropdownOptions: DropDownOption[]
  title: string
  selectedOption: null | DropDownOption
  handleSelect: (event: any, value: any) => void
  suggestions?: string[]
  defaultOrganizationId?: string // default org categories will be created for.
}

export const SelectCategoryForm = (props: SelectCategoryFormInterface) => {
  const { dropdownOptions, selectedOption, handleSelect, title, defaultOrganizationId, suggestions } = props
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [newCategory, setNewCategory] = useState<string | null>(null)
  const { setSnackBarState } = useContext(SnackBarContext)
  const timeout = useRef<any>()

  const createCategory = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const createCategoryCallBack = (newCategoryResponse: any) => {
    // save ID of category that was created while 'select category' was open
    setNewCategory(newCategoryResponse.id)
  }

  const debouncedHandleAddCategory = (suggestion: string) => {
    debounceFunction(
      timeout,
      () => {
        handleAddCategory(suggestion)
      },
      DEBOUNCE_TIME
    )
  }

  const [addCategory] = useMutation(createCategoryMutation, {
    update(cache, { data: { createCategory } }) {
      cache.modify({
        fields: {
          categories(existingCategories = []) {
            const newCategoryRef = cache.writeFragment({
              data: createCategory,
              fragment: writeCategoryQuery,
            })
            return [...existingCategories, newCategoryRef]
          },
        },
      })
    },
  })

  const handleAddCategory = (title: string) => {
    var orgId = defaultOrganizationId === "0" ? undefined : defaultOrganizationId

    addCategory({
      variables: {
        title: title,
        organizationId: orgId,
        description: "",
        folderId: null,
      },
    }).then(
      (res: any) => {
        setSnackBarState({
          isOpen: true,
          snackBarType: SNACK_BAR_TYPES.SUCCESS,
          message: "Category Created.",
        })
        createCategoryCallBack(res.data.createCategory)
      },
      (res: any) => {
        setSnackBarState({
          isOpen: true,
          snackBarType: SNACK_BAR_TYPES.SUCCESS,
          message: "Category Created.",
        })
      }
    )
  }

  useEffect(() => {
    // if newCategory exists, select our new category from dropdown options
    // once dropdownOptions has been asynchronously updated
    if (newCategory) {
      const dropdownMatchIndex = dropdownOptions.findIndex((dropdownOption) => {
        return dropdownOption.value === newCategory
      })

      if (dropdownMatchIndex >= 0) {
        handleSelect(null, dropdownOptions[dropdownMatchIndex])
        setNewCategory(null)
      }
    }
  }, [dropdownOptions, handleSelect, newCategory])

  const selectSuggestion = (suggestion: string) => {
    const foundOption = dropdownOptions.find((dropdownOption: DropDownOption) => dropdownOption.name === suggestion)
    if (foundOption) {
      handleSelect(null, foundOption)
    } else {
      debouncedHandleAddCategory(suggestion)
    }
  }

  const determineChipColor = (suggestion: string) => {
    if (selectedOption && selectedOption.name === suggestion) {
      return "success"
    }
    return "default"
  }

  const buildCategorySuggestions = () => {
    return suggestions?.map((suggestion: string, idx: number) => {
      return (
        <Tooltip key={idx} followCursor title="Use this suggested category">
          <Chip
            sx={{ mx: 1 }}
            icon={<AutoAwesomeIcon fontSize="small" />}
            label={suggestion}
            variant="outlined"
            color={determineChipColor(suggestion)}
            onClick={() => {
              selectSuggestion(suggestion)
            }}
          />
        </Tooltip>
      )
    })
  }

  return (
    <span id="select-category-form">
      <Grid container>
        <Grid item xs={12}>
          {buildCategorySuggestions()}
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectFieldDropdown
            styles={{ mt: 2, width: "100%" }}
            dropdownOptions={dropdownOptions.sort(function (a, b) {
              return sortByText(a.name, b.name)
            })}
            title={title}
            selectedOption={selectedOption}
            handleSelect={handleSelect}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
            <Button sx={{ mt: 3 }} variant="outlined" onClick={createCategory}>
              New Category
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth={true}>
        <DialogContent>
          <CreateCategoryModal
            callBack={createCategoryCallBack}
            isControlledByContext={false}
            handleClose={handleCloseModal}
            defaultOrganizationId={defaultOrganizationId}
          />
        </DialogContent>
      </Dialog>
    </span>
  )
}
