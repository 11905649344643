import { StripeOrgTable } from "./StripeOrgTable"
import { useParams } from "react-router-dom"

export const UpgradeOrganizationTable = () => {
  let { organization_id } = useParams()
  return (
    <div>
      <StripeOrgTable organizationId={organization_id} />
    </div>
  )
}
