import { SxProps } from "@mui/material"

export const forgotPasswordFormStyle = {
  marginLeft: "auto",
  marginRight: "auto",
  mt: 5,
  pb: 1,
  border: "1px solid",
  borderRadius: 2,
  textAlign: "center",
  fontSize: "1rem",
  fontWeight: "700",
  width: { xs: "auto", sm: "396px" },
} as SxProps
