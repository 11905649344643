import React from "react"
import { useContext } from "react"

import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"

// Contexts
import { ModalContext } from "contexts/ModalContext"

// Components
import { SignUpModal } from "./SignUpModal"
import { UpdateEmailModal } from "./UpdateEmailModal"
import { UpdatePasswordModal } from "./UpdatePasswordModal"
import { CreateLinkModal } from "./CreateLinkModal"
import { EditLinkModal } from "./EditLinkModal"
import { DeleteLinkModal } from "./DeleteLinkModal"
import { CreateCategoryModal } from "./CreateCategoryModal"
import { EditCategoryModal } from "./EditCategoryModal"
import { CreateTagModal } from "./CreateTagModal"
import { EditTagModal } from "./EditTagModal"
import { DeleteTagModal } from "./DeleteTagModal"
import { DeleteCategoryModal } from "./DeleteCategoryModal"
import { CreateFolderModal } from "./CreateFolderModal"
import { EditFolderModal } from "./EditFolderModal"
import { DeleteFolderModal } from "./DeleteFolderModal"
import { IphoneDownloadScreenshotModal } from "./IphoneDownloadScreenshotModal"
import { SecondFactorModal } from "./SecondFactorModal"
import { ManageTwoFactorModal } from "./ManageTwoFactorModal"
import { ManageBackupCodesModal } from "./ManageBackupCodesModal"
import { LostAuthenticatorModal } from "./LostAuthenticatorModal"
import { ManageWebauthnModal } from "./ManageWebauthnModal"
import { WebauthnFactorModal } from "./WebauthnFactorModal"
import { CreateOrganizationModal } from "./CreateOrganizationModal"
import { LeaveOrganizationModal } from "./LeaveOrganizationModal"
import { ManageUserOrganizationPermissionsModal } from "./ManageUserOrganizationPermissionsModal"
import { RemoveUserFromOrganizationModal } from "./RemoveUserFromOrganizationModal"
import { CreateOrganizationInviteModal } from "./CreateOrganizationInviteModal"
import { OrganizationRolesDescriptionModal } from "./OrganizationRolesDescriptionModal"
import { OrganizationStateDescriptionModal } from "./OrganizationStateDescriptionModal"
import { WelcomeModal } from "./WelcomeModal"
import { ImportBookmarksHowTwoModal } from "./ImportBookmarksHowTwoModal"
import { UseCategoriesAndTagsForAiSuggestHowToModal } from "./UseCategoriesAndTagsForAiSuggestHowToModal"

// Types
import { MODAL_TYPES } from "components/Modal/ModalTypes"

export const Modal = () => {
  const { modalState, setModalState } = useContext(ModalContext)

  const handleClose = () => {
    setModalState({ isOpen: false, modalType: "" })
  }

  switch (modalState.modalType) {
    case MODAL_TYPES.SIGN_UP:
      return (
        <Dialog open={modalState.isOpen} onClose={handleClose} fullWidth={true}>
          <DialogContent>
            <SignUpModal />
          </DialogContent>
        </Dialog>
      )
    case MODAL_TYPES.UPDATE_EMAIL:
      return (
        <Dialog open={modalState.isOpen} onClose={handleClose} fullWidth={true}>
          <DialogContent>
            <UpdateEmailModal />
          </DialogContent>
        </Dialog>
      )
    case MODAL_TYPES.UPDATE_PASSWORD:
      return (
        <Dialog open={modalState.isOpen} onClose={handleClose} fullWidth={true}>
          <DialogContent>
            <UpdatePasswordModal />
          </DialogContent>
        </Dialog>
      )
    case MODAL_TYPES.CREATE_LINK:
      return (
        <Dialog open={modalState.isOpen} onClose={handleClose} fullWidth={true}>
          <DialogContent>
            <CreateLinkModal />
          </DialogContent>
        </Dialog>
      )
    case MODAL_TYPES.EDIT_LINK:
      return (
        <Dialog open={modalState.isOpen} onClose={handleClose} fullWidth={true}>
          <DialogContent>
            <EditLinkModal />
          </DialogContent>
        </Dialog>
      )
    case MODAL_TYPES.DELETE_LINK:
      return (
        <Dialog open={modalState.isOpen} onClose={handleClose} fullWidth={true}>
          <DialogContent>
            <DeleteLinkModal />
          </DialogContent>
        </Dialog>
      )
    case MODAL_TYPES.CREATE_COLLECTION:
      return (
        <Dialog open={modalState.isOpen} onClose={handleClose} fullWidth={true}>
          <DialogContent>
            <CreateCategoryModal />
          </DialogContent>
        </Dialog>
      )
    case MODAL_TYPES.EDIT_COLLECTION:
      return (
        <Dialog open={modalState.isOpen} onClose={handleClose} fullWidth={true}>
          <DialogContent>
            <EditCategoryModal />
          </DialogContent>
        </Dialog>
      )
    case MODAL_TYPES.DELETE_COLLECTION:
      return (
        <Dialog open={modalState.isOpen} onClose={handleClose} fullWidth={true}>
          <DialogContent>
            <DeleteCategoryModal />
          </DialogContent>
        </Dialog>
      )
    case MODAL_TYPES.CREATE_TAG:
      return (
        <Dialog open={modalState.isOpen} onClose={handleClose} fullWidth={true}>
          <DialogContent>
            <CreateTagModal />
          </DialogContent>
        </Dialog>
      )
    case MODAL_TYPES.EDIT_TAG:
      return (
        <Dialog open={modalState.isOpen} onClose={handleClose} fullWidth={true}>
          <DialogContent>
            <EditTagModal />
          </DialogContent>
        </Dialog>
      )
    case MODAL_TYPES.DELETE_TAG:
      return (
        <Dialog open={modalState.isOpen} onClose={handleClose} fullWidth={true}>
          <DialogContent>
            <DeleteTagModal />
          </DialogContent>
        </Dialog>
      )

    case MODAL_TYPES.EDIT_FOLDER:
      return (
        <Dialog open={modalState.isOpen} onClose={handleClose} fullWidth={true}>
          <DialogContent>
            <EditFolderModal />
          </DialogContent>
        </Dialog>
      )
    case MODAL_TYPES.CREATE_FOLDER:
      return (
        <Dialog open={modalState.isOpen} onClose={handleClose} fullWidth={true}>
          <DialogContent>
            <CreateFolderModal />
          </DialogContent>
        </Dialog>
      )
    case MODAL_TYPES.DELETE_FOLDER:
      return (
        <Dialog open={modalState.isOpen} onClose={handleClose} fullWidth={true}>
          <DialogContent>
            <DeleteFolderModal />
          </DialogContent>
        </Dialog>
      )
    case MODAL_TYPES.IPHONE_DOWNLOAD_SCREENSHOT:
      return (
        <Dialog open={modalState.isOpen} onClose={handleClose} fullWidth={true}>
          <DialogContent>
            <IphoneDownloadScreenshotModal />
          </DialogContent>
        </Dialog>
      )
    case MODAL_TYPES.SECOND_FACTOR:
      return (
        <Dialog open={modalState.isOpen} onClose={handleClose} fullWidth={true}>
          <DialogContent>
            <SecondFactorModal />
          </DialogContent>
        </Dialog>
      )
    case MODAL_TYPES.WEBAUTHN_FACTOR:
      return (
        <Dialog open={modalState.isOpen} onClose={handleClose} fullWidth={true}>
          <DialogContent>
            <WebauthnFactorModal />
          </DialogContent>
        </Dialog>
      )
    case MODAL_TYPES.MANAGE_TWO_FACTOR:
      return (
        <Dialog open={modalState.isOpen} onClose={handleClose} fullWidth={true}>
          <DialogContent>
            <ManageTwoFactorModal />
          </DialogContent>
        </Dialog>
      )
    case MODAL_TYPES.MANAGE_BACKUP_CODES:
      return (
        <Dialog open={modalState.isOpen} onClose={handleClose} fullWidth={true}>
          <DialogContent>
            <ManageBackupCodesModal />
          </DialogContent>
        </Dialog>
      )
    case MODAL_TYPES.LOST_AUTHENTICATOR:
      return (
        <Dialog open={modalState.isOpen} onClose={handleClose} fullWidth={true}>
          <DialogContent>
            <LostAuthenticatorModal />
          </DialogContent>
        </Dialog>
      )
    case MODAL_TYPES.MANAGE_WEBAUTHN:
      return (
        <Dialog open={modalState.isOpen} onClose={handleClose} fullWidth={true}>
          <DialogContent>
            <ManageWebauthnModal />
          </DialogContent>
        </Dialog>
      )
    case MODAL_TYPES.CREATE_ORGANIZATION:
      return (
        <Dialog open={modalState.isOpen} onClose={handleClose} fullWidth={true}>
          <DialogContent>
            <CreateOrganizationModal />
          </DialogContent>
        </Dialog>
      )
    case MODAL_TYPES.LEAVE_ORGANIZATION:
      return (
        <Dialog open={modalState.isOpen} onClose={handleClose} fullWidth={true}>
          <DialogContent>
            <LeaveOrganizationModal />
          </DialogContent>
        </Dialog>
      )
    case MODAL_TYPES.MANAGE_USER_ORGANIZATION_PERMISSIONS:
      return (
        <Dialog open={modalState.isOpen} onClose={handleClose} fullWidth={true}>
          <DialogContent>
            <ManageUserOrganizationPermissionsModal />
          </DialogContent>
        </Dialog>
      )
    case MODAL_TYPES.REMOVE_USER_FROM_ORGANIZATION:
      return (
        <Dialog open={modalState.isOpen} onClose={handleClose} fullWidth={true}>
          <DialogContent>
            <RemoveUserFromOrganizationModal />
          </DialogContent>
        </Dialog>
      )
    case MODAL_TYPES.CREATE_ORGANIZATION_INVITE:
      return (
        <Dialog open={modalState.isOpen} onClose={handleClose} fullWidth={true}>
          <DialogContent>
            <CreateOrganizationInviteModal />
          </DialogContent>
        </Dialog>
      )
    case MODAL_TYPES.ORGANIZATION_ROLES_DESCRIPTION:
      return (
        <Dialog open={modalState.isOpen} onClose={handleClose} fullWidth={true}>
          <DialogContent>
            <OrganizationRolesDescriptionModal />
          </DialogContent>
        </Dialog>
      )
    case MODAL_TYPES.ORGANIZATION_STATE_DESCRIPTION:
      return (
        <Dialog open={modalState.isOpen} onClose={handleClose} fullWidth={true}>
          <DialogContent>
            <OrganizationStateDescriptionModal />
          </DialogContent>
        </Dialog>
      )
    case MODAL_TYPES.WELCOME_TO_LINKIDEX:
      return (
        <Dialog open={modalState.isOpen} onClose={handleClose} fullWidth={true}>
          <DialogContent>
            <WelcomeModal />
          </DialogContent>
        </Dialog>
      )
    case MODAL_TYPES.IMPORT_BOOKMARKS_HOW_TO:
      return (
        <Dialog open={modalState.isOpen} onClose={handleClose} fullWidth={true}>
          <DialogContent>
            <ImportBookmarksHowTwoModal />
          </DialogContent>
        </Dialog>
      )
    case MODAL_TYPES.USE_CATEGORIES_AND_TAGS_FOR_AI_SUGGEST_HOW_TO:
      return (
        <Dialog open={modalState.isOpen} onClose={handleClose} fullWidth={true}>
          <DialogContent>
            <UseCategoriesAndTagsForAiSuggestHowToModal />
          </DialogContent>
        </Dialog>
      )

    default:
      return <div></div>
  }
}
